import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Lottie from "react-lottie";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import { crudGetPresentationData } from "../../CRUDHELPER";

export default class ReportProposalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
		};
	}

	componentDidMount() {
		// crudGetPresentationData(this.props.sheetName, (s, d) => {
		// 	if (s === 200) {
		// 		this.setState({
		// 			items: d,
		// 		});
		// 	}
		// });
	}

	render() {
		let h = window.innerHeight;
		let w = window.innerWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 265 / 345;

		let bias = h > w ? w : h;

		let mw = 800;
		let { items } = this.state;
		let { h1, h2, pp, h0 } = this.props;

		return (
			<View
				style={{
					flexDirection: "row",
					width: "100%",
					flexWrap: "wrap",
					alignItems: "center",
					// padding: "5%",
					paddingTop: 100,
				}}>
				<View
					style={{
						width: "100%",
					}}>
					<View style={{ width: "100%" }}>
						<Text
							style={{
								fontSize: 34,
								color: APP_COLOURS.DESELECTEDCOLOR,
							}}>
							<Font family='Montserrat' weight={800}>
								Website Audit Report:
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 24,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 20,
							}}>
							<Font family='Montserrat' weight={700}>
								Revlon South Africa
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.DESELECTEDCOLOR,
							}}>
							<Font family='Montserrat' weight={300}>
								Audit process
							</Font>
						</Text>
						<View
							style={{
								flexDirection: "row",
								marginBottom: 20,
							}}>
							{[
								"Audit & Assess",
								"Digital Shelf Clean-Up",
								"Assess Fixes",
							].map((d) => (
								<View
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										borderWidth: 1,
										borderColor: "#FFF",
										height: 60,
										marginRight: 5,
									}}>
									<Text
										style={{
											fontSize: 16,
											color: APP_COLOURS.DESELECTEDCOLOR,
										}}>
										<Font family='Montserrat' weight={300}>
											{d}
										</Font>
									</Text>
								</View>
							))}
						</View>
						<View
							style={{
								width: "100%",
								marginBottom: 20,
								// maxWidth: 1200,
								overflow: "hidden",
								borderRadius: 10,
							}}>
							<img
								src={require("./DALL·E 2024-07-03 14.49.32 - A simple presentation illustration of a digital audit report for a website and ecommerce platform. The steps are displayed in a clean, professional ma.webp")}
								alt='img'
								className='responsive'
							/>
						</View>

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Overview
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 20,
								flexDirection: "row",
							}}>
							<Font family='Montserrat' weight={300}>
								This report covers the audit of the Revlon South
								Africa website and the e-commerce retailers that
								list and sell Revlon products. It includes a
								list of all pages, updates performed, and the
								products that are active on the website.
							</Font>
						</Text>

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Active Website Pages
							</Font>
						</Text>
						{[
							"Home Page",
							"Category Pages - hair",
							"Category Pages - makeup",
							"Category Pages - nails",
							"Category Pages - collections",
							"Product Pages - hair",
							"Product Pages - makeup",
							"Product Pages - nails",
							"Product Pages - collections",
						].map((b) => (
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,

									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									{b}
								</Font>
							</Text>
						))}

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
								marginTop: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Home Page Banner Updates
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 20,
								flexDirection: "row",
							}}>
							<Font family='Montserrat' weight={300}>
								1. Home Page
							</Font>
						</Text>

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Updates Performed
							</Font>
						</Text>
						<View
							style={{
								marginBottom: 20,
							}}>
							{[
								"1. Updated product listings",
								"2. VTO - Activations",
								"3. Fixed navigation issues found via google SEO on 2024-05-25",
							].map((d) => (
								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.DESELECTEDCOLOR,

										flexDirection: "row",
									}}>
									<Font
										family='Montserrat'
										weight={300}></Font>
								</Text>
							))}
						</View>

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Added Products
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 20,
								flexDirection: "row",
							}}>
							<Font family='Montserrat' weight={300}>
								{`ColorStay Multiplayer™ Liquid-Glide Eye Pencil
Illuminance™ Serum Tint
ColorStay™ Longwear Makeup for Combination/Oily Skin
ColorStay™ Longwear Makeup for Normal/Dry Skin
ColorStay Flex Wear Full Cover™ Concealer
ColorStay Limitless Matte™ Liquid Lipstick
ColorStay Gel Envy™ Longwear Nail Polish
Revlon Kiss Exfoliating Balm Sugar Mint
PhotoReady Candid Natural Finish Anti-Pollution Foundation
ColorStay Longwear Cream Color`}
							</Font>
						</Text>

						<Text
							style={{
								fontSize: 18,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 10,
							}}>
							<Font family='Montserrat' weight={500}>
								Recommendations
							</Font>
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.DESELECTEDCOLOR,
								marginBottom: 20,
								flexDirection: "row",
							}}>
							<Font family='Montserrat' weight={300}>
								{`1. Monitor e-commerce retailers for
compliance with brand & listing standards
2. Improve website navigation for better user experience.
3. Implement SEO Audit findings & fixes.`}
							</Font>
						</Text>
					</View>
				</View>
				{/* <View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: 20,
					}}>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../assets/services.png")}
							alt='img'
							className='responsive'
						/>
					</View>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../assets/infod.png")}
							alt='img'
							className='responsive'
						/>
					</View>
				</View> */}
			</View>
		);
	}

	left = (h1, h2, pp, h0) => {
		let h = window.innerHeight * 0.75;
		let w = window.innerWidth * 0.75;

		return (
			<View
				style={{
					// flexDirection:
					// 	window.innerWidth < 800 ? "column" : "row-reverse",
					flexWrap: "wrap",
					marginBottom: h1,
					// marginBottom: "10%",
					// minHeight: window.innerHeight,
					justifyContent: "space-between",
					height: "auto",
					// alignItems:
					// 	window.innerWidth < 800 ? "flex-start" : "flex-end",
				}}>
				{/* <View
					style={{
						width: "100%",
						maxWidth: 500,
						// justifyContent: "flex-end",
						// alignItems: "flex-end",
					}}>
					<View
						style={{
							// flex: 1,
							padding: 20,
						}}>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: this.props.animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={h * 0.75 > 550 ? 550 : h * 0.75}
							width={h * 0.75 > 550 ? 550 : h * 0.75}
						/>
					</View>
				</View> */}
				<View
					style={
						{
							// opacity: 0.9,
							// flexDirection: "row",
						}
					}>
					<Font family='Gotham' weight={300}>
						<Text
							style={{
								fontSize: h0,
								color: "#FFF",
								// fontFamily: "Gotham",
								fontWeight: "900",
							}}>
							{this.props.title}:
						</Text>
					</Font>
					<Font family='Gotham' weight={300}>
						<Text
							ellipsizeMode='clip'
							style={{
								fontSize: h1,
								opacity: 0.7,
								color: "#FFF",
								// fontFamily: "Gotham",
								flexWrap: "wrap",
							}}>
							{this.props.title2}
						</Text>
					</Font>
				</View>
			</View>
		);
	};
}
