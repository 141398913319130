import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Image,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import Font from "react-font";

class ImageLoop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
		};

		this.scrollViewRef = React.createRef();
		this.steps = props.steps;

		this.interval = React.createRef();
	}

	componentDidMount() {
		this.startInterval();
		this.props.stepTitle(this.steps[0].title);
	}

	startInterval() {
		clearInterval(this.interval);
		clearTimeout(this.interval);
		this.interval = setInterval(() => {
			this.nextStep();
		}, 3000);
	}

	scrollToStep(step) {
		if (this.scrollViewRef.current) {
			this.scrollViewRef.current.scrollTo({
				x: step * 400, // Adjust the multiplier according to the item width
				y: 0,
				animated: true,
			});
		}
	}

	nextStep() {
		this.setState((prevState) => {
			const nextStep = (prevState.activeStep + 1) % this.steps.length;
			this.scrollToStep(nextStep);
			this.props.stepTitle(this.steps[nextStep].title);
			// console.log(nextStep);
			return { activeStep: nextStep };
		});
		clearInterval(this.interval);
		clearTimeout(this.interval);
		this.interval = setInterval(() => {
			this.nextStep();
		}, 3000);
	}

	prevStep() {
		this.setState((prevState) => {
			const prevStep =
				(prevState.activeStep - 1 + this.steps.length) %
				this.steps.length;
			this.scrollToStep(prevStep);
			this.props.stepTitle(this.steps[prevStep].step);
			return { activeStep: prevStep };
		});

		clearInterval(this.interval);
		clearTimeout(this.interval);
		this.interval = setInterval(() => {
			this.nextStep();
		}, 3000);
	}

	render() {
		return (
			<View style={styles.container}>
				<ScrollView
					horizontal
					ref={this.scrollViewRef}
					showsHorizontalScrollIndicator={false}>
					{this.steps.map((step, index) => (
						<View
							style={{
								width: "100%",
								maxWidth: 350,
								marginRight: 50,
								opacity:
									this.state.activeStep === index ? 1 : 0.2,
								// backgroundColor: "#FFF",
								// backgroundColor: "red",
								borderRadius: 15,
								// padding: 20,
								overflow: "hidden",
							}}
							key={index}>
							{/* <View
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									position: "absolute",
									left: 0,
									top: 0,
									// opacity: 0.3,
								}}>
								<Image
									source={{ uri: step.image }}
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										resizeMode: "cover",
										// opacity: 0.8,
									}}
									blurRadius={20}
								/>
							</View> */}

							<View
								style={{
									marginLeft: "auto",
									marginRight: "auto",
								}}>
								<Image
									source={{ uri: step.image }}
									style={{
										width: 300,
										height: window.innerHeight * 0.8 - 90,
										paddingBottom: 10,
										marginBottom: 10,
										resizeMode: "contain",
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 10,
										},
										shadowOpacity: 0.55,
										shadowRadius: 14.78,
										elevation: 22,
									}}
								/>
							</View>
							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
									flexDirection: "row",
									padding: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									{step.title}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
									// textAlign: "justify",
									fontFamily: "Montserrat",
									fontWeight: "300",
								}}>
								{step.description}
							</Text>
						</View>
					))}
				</ScrollView>

				<View style={styles.buttonsContainer}>
					<TouchableOpacity
						onPress={() => this.prevStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Back</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => this.nextStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Forward</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		height: "auto",
		maxHeight: window.innerHeight * 0.8,
		width: "100%",
	},
	buttonsContainer: {
		width: "100%",
		justifyContent: "space-between",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},
	button: {
		backgroundColor: APP_COLOURS.TEXTCOLOR,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 5,
		marginRight: 20,
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default ImageLoop;
