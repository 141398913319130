import React, { Component } from "react";
import { Dimensions } from "react-native";

export const APP_COLOURS = {
	// PRIMARY: "",
	// SECONDARY: "#748969",
	// BACKGROUND: "#F2F3F6",

	// TEXTCOLOR: "#020E35",
	// PLACEHOLDER: "#797979",
	// TEXTINPUT: "#0720D5",
	// DESELECTEDCOLOR: "#EEF2FC",
	// OFFWHITE: "#FBFBFC",

	// WHITE: "#FFF",
	// GREEN: "#07D55D",
	// ORANGE: "#FFD76E",
	// RED: "#FF5F5F",
	// BLUE: "#0068FF",

	// TRANSHALO: "rgba(0, 0, 0, 0.7)",
	// BLACK: "#000",
	// BG: "#1F2229",
	// BG2: "#121419",
	// BG3: "#1D2028",
	// BG4: "#242830",
	// BG5: "#4E525D",
	// BG6: "#3F4659",

	MAIN_BG: "#131119",
	MAIN: "#131119",
	BACKGROUND: "#17151D",
	PRIMARY: "#17151D",
	SECONDARY: "#0676ED",
	//   BACKGROUND: '#F8FAFF',
	TEXTWHITE: "#F8FAFF",

	BG: "#2B2C31",
	BG2: "#2B2C31",
	BG3: "#35373C",
	BG4: "#4B4E54",
	BG5: "#686868",
	TEXTCOLOR: "#C7C7D2",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	PURPLE: "#8F6FFB",
	BUTTON2: "#252B35",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#F4F6FA",
	WHITE: "#FFF",
	GREEN: "#07D55D",
	GREENCOMPLETE: "rgba(55, 255, 86, 0.4)",
	ORANGE: "#FF8807",
	PENDING: "#748969",
	YELLOW: "#FFD76E",
	RED: "#E35B5B",
	RED2: "#FF5F5F",
	BLUE: "#0676ED",
	BLUE2: "#3F6EFF",
	WHITE_TRANSPARENT: "rgba(212, 212, 212, 0.2)",
	WHITE_TRANSPARENT1: "rgba(212, 212, 212, 0.05)",
	WHITE_TRANSPARENT2: "rgba(25, 29, 36, 0.4)",
	WHITE_TRANSPARENT3: "#323A47",
	WHITE_TRANSPARENT4: "rgba(212, 212, 212, 0.05)",
	WHITE_TRANSPARENT5: "rgba(0, 0, 0, 0.05)",
	WHITE_TRANSPARENT7: "rgba(212, 212, 212, 0.03)",
	WHITE_TRANSPARENT8: "rgba(212, 212, 212, 0.015)",

	WHITE_TRANSPARENT6: "rgba(0, 0, 0, 0.015)",
	WHITE_TRANSPARENT9: "rgba(255, 255, 255, 0.8)",
	WHITE_TRANSPARENT10: "rgba(255, 255, 255, 0.5)",
	BACK_TRANSPARENT: "rgba(0, 0, 0, 0.7)",
	BACK_TRANSPARENT2: "rgba(0, 0, 0, 0.37)",
	BACK_TRANSPARENT3: "rgba(0, 0, 0, 0.15)",
	BLACK_TRANS: "rgba(52, 52, 52, 0.2)",
	BLACK: "#252525",

	RED_TRANS: "rgba(255, 95, 95, 0.05)",
};

export const PROJECTSTATUS = [
	{
		type: 1,
		status: "Urgent attention",
		color: "#FF4A30",
		description: "Task or project needs immediate attention or action.",
	},
	{
		type: 2,
		status: "Attention Required",
		color: APP_COLOURS.ORANGE,
		description: "Custom status entered by the user.",
	},

	{
		type: 3,
		status: "In Progress",

		color: "#0676ED",
		description: "Task or project is currently underway.",
	},
	{
		type: 5,
		status: "Awaiting Approval",
		color: APP_COLOURS.PURPLE,
		description: "Task or project has not begun.",
	},
	{
		type: 7,
		status: "Completed",
		color: APP_COLOURS.GREEN,
		description: "Task or project has been successfully finished.",
	},
	{
		type: 8,
		status: "Published",
		color: APP_COLOURS.GREENCOMPLETE,
		description: "Published",
	},

	// {
	// 	type: 9,
	// 	status: "Ongoing",
	// 	color: "#F47FC0",
	// 	description: "Task or project is behind its intended schedule.",
	// },

	// {
	// 	type: 10,
	// 	status: "Under Review by client",
	// 	color: APP_COLOURS.PURPLE,
	// 	description: "Task or project is being assessed or examined.",
	// },
	{
		type: 9,
		status: "Delayed",
		color: "#F47FC0",
		description: "Task or project is temporarily paused.",
	},

	{
		type: 11,
		status: "Paused",
		color: APP_COLOURS.BG3,
		description: "Task or project is behind its intended schedule.",
	},
	{
		type: 12,
		status: "pending",
		// color: APP_COLOURS.PENDING,
		color: APP_COLOURS.WHITE_TRANSPARENT,
		description: "",
	},
	{
		type: 13,
		status: "Cancelled",
		color: APP_COLOURS.WHITE_TRANSPARENT7,
		description: "Task or project has been terminated before completion.",
	},
	// {
	// 	type: 12,
	// 	status: "Not Started",
	// 	color: "white",
	// 	description: "Task or project has not begun.",
	// },
	// {
	// 	type: 13,
	// 	status: "Scheduled",
	// 	color: APP_COLOURS.WHITE_TRANSPARENT5,
	// 	description: "Task or project has not begun.",
	// },

	// {
	// 	type: 14,
	// 	status: "Planning",
	// 	color: "#E8E8E8",
	// 	description: "Task or project has not begun.",
	// },
	// {
	// 	type: 15,
	// 	status: "Design",
	// 	color: APP_COLOURS.BG2,
	// 	description: "",
	// },
	// {
	// 	type: 16,
	// 	status: "Copy",
	// 	color: APP_COLOURS.YELLOW,
	// 	description: "",
	// },
	// {
	// 	type: 17,
	// 	status: "Approved",
	// 	color: APP_COLOURS.BLUE2,
	// 	description: "",
	// },
	// {
	// 	type: 18,
	// 	status: "Pending",
	// 	color: APP_COLOURS.BG3,
	// 	description: "",
	// },
];

export const Avatars = [
	{
		pp: require("./App/assets/Avatars/1.png"),
		avatar: 1,
	},
	{
		pp: require("./App/assets/Avatars/2.png"),
		avatar: 2,
	},
	{
		pp: require("./App/assets/Avatars/3.png"),
		avatar: 3,
	},
	{
		pp: require("./App/assets/Avatars/4.png"),
		avatar: 4,
	},
	{
		pp: require("./App/assets/Avatars/5.png"),
		avatar: 5,
	},
	{
		pp: require("./App/assets/Avatars/6.png"),
		avatar: 6,
	},
	{
		pp: require("./App/assets/Avatars/7.png"),
		avatar: 7,
	},
	{
		pp: require("./App/assets/Avatars/8.png"),
		avatar: 8,
	},
	{
		pp: require("./App/assets/Avatars/9.png"),
	},
	{
		pp: require("./App/assets/Avatars/10.png"),
	},
	{
		pp: require("./App/assets/Avatars/11.png"),
	},
	{
		pp: require("./App/assets/Avatars/12.png"),
	},
	{
		pp: require("./App/assets/Avatars/13.png"),
	},
	{
		pp: require("./App/assets/Avatars/14.png"),
	},
	{
		pp: require("./App/assets/Avatars/15.png"),
	},
	{
		pp: require("./App/assets/Avatars/16.png"),
	},
	{
		pp: require("./App/assets/Avatars/17.png"),
	},
	{
		pp: require("./App/assets/Avatars/18.png"),
	},
	{
		pp: require("./App/assets/Avatars/19.png"),
	},
	{
		pp: require("./App/assets/Avatars/20.png"),
	},
	{
		pp: require("./App/assets/Avatars/21.png"),
	},
	{
		pp: require("./App/assets/Avatars/22.png"),
	},
	{
		pp: require("./App/assets/Avatars/23.png"),
	},
	{
		pp: require("./App/assets/Avatars/24.png"),
	},
	{
		pp: require("./App/assets/Avatars/25.png"),
	},
	{
		pp: require("./App/assets/Avatars/26.png"),
	},
	{
		pp: require("./App/assets/Avatars/27.png"),
	},
	{
		pp: require("./App/assets/Avatars/28.png"),
	},
	{
		pp: require("./App/assets/Avatars/29.png"),
	},
	{
		pp: require("./App/assets/Avatars/30.png"),
	},
	{
		pp: require("./App/assets/Avatars/31.png"),
	},
	{
		pp: require("./App/assets/Avatars/32.png"),
	},
	{
		pp: require("./App/assets/Avatars/33.png"),
	},
	{
		pp: require("./App/assets/Avatars/34.png"),
	},
	{
		pp: require("./App/assets/Avatars/35.png"),
	},
	{
		pp: require("./App/assets/Avatars/36.png"),
	},
	{
		pp: require("./App/assets/Avatars/37.png"),
	},
	{
		pp: require("./App/assets/Avatars/38.png"),
	},
	{
		pp: require("./App/assets/Avatars/39.png"),
	},
	{
		pp: require("./App/assets/Avatars/40.png"),
	},
	{
		pp: require("./App/assets/Avatars/41.png"),
	},
	{
		pp: require("./App/assets/Avatars/42.png"),
	},
	{
		pp: require("./App/assets/Avatars/43.png"),
	},
	{
		pp: require("./App/assets/Avatars/44.png"),
	},
	{
		pp: require("./App/assets/Avatars/45.png"),
	},
	{
		pp: require("./App/assets/Avatars/46.png"),
	},
	{
		pp: require("./App/assets/Avatars/47.png"),
	},
	{
		pp: require("./App/assets/Avatars/48.png"),
	},
	{
		pp: require("./App/assets/Avatars/49.png"),
	},
	{
		pp: require("./App/assets/Avatars/50.png"),
	},
];

export function StatusColour(d) {
	let color = "";
	let options = {
		online: APP_COLOURS.GREEN,
		busy: APP_COLOURS.ORANGE,
		"On lunch": APP_COLOURS.PURPLE,
		offline: APP_COLOURS.RED2,
	};
	color = typeof options[d] !== "undefined" ? options[d] : "transparent";
	return color;
}

export const screenWidth = Dimensions.get("window").width;
export const screenHeight = Dimensions.get("window").height;

export function TaskStatus(type) {
	let color = "";

	let pallet = PROJECTSTATUS.find((m) => m.status === type);

	// console.log(pallet);
	color = typeof pallet !== "undefined" ? pallet.color : "gray";

	// color = typeof options[d] !== 'undefined' ? options[d] : APP_COLOURS.YELLOW;
	return color;
}
