import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import { APP_COLOURS, Avatars } from "../../APP_VARS";

export default class AuditLandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.8
				: window.innerHeight * 0.45;

		let maxContain = contain > 500 ? 500 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp, h0, mw, proposalData } = this.props;

		return (
			<View
				style={{
					width: "100%",
					backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
					alignItems: "center",
					justifyContent: "center",
					// overflow: "hidden",
				}}>
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						flexWrap: "wrap",
						alignItems: "center",
						// padding: "5%",
						paddingHorizontal: "5%",
						justifyContent: "center",
					}}>
					<View
						style={{
							flex: 1,
							maxWidth: 1000,
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "space-between",
						}}>
						<View
							style={{
								// width: mw,
								flex: 1,
								// minHeight: window.innerHeight * 0.5,
								paddingTop: 100,
								// padding: "5%",
								minWidth: 350,
							}}>
							<View
								style={{
									width: "100%",
									// marginBottom: 100,
									maxWidth: 500,
									alignItems: "center",
									justifyContent: "center",
									// height: window.innerHeight * 0.2,
								}}>
								<img
									src={require("../assets/tetrice-revlon-main.png")}
									alt='img'
									className='responsive'
								/>
							</View>
							<View
								style={{
									// width: window.innerWidth,
									flexDirection: "row",
									flexWrap: "wrap",
									marginBottom: "10%",
									overflow: "hidden",
									justifyContent: "space-between",
									marginTop: 50,
								}}>
								<View
									style={{
										flex: 1,
										maxWidth: 700,
										// opacity: 0.9,
										// marginTop: 50,
									}}>
									<Fade cascade top>
										<Text
											style={{
												fontSize: h0 + 10,
												opacity: 1,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={800}>
												{proposalData?.title}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: h0 + 6,
												opacity: 0.5,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={800}>
												{proposalData?.title2}
											</Font>
										</Text>

										<Text
											style={{
												fontSize: h2 + 5,
												color: "#FFF",
											}}>
											<Font family='Poppins' weight={400}>
												{proposalData?.subTitle}
											</Font>
										</Text>
									</Fade>

									<Text
										style={{
											fontSize: pp,
											opacity: 0.6,
											color: "#FFF",
											maxWidth: 500,
										}}>
										<Font family='Poppins' weight={300}>
											{proposalData?.description}
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								flex: 1,
								// position: "absolute",
								// top: 0,
								// right: 0,
								minWidth: 350,
								// width: "50%",
								alignItems: "flex-end",
								justifyContent: "center",
								// backgroundColor: "red",
								height: window.innerHeight * 0.5,
								maxHeight: window.innerWidth,
							}}>
							<View
								style={{
									width: "100%",
									maxWidth: 1000,
								}}>
								<img
									src={require("../assets/ab1.png")}
									alt='Description'
									className='responsive'
								/>
							</View>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						flexWrap: "wrap",
						alignItems: "center",
						// justifyContent: "space-between",
						// marginBottom: "10%",
						width: "100%",
						// paddingHorizontal: "5%",
						// maxWidth: 1200,
						paddingHorizontal: "5%",
						justifyContent: "center",
					}}>
					{/* <View
						style={{
							width: window.innerWidth - 1200,
							maxWidth: 350,
						}}
					/> */}
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							// justifyContent: "space-between",
							flexWrap: "wrap",
							maxWidth: 1000,
						}}>
						<View
							style={{
								width: 200,
								// flex: 1,
								marginBottom: 20,
								marginRight: "10%",
							}}>
							<img
								src={require("../assets/services.png")}
								alt='img'
								className='responsive'
							/>
						</View>
						<View
							style={{
								width: 200,
								marginBottom: 20,
							}}>
							<img
								src={require("../assets/infod.png")}
								alt='img'
								className='responsive'
							/>
						</View>
					</View>
				</View>

				<View
					style={{
						// flex: 1,
						width: "100%",
						marginTop: 20,
						backgroundColor: APP_COLOURS.BG2,
						justifyContent: "center",
						alignItems: "center",
						paddingVertical: 20,
						paddingHorizontal: "5%",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
							width: "100%",
							// paddingHorizontal: "5%",
							paddingVertical: "2%",
							justifyContent: "center",
						}}>
						{/* <View
							style={{
								width: window.innerWidth - 1200,
								maxWidth: 350,
							}}
						/> */}
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								flexWrap: "wrap",
								maxWidth: 1000,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									minWidth: 200,
									paddingVertical: "1%",
								}}>
								<View
									style={{
										width: 36,
										height: 36,
										borderRadius: 20,
										resizeMode: "contain",
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "#FFF",
										borderWidth: 2,
										borderColor: "#FFF",
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.44,
										shadowRadius: 10.32,
										elevation: 16,
										marginRight: 10,
									}}>
									{typeof this.props.createdBy !==
									"undefined" ? (
										<Image
											source={
												Avatars[
													parseInt(
														this.props.createdBy
															?.avatar,
													)
												].pp
											}
											style={{
												width: 35,
												height: 35,
												borderRadius: 20,
												resizeMode: "contain",
											}}
										/>
									) : null}
								</View>
								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Created by:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={600}>
											{typeof proposalData?.createdBy !==
											"undefined"
												? proposalData?.createdBy
														.fullName
												: "..."}
										</Font>
									</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									minWidth: 200,
									paddingVertical: "1%",
								}}>
								{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}

								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Date:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={300}>
											{moment(
												proposalData?._createTime,
											).format("DD/MM/YYYY")}
										</Font>
									</Text>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
									minWidth: 200,
									paddingVertical: "1%",
								}}>
								{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}
								<View>
									<Text
										style={{
											fontSize: 10,
											color: "#FFF",
											top: 6,
										}}>
										<Font family='Poppins' weight={400}>
											Document number:
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 16,
											color: "#FFF",
											marginBottom: 5,
										}}>
										<Font family='Poppins' weight={300}>
											2345234
										</Font>
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}
}
