export const southAfricanHolidays = [
	{
		id: "a1",
		title: "New Year's Day",
		start_date: "01/01/2024",
		end_date: "01/01/2024",
	},
	{
		id: "a2",
		title: "Human Rights Day",
		start_date: "03/21/2024",
		end_date: "03/21/2024",
	},
	{
		id: "a3",
		title: "Good Friday",
		start_date: "03/29/2024",
		end_date: "03/29/2024",
	},
	{
		id: "a4",
		title: "Family Day",
		start_date: "04/01/2024",
		end_date: "04/01/2024",
	},
	{
		id: "a5",
		title: "Freedom Day",
		start_date: "04/27/2024",
		end_date: "04/27/2024",
	},
	{
		id: "a6",
		title: "Workers' Day",
		start_date: "05/01/2024",
		end_date: "05/01/2024",
	},
	{
		id: "a7",
		title: "Youth Day",
		start_date: "06/16/2024",
		end_date: "06/16/2024",
	},
	{
		id: "8a",
		title: "National Women's Day",
		start_date: "08/09/2024",
		end_date: "08/09/2024",
	},
	{
		id: "8b",
		title: "Worl Afro day",
		start_date: "15/09/2024",
		end_date: "15/09/2024",
	},
	{
		id: "9a",
		title: "Heritage Day",
		start_date: "09/24/2024",
		end_date: "09/24/2024",
	},
	{
		id: "10a",
		title: "Day of Reconciliation",
		start_date: "12/16/2024",
		end_date: "12/16/2024",
	},
	{
		id: "11a",
		title: "Christmas Day",
		start_date: "12/25/2024",
		end_date: "12/25/2024",
	},
	{
		id: "1a2",
		title: "Day of Goodwill",
		start_date: "12/26/2024",
		end_date: "12/26/2024",
	},
];

export const socialCalendarDays = [
	{
		id: "1",
		title: "National Spaghetti Day",
		start_date: "01/04/2024",
		end_date: "01/04/2024",
	},
	{
		id: "2",
		title: "International Women's Day",
		start_date: "03/08/2024",
		end_date: "03/08/2024",
	},
	{
		id: "3",
		title: "World Sleep Day",
		start_date: "03/15/2024",
		end_date: "03/15/2024",
	},
	{
		id: "4",
		title: "International Day of Happiness",
		start_date: "03/20/2024",
		end_date: "03/20/2024",
	},
	{
		id: "5",
		title: "World Health Day",
		start_date: "04/07/2024",
		end_date: "04/07/2024",
	},
	{
		id: "6",
		title: "Earth Day",
		start_date: "04/22/2024",
		end_date: "04/22/2024",
	},
	{
		id: "7",
		title: "Star Wars Day",
		start_date: "05/04/2024",
		end_date: "05/04/2024",
	},
	{
		id: "8",
		title: "World Environment Day",
		start_date: "06/05/2024",
		end_date: "06/05/2024",
	},
	{
		id: "9",
		title: "International Yoga Day",
		start_date: "06/21/2024",
		end_date: "06/21/2024",
	},
	{
		id: "10",
		title: "National Ice Cream Day",
		start_date: "07/21/2024",
		end_date: "07/21/2024",
	},
	{
		id: "11",
		title: "International Cat Day",
		start_date: "08/08/2024",
		end_date: "08/08/2024",
	},
	{
		id: "12",
		title: "World Beard Day",
		start_date: "09/07/2024",
		end_date: "09/07/2024",
	},
	{
		id: "13",
		title: "World Smile Day",
		start_date: "10/04/2024",
		end_date: "10/04/2024",
	},
	{
		id: "14",
		title: "World Kindness Day",
		start_date: "11/13/2024",
		end_date: "11/13/2024",
	},
	{
		id: "15",
		title: "World Toilet Day",
		start_date: "11/19/2024",
		end_date: "11/19/2024",
	},
	{
		id: "16",
		title: "National Cookie Day",
		start_date: "12/04/2024",
		end_date: "12/04/2024",
	},
	{
		id: "17",
		title: "Ugly Christmas Sweater Day",
		start_date: "12/20/2024",
		end_date: "12/20/2024",
	},
	{
		id: "18",
		title: "National Make Your Bed Day",
		start_date: "09/11/2024",
		end_date: "09/11/2024",
	},
	{
		id: "19",
		title: "National Slipper Day",
		start_date: "01/14/2024",
		end_date: "01/14/2024",
	},
	{
		id: "20",
		title: "National Lipstick Day",
		start_date: "07/29/2024",
		end_date: "07/29/2024",
	},
];
