import React, { Component, useEffect } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import Login from "./App/Unauthed/Login";
import Main from "./App/Main";
import Overview from "./App/Overview";
import ProposalPage3 from "./App/MainProposal";
import IntroPage from "./App/PresentationReport/IntroPage";
import ProposalReport from "./App/PresentationReport";
import ReportPage from "./App/PresentationReport/UrlProp";
import TrackerOverview from "./App/TRACKER";
import Tracker from "./App/TRACKER/TrackerUrl";
import AuditReport from "./App/AuditReport";
// import OrganisationServices from "./App/ServicesOverview/OrganisationServices";

const withPreventZoom = (Component) => {
	return (props) => {
		useEffect(() => {
			const preventZoom = (e) => {
				if (e.ctrlKey) {
					e.preventDefault();
				}
			};

			window.addEventListener("wheel", preventZoom, { passive: false });

			// Cleanup function to remove event listener when the component unmounts
			return () =>
				window.removeEventListener("wheel", preventZoom, {
					passive: false,
				});
		}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

		return <Component {...props} />;
	};
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	render() {
		const prop = "/";

		const p = {
			login: prop + "",
			overview: prop + "overview",
			prop: prop + ":id",
			main: prop + "main",
			scope: prop + "scope",
			test: prop + "test",
		};

		return (
			<Router>
				<Routes>
					<Route path={p.login} exact element={<Login />} />
					<Route path={p.overview} exact element={<Overview />} />
					<Route path={p.main} element={<Main />} />

					<Route path={p.prop} element={<ReportPage />} />

					<Route path={p.test} exact element={<ProposalPage3 />} />

					{/* <Route
						path={"/report"}
						exact
						element={<ProposalReport />}
					/> */}

					{/* <Route path={"/IntroPage"} exact element={<Main />} /> */}

					{/* <Route path={"/pres"} exact element={<ProposalReport />} /> */}

					{/* <Route
						path={"/slas"}
						exact
						element={<OrganisationServices />}
					/> */}

					<Route
						path={"/tracker"}
						exact
						element={<TrackerOverview />}
					/>
					<Route
						path={"/tracker/:id/:token"}
						exact
						element={<Tracker />}
					/>

					<Route
						path={"/audits/revlon.co.za/3456322345"}
						exact
						element={<AuditReport />}
					/>

					<Route
						path='/livedashboard/:clientid/:token'
						element={<Login />}
					/>

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default withPreventZoom(App);
