import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Lottie from "react-lottie";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import { crudGetPresentationData } from "../../CRUDHELPER";

export default class VTOPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
		};
	}

	componentDidMount() {
		crudGetPresentationData(this.props.sheetName, (s, d) => {
			if (s === 200) {
				this.setState({
					items: d,
				});
			}
		});
	}

	render() {
		let h = window.innerHeight;
		let w = window.innerWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 265 / 345;

		let bias = h > w ? w : h;

		let mw = 800;
		let { items } = this.state;
		let { h1, h2, pp, h0 } = this.props;

		return (
			<View
				style={{
					width: "100%",
					height: "auto",
					// justifyContent: "center",
					// alignItems: "center",
					paddingTop: h1,
				}}>
				{this.left(h1, h2, pp, h0)}

				<View
					style={{
						width: "100%",
					}}>
					<View style={{ width: "100%" }}>
						<View style={{ width: "100%" }}>
							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Virtual Try-Ons at Revlon South Africa
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Revlon South Africa has embraced the latest
									in beauty technology by offering a range of
									virtual try-on experiences on their website.
									These virtual try-ons allow customers to
									explore and experiment with various products
									in real-time, providing a personalized and
									interactive shopping experience. Here’s a
									look at the different virtual try-ons
									currently available on revlon.co.za:
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Lipstick Try-On
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Revlon’s virtual lipstick try-on feature
									enables users to test out different shades
									of their iconic lipsticks without leaving
									the comfort of their homes. By using their
									device's camera or uploading a photo,
									customers can see how each lipstick color
									looks on their lips. This tool helps users
									find the perfect shade to match their skin
									tone and style, enhancing their confidence
									in their purchase decisions.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Foundation Match
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Finding the right foundation shade is
									crucial for a flawless makeup look, and
									Revlon’s foundation match tool simplifies
									this process. The virtual try-on tool
									analyzes the user's skin tone through their
									camera or a photo upload and suggests the
									best foundation shades from Revlon's range.
									This feature ensures a perfect match,
									eliminating the guesswork and reducing the
									likelihood of returns due to incorrect shade
									selection.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Eyeshadow Palette Try-On
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Revlon’s eyeshadow palette virtual try-on
									offers an innovative way to experiment with
									different eye looks. Users can choose from
									various eyeshadow palettes and see how
									different shades and combinations appear on
									their eyelids. This tool is particularly
									useful for customers looking to try bold or
									unfamiliar colors, as it provides a visual
									preview before making a purchase.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Hair Color Try-On
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									For customers considering a new hair color,
									Revlon’s virtual hair color try-on is an
									invaluable resource. This feature allows
									users to try different hair colors and
									shades from Revlon's extensive hair color
									collection. By simply using their camera or
									uploading a photo, customers can visualize
									how a new hair color will look on them,
									making it easier to choose a shade that
									complements their overall look.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Brow Shaper Try-On
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Revlon’s brow shaper try-on tool helps users
									find the perfect brow shape and color. This
									virtual try-on allows customers to
									experiment with different brow styles and
									shades, ensuring they achieve their desired
									look. Whether aiming for natural, bold, or
									defined brows, this tool provides a preview
									of various products and techniques.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 18,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 10,
								}}>
								<Font family='Montserrat' weight={500}>
									Benefits of Virtual Try-Ons
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									- **Convenience:** Customers can try on
									multiple products from the comfort of their
									homes, without needing to visit a physical
									store. - **Personalization:** The virtual
									try-ons provide a personalized shopping
									experience, helping users find products that
									suit their individual preferences and skin
									tones. - **Confidence:** By seeing how
									products look in real-time, customers can
									make more informed purchase decisions,
									increasing their confidence in the products
									they choose. - **Engagement:** These
									interactive tools enhance user engagement on
									the website, making the shopping experience
									more enjoyable and informative.
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.DESELECTEDCOLOR,
									marginBottom: 20,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={300}>
									Revlon South Africa’s virtual try-on tools
									are a testament to the brand’s commitment to
									innovation and customer satisfaction. By
									integrating these cutting-edge technologies,
									Revlon continues to enhance the online
									shopping experience, making it easier and
									more enjoyable for customers to explore and
									find their perfect beauty products.
								</Font>
							</Text>
						</View>
					</View>
				</View>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						// marginBottom: "10%",
					}}>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../assets/services.png")}
							alt='img'
							className='responsive'
						/>
					</View>
					<View
						style={{
							width: "50%",
							marginBottom: 20,
							maxWidth: 250,
						}}>
						<img
							src={require("../assets/infod.png")}
							alt='img'
							className='responsive'
						/>
					</View>
				</View>
			</View>
		);
	}

	left = (h1, h2, pp, h0) => {
		let h = window.innerHeight * 0.75;
		let w = window.innerWidth * 0.75;

		return (
			<View
				style={{
					// flexDirection:
					// 	window.innerWidth < 800 ? "column" : "row-reverse",
					flexWrap: "wrap",
					marginBottom: h1,
					// marginBottom: "10%",
					// minHeight: window.innerHeight,
					justifyContent: "space-between",
					height: "auto",
					// alignItems:
					// 	window.innerWidth < 800 ? "flex-start" : "flex-end",
				}}>
				<View
					style={{
						width: "100%",
						maxWidth: 500,
						// justifyContent: "flex-end",
						// alignItems: "flex-end",
					}}>
					<View
						style={{
							// flex: 1,
							padding: 20,
						}}>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: this.props.animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={h * 0.75 > 550 ? 550 : h * 0.75}
							width={h * 0.75 > 550 ? 550 : h * 0.75}
						/>
					</View>
				</View>
				<View
					style={
						{
							// opacity: 0.9,
							// flexDirection: "row",
						}
					}>
					<Font family='Gotham' weight={300}>
						<Text
							style={{
								fontSize: h0,
								color: "#FFF",
								// fontFamily: "Gotham",
								fontWeight: "900",
							}}>
							{this.props.title}:
						</Text>
					</Font>
					<Font family='Gotham' weight={300}>
						<Text
							ellipsizeMode='clip'
							style={{
								fontSize: h1,
								opacity: 0.7,
								color: "#FFF",
								// fontFamily: "Gotham",
								flexWrap: "wrap",
							}}>
							{this.props.title2}
						</Text>
					</Font>
				</View>
			</View>
		);
	};
}
