import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TextInput,
	StyleSheet,
	Image,
	ActivityIndicator,
	TouchableOpacity,
} from "react-native";
import {
	APP_COLOURS,
	Avatars,
	StatusColour,
	TaskStatus,
} from "../../../../APP_VARS";
import {
	chatGetMessages,
	chatSendMessage,
	crudChatWithHtml,
	crudCreate,
	crudCreateWithBase64,
} from "../../../../CRUDHELPER";
import sentTicks from "./sentMessage.svg";
import send from "./send.png";
import status from "./status.png";
import meetingNotes from "./MeetingNotes.png";
import moment from "moment";
import ImageUploader from "./ImageUploader";
import AutoHeightImage from "./AutoHeightImageComp";

// import { playAudio } from "../Notifications";
import CountDownTimer from "./CountdownTimer";
import QuillEditor from "./Editor/QuillEditor";
import HtmlLoader from "./HtmlLoader";

export default class ChatBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputHeight: 50,
			messages: [],
			crudDocument: "projects_timeline",

			chatTab: "html",
			imagesToUpload: "",
			loading: true,
		};

		this.intervalID = null;
	}

	componentDidMount() {
		this.fetchChats();
		// playAudio(6);
		clearInterval(this.intervalID);
		this.intervalID = setInterval(this.fetchChats, 20000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID); // Clear interval on component unmount
	}

	componentDidUpdate(prevProps) {
		if (prevProps.project_id !== this.props.project_id) {
			this.fetchChats();
		}
	}

	fetchChats = () => {
		// return;
		console.log("fetching chats");
		this.setState({
			loading: true,
		});

		chatGetMessages(
			{
				field: "project_id",
				action: "==",
				value: this.props.project_id,
			},
			async (status, d) => {
				console.log("messages", status, d);
				if (status === 200) {
					this.setState({
						sentMessageData: "",
						sendingMessage: false,
					});
					if (
						this.state.messages.length > 0 &&
						d.length !== this.state.messages.length
					) {
						// playAudio(6);
					}

					await this.setState({
						messages: d,
						loading: false,
					});
					// clearInterval(this.intervalID);
					// setInterval(this.fetchChats(), 20000);
				}
			},
		);
	};

	playSound = () => {
		this.audio.play();
	};

	sendMessage = async (data) => {
		// this.notifyPeople("Message", data);

		chatSendMessage(data, () => {
			this.fetchChats();
		});
	};

	sendStatus = async (data) => {
		// this.notifyPeople("Status", data);

		chatSendMessage(data, () => {
			this.fetchChats();
		});
	};

	sendImage = async (data) => {
		// await this.notifyPeople("Image", data);
		await this.fetchChats();

		return;
	};

	notifyPeople = async (notification, data) => {
		let ass = this.props.assignment_ids;
		let user_data = JSON.parse(localStorage.getItem("user_data"));
		// ass = ass.filter((d) => d !== user_data.id);

		if (notification === "Image") {
			// console.log(data);
			// 		let ass = this.props.selectedProject.assignment;
			// 		console.log(ass);
			const mapThroughEmail = await ass.forEach(async (e) => {
				// await crudSendEmail(
				// 	"SKrum - <skrum@tetrice.co.za>",
				// 	e.username,
				// 	`[NEW MESSAGE] Image uploaded - ${this.props.chatTitle}`,
				// 	`<i>${data.sendBy.first_name} ${data.sendBy.last_name}:</i>
				// 			<br>
				// 			<br><br>
				// 			<div>Client: ${this.props.selectedProject.brand.title}</div> <div>Project: ${this.props.chatTitle}</div>
				// 			<div>url: ${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}</div>
				// 		<br>
				// 		`,
				// );
			});

			const mapThrough = await ass
				.filter((a) => a !== user_data.id)
				.forEach(async (id) => {
					// 					await sendDiscordMessage(
					// 						`${user_data.first_name} ${user_data.last_name}`,
					// 						`### *${this.props.selectedProject.brand.title} - ${this.props.chatTitle}*
					// [IMAGE UPLOADED]
					// `,
					// 						`${window.location.origin}${
					// 							Avatars[parseInt(user_data.avatar)].pp
					// 						}`,
					// 						id,
					// 					);
				});
		} else if (notification === "Status") {
			// console.log(data);

			// 			console.log(ass);
			const mapThroughEmail = await ass
				.filter((a) => a !== user_data.id)
				.forEach(async (e) => {
					// await crudSendEmail(
					// 	"SKrum - <skrum@tetrice.co.za>",
					// 	e.username,
					// 	`[NEW STATUS] - ${this.props.chatTitle}`,
					// 	`<i>${data.sendBy.first_name} ${data.sendBy.last_name}:</i>
					// 		<br><br>
					// 		<div style="padding; 10px;">
					// 		${data.text}
					// 		<br>
					// 		<div>
					// 		<br><br>
					// 		<div>Client: ${this.props.selectedProject.brand.title}</div> <div>Project: ${this.props.chatTitle}</div>
					// 		<div>url: ${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}</div>
					// 	<br>
					// 	`,
					// );
				});

			const mapThrough = await ass
				.filter((a) => a !== user_data.id)
				.forEach(async (id) => {
					// 					await sendDiscordMessage(
					// 						`${user_data.first_name} ${user_data.last_name}`,
					// 						`### *${this.props.selectedProject.brand.title} - ${this.props.chatTitle}*
					// [STATUS]
					// > ${data.text}
					// - url: ${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}
					// `,
					// 						`${window.location.origin}${
					// 							Avatars[parseInt(user_data.avatar)].pp
					// 						}`,
					// 						id,
					// 					);
				});
		} else if (notification === "Message") {
			// console.log(data);
			console.log(ass);
			const mapThroughEmails = await ass
				.filter((a) => a !== user_data.id)
				.forEach(async (id) => {
					let emailHtml = this.emailTemplate(
						`${data.sendBy.first_name} ${data.sendBy.last_name}`,
						data.text,
						this.props.chatTitle,
						this.props.selectedProject.brand.title,
						`${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}`,
					);

					// await crudSendUserIdEmail(
					// 	"SKrum <skrum@tetrice.co.za>",
					// 	id,
					// 	`[NEW MESSAGE] - ${this.props.chatTitle}`,
					// 	emailHtml,
					// );

					// 	await crudSendUserIdEmail(
					// 		"SKrum - <skrum@tetrice.co.za>",
					// 		id,
					// 		`[NEW MESSAGE] - ${this.props.chatTitle}`,
					// 		`<i>${data.sendBy.first_name} ${data.sendBy.last_name}:</i>
					// 	<br><br>
					// 	<div style="padding; 10px;">
					// 	${data.text}
					// 	<br>
					// 	<div>
					// 	<br><br>
					// 	<div>Client: ${this.props.selectedProject.brand.title}</div> <div>Project: ${this.props.chatTitle}</div>
					// 	<div>url: ${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}</div>

					// <br>

					// `,
					// 	);
				});

			const mapThrough = await ass
				.filter((a) => a !== user_data.id)
				.forEach(async (id) => {
					// 		await sendDiscordMessage(
					// 			`${user_data.first_name} ${user_data.last_name}`,
					// 			`### *${this.props.selectedProject.brand.title} - ${this.props.chatTitle}*
					// [NEW MESSAGE]
					// > ${data.text}
					// - url: ${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}
					// `,
					// 			`${window.location.origin}${
					// 				Avatars[parseInt(user_data.avatar)].pp
					// 			}`,
					// 			id,
					// 		);
				});
		}
	};

	emailTemplate = (sendBy, t, ttl, proj, url) => {
		return `<!DOCTYPE html>
		<html>
		<body>
			 <div style="font-family: 'Arial', sans-serif;
			 max-width: 600px;
			 margin: auto;
			 padding: 20px;
			 border-radius: 7px;
			 box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
			 background-color:${APP_COLOURS.OFFWHITE};
			 color:${APP_COLOURS.WHITE_TRANSPARENT};">
			 <i>${sendBy}:</i>
				  <div style="background-color: ${APP_COLOURS.BLUE2};
				  padding: 10px;
				  border-radius: 5px;
				  margin-bottom: 8px;
				  color:#FFF;">
						${t}
						<br><br><br>
						${moment().format("DD/MM/YYYY HH:mm")}
				  </div>
				  <div style="font-size: 12px;
				  color: #777;">
						Client: ${ttl} <br>
						Project: ${proj} <br>
						url: ${url}
				  </div>
			 </div>

	
		</body>
		</html>
		`;
	};

	handleContentSizeChange = (event) => {
		const { contentSize } = event.nativeEvent;
		console.log(contentSize);
		// // Update the height: Minimum is 40, Maximum can be set as per your need
		this.setState({
			inputHeight: contentSize.height > 40 ? contentSize.height : 40,
		});
	};

	render() {
		// if (window.innerWidth > 600) {
		// 	return (
		// 		<View
		// 			style={{
		// 				flex: 1,

		// 				backgroundColor: "#F1F5FA",

		// 				width: "100%",
		// 				// paddingBottom: 30,
		// 				borderRadius: 10,
		// 			}}>
		// 			{/* <View
		// 				style={{
		// 					display: this.state.loading ? "none" : "flex",
		// 					width: 1,
		// 					borderRightWidth: 1,
		// 					borderStyle: "dashed",
		// 					height: "100%",
		// 					left: 35,
		// 					position: "absolute",
		// 				}}
		// 			/> */}
		// 			<View
		// 				style={{
		// 					width: "100%",
		// 					backgroundColor: "#FFF",
		// 					// borderRadius: 10,
		// 					padding: 4,
		// 					flexDirection: "row",
		// 					alignItems: "center",

		// 					// display: "none",

		// 					// shadowColor: "#000",
		// 					// shadowOffset: {
		// 					// 	width: 0,
		// 					// 	height: 8,
		// 					// },
		// 					// shadowOpacity: 0.1,
		// 					// shadowRadius: 10.32,
		// 					// elevation: 15,
		// 					// top: 5,
		// 				}}>
		// 				<Image
		// 					source={meetingNotes}
		// 					style={{
		// 						width: 34,
		// 						height: 34,
		// 						resizeMode: "contain",
		// 						marginRight: 8,
		// 					}}
		// 				/>
		// 				<Text
		// 					style={{
		// 						color: APP_COLOURS.BG2,
		// 						fontWeight: "600",
		// 						fontSize: 17,
		// 					}}>
		// 					Chat {this.props.project_id}
		// 				</Text>

		// 				<View
		// 					style={{
		// 						flex: 1,
		// 					}}
		// 				/>

		// 				{this.state.loading ? null : (
		// 					<CountDownTimer
		// 						duration={20}
		// 						color={APP_COLOURS.BLUE2}
		// 						onCountdownFinish={() => {
		// 							this.fetchChats();
		// 							//	playAudio(4);
		// 						}}
		// 					/>
		// 				)}
		// 			</View>
		// 			<View
		// 				style={{
		// 					flex: 1,
		// 					maxWidth: 850,
		// 					// marginHorizontal: "auto",
		// 					width: "100%",
		// 					padding: 5,
		// 					bottom: 5,
		// 				}}>
		// 				<ScrollView
		// 					ref={(ref) => {
		// 						this.scrollView = ref;
		// 					}}
		// 					showsVerticalScrollIndicator={false}
		// 					onContentSizeChange={() =>
		// 						this.scrollView.scrollToEnd({
		// 							animated: true,
		// 						})
		// 					}
		// 					style={{
		// 						padding: 10,
		// 						flex: 1,
		// 						paddingRight: 18,
		// 					}}>
		// 					{this.state.messages
		// 						.sort((a, b) => {
		// 							return (
		// 								moment(a.dateCreated).unix() -
		// 								moment(b.dateCreated).unix()
		// 							);
		// 						})
		// 						.map((d, i) => {
		// 							if (d.type === "message") {
		// 								return (
		// 									<View
		// 										style={{
		// 											width: "100%",
		// 											alignItems:
		// 												d.sendBy.id ===
		// 												this.props.user_data.id
		// 													? "flex-end"
		// 													: "flex-start",
		// 											marginBottom: 15,
		// 											// borderTopColor: "#E8E8E8",
		// 											paddingLeft: 5,
		// 										}}>
		// 										{d.sendBy.id ===
		// 										this.props.user_data
		// 											.id ? null : (
		// 											<View>
		// 												<View
		// 													style={{
		// 														flexDirection:
		// 															"row",
		// 														alignItems:
		// 															"center",
		// 													}}>
		// 													<Image
		// 														source={
		// 															Avatars[
		// 																parseInt(
		// 																	2,
		// 																)
		// 															].pp
		// 														}
		// 														style={{
		// 															width: 35,
		// 															height: 35,
		// 															borderRadius: 25,
		// 															resizeMode:
		// 																"contain",
		// 															backgroundColor:
		// 																"#FFF",
		// 															borderWidth: 0.5,
		// 															borderColor:
		// 																"#C5C5C5",
		// 															shadowColor:
		// 																"#ECF4FF",
		// 															shadowOffset:
		// 																{
		// 																	width: 0,
		// 																	height: 8,
		// 																},
		// 															shadowOpacity: 0.44,
		// 															shadowRadius: 10.32,

		// 															elevation: 16,

		// 															marginRight: 10,
		// 														}}
		// 													/>
		// 													<View style={{}}>
		// 														<Text
		// 															style={{
		// 																// color: APP_COLOURS.BLUE,
		// 																color: APP_COLOURS.BG2,
		// 																// color: "#FFF",
		// 																fontSize: 15,
		// 																fontWeight:
		// 																	"700",
		// 															}}>
		// 															{
		// 																d.sendBy
		// 																	.first_name
		// 															}{" "}
		// 															{
		// 																d.sendBy
		// 																	.last_name
		// 															}
		// 														</Text>
		// 													</View>
		// 												</View>

		// 												<Text
		// 													style={{
		// 														color: APP_COLOURS.BG2,
		// 														fontWeight:
		// 															"300",
		// 														marginLeft: 40,
		// 														width: "100%",

		// 														marginVertical: 5,
		// 														fontSize: 10,
		// 														alignSelf:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? "flex-end"
		// 																: "flex-start",
		// 													}}>
		// 													{moment(
		// 														d.dateCreated,
		// 													).fromNow()}{" "}
		// 													{moment(
		// 														d.dateCreated,
		// 													).format(
		// 														"HH:mm - DD/MM/YY",
		// 													)}{" "}
		// 												</Text>
		// 											</View>
		// 										)}

		// 										<View
		// 											style={{
		// 												width: "80%",
		// 												// marginLeft: "10%",
		// 												maxWidth: 450,
		// 												// marginLeft: 40,

		// 												backgroundColor:
		// 													d.sendBy.id ===
		// 													this.props.user_data
		// 														.id
		// 														? APP_COLOURS.BLUE
		// 														: "#FFF",
		// 												borderRadius: 12,
		// 												padding: 13,

		// 												marginBottom: 2,
		// 												borderColor: "#D8D8D8",
		// 												borderWidth:
		// 													d.sendBy.id ===
		// 													this.props.user_data
		// 														.id
		// 														? 0.5
		// 														: 0,
		// 												shadowColor: "#D8D8D8",
		// 												shadowOffset: {
		// 													width: 0,
		// 													height: 10,
		// 												},
		// 												shadowOpacity: 0.44,
		// 												shadowRadius: 9.32,
		// 												elevation: 10,
		// 												opacity: 0.9,
		// 											}}>
		// 											<Text
		// 												style={{
		// 													color:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "#FFF"
		// 															: APP_COLOURS.PRIMARY,

		// 													fontSize: 15,
		// 													// opacity: 0.9,
		// 													fontWeight: "400",
		// 													// paddingRight: 15,
		// 												}}>
		// 												{d.text}
		// 											</Text>

		// 											<Image
		// 												source={sentTicks}
		// 												style={{
		// 													width:
		// 														12 * (34 / 25),
		// 													height: 12,
		// 													resizeMode:
		// 														"contain",
		// 													position:
		// 														"absolute",
		// 													right: -5,
		// 													bottom: -5,
		// 													display:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "flex"
		// 															: "none",
		// 												}}
		// 											/>

		// 											<Text
		// 												style={{
		// 													display:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "flex"
		// 															: "none",
		// 													fontWeight: "300",
		// 													color:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "#FFF"
		// 															: APP_COLOURS.PRIMARY,
		// 													width: "100%",
		// 													textAlign: "right",
		// 													// opacity: 0.6,
		// 													marginTop: 20,
		// 													fontSize: 10,
		// 													alignSelf:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "flex-end"
		// 															: "flex-start",
		// 												}}>
		// 												{moment(
		// 													d.dateCreated,
		// 												).fromNow()}{" "}
		// 												{moment(
		// 													d.dateCreated,
		// 												).format(
		// 													"HH:mm - DD/MM/YY",
		// 												)}{" "}
		// 											</Text>
		// 										</View>
		// 										{/* <Text
		// 										style={{
		// 											fontWeight: "300",
		// 											// marginLeft: 10,
		// 											width: "100%",
		// 											textAlign: "left",
		// 											// paddingLeft: 10,
		// 											opacity: 0.6,
		// 											// fontStyle: "italic",
		// 											// color:
		// 											// 	d.sendBy.id ===
		// 											// 	this.props.user_data.id
		// 											// 		? "#FFF"
		// 											// 		: "#797979",
		// 											fontSize: 12,
		// 											alignSelf:
		// 												d.sendBy.id ===
		// 												this.props.user_data.id
		// 													? "flex-end"
		// 													: "flex-start",
		// 											// marginTop: 15,
		// 										}}>
		// 										{moment(d.dateCreated).format(
		// 											"HH:mm - DD MMM YY",
		// 										)}{" "}
		// 									</Text> */}
		// 									</View>
		// 								);
		// 							} else if (d.type === "status") {
		// 								return (
		// 									<View
		// 										style={{
		// 											width: "100%",
		// 											alignItems: "flex-start",
		// 											marginVertical: 15,
		// 											// backgroundColor: TaskStatus(d.text),
		// 											// backgroundColor: "#FFF",
		// 											backgroundColor:
		// 												APP_COLOURS.PURPLE,
		// 											borderRadius: 4,
		// 											borderBottomColor:
		// 												"#E8E8E8",
		// 											borderBottomWidth: 0.5,
		// 											borderTopColor: "#E8E8E8",
		// 											borderTopWidth: 0.5,
		// 											// marginLeft: 34,
		// 										}}>
		// 										<Text
		// 											style={{
		// 												fontWeight: "300",

		// 												color: "#FFF",
		// 												fontSize: 11,
		// 												marginHorizontal: 15,
		// 												top: -20,
		// 												position: "absolute",
		// 												left: 20,
		// 											}}>
		// 											{moment(
		// 												d.dateCreated,
		// 											).format("HH:mm DD/MM/YY")}
		// 										</Text>
		// 										<View
		// 											style={{
		// 												width: "100%",

		// 												borderRadius: 12,
		// 												padding: 10,
		// 												marginBottom: 10,

		// 												opacity: 0.9,
		// 												justifyContent:
		// 													"center",
		// 												alignItems: "center",
		// 											}}>
		// 											<Text
		// 												style={{
		// 													// color: APP_COLOURS.PRIMARY,
		// 													color: "#FFF",
		// 													fontSize: 9,
		// 													opacity: 0.8,
		// 													fontWeight: "900",
		// 												}}>
		// 												STATUS
		// 											</Text>

		// 											<Text
		// 												style={{
		// 													// color: APP_COLOURS.PURPLE,
		// 													color: "#FFF",
		// 													fontSize: 15,
		// 													// opacity: 0.9,
		// 													fontWeight: "500",
		// 												}}>
		// 												{d.text}
		// 											</Text>
		// 										</View>
		// 									</View>
		// 								);
		// 							} else if (d.type === "image") {
		// 								return (
		// 									<View
		// 										style={{
		// 											width: "100%",
		// 											alignItems:
		// 												d.sendBy.id ===
		// 												this.props.user_data.id
		// 													? "flex-end"
		// 													: "flex-start",
		// 											marginBottom: 15,
		// 											paddingLeft: 20,
		// 										}}>
		// 										{d.sendBy.id ===
		// 										this.props.user_data
		// 											.id ? null : (
		// 											<View
		// 												style={{
		// 													flex: 1,
		// 												}}>
		// 												<View
		// 													style={{
		// 														flexDirection:
		// 															"row",
		// 														alignItems:
		// 															"center",
		// 													}}>
		// 													<Image
		// 														source={
		// 															Avatars[
		// 																parseInt(
		// 																	2,
		// 																)
		// 															].pp
		// 														}
		// 														style={{
		// 															width: 35,
		// 															height: 35,
		// 															borderRadius: 25,
		// 															resizeMode:
		// 																"contain",
		// 															backgroundColor:
		// 																"#FFF",
		// 															borderWidth: 0.5,
		// 															borderColor:
		// 																"#C5C5C5",
		// 															shadowColor:
		// 																"#ECF4FF",
		// 															shadowOffset:
		// 																{
		// 																	width: 0,
		// 																	height: 8,
		// 																},
		// 															shadowOpacity: 0.44,
		// 															shadowRadius: 10.32,

		// 															elevation: 16,

		// 															marginRight: 10,
		// 														}}
		// 													/>
		// 													<View style={{}}>
		// 														<Text
		// 															style={{
		// 																color: APP_COLOURS.BLUE,
		// 																fontSize: 15,
		// 																fontWeight:
		// 																	"700",
		// 															}}>
		// 															{
		// 																d.sendBy
		// 																	.first_name
		// 															}{" "}
		// 															{
		// 																d.sendBy
		// 																	.last_name
		// 															}
		// 														</Text>
		// 													</View>
		// 												</View>

		// 												<Text
		// 													style={{
		// 														fontWeight:
		// 															"300",
		// 														marginLeft: 40,
		// 														width: "100%",

		// 														marginVertical: 5,
		// 														fontSize: 10,
		// 														alignSelf:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? "flex-end"
		// 																: "flex-start",
		// 													}}>
		// 													{moment(
		// 														d.dateCreated,
		// 													).fromNow()}{" "}
		// 													{moment(
		// 														d.dateCreated,
		// 													).format(
		// 														"HH:mm - DD/MM/YY",
		// 													)}{" "}
		// 												</Text>
		// 											</View>
		// 										)}
		// 										<TouchableOpacity
		// 											onPress={async () => {
		// 												window.open(
		// 													d.imageData.url,
		// 													"_blank",
		// 												);
		// 											}}>
		// 											<View
		// 												style={{
		// 													// width: "100%",
		// 													// marginLeft: "10%",
		// 													// maxWidth: 450,
		// 													marginLeft: 40,

		// 													backgroundColor:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? "#FFF"
		// 															: APP_COLOURS.BLUE,
		// 													borderRadius: 12,
		// 													// padding: 5,
		// 													overflow: "hidden",

		// 													marginBottom: 2,
		// 													borderColor:
		// 														"#D8D8D8",
		// 													borderWidth:
		// 														d.sendBy.id ===
		// 														this.props
		// 															.user_data
		// 															.id
		// 															? 0.5
		// 															: 0,
		// 												}}>
		// 												<AutoHeightImage
		// 													width={300}
		// 													style={{}}
		// 													source={{
		// 														uri: d.imageData
		// 															.url,
		// 													}}
		// 												/>

		// 												<Image
		// 													source={sentTicks}
		// 													style={{
		// 														width:
		// 															12 *
		// 															(34 / 25),
		// 														height: 12,
		// 														resizeMode:
		// 															"contain",
		// 														position:
		// 															"absolute",
		// 														right: -5,
		// 														bottom: -5,
		// 														display:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? "flex"
		// 																: "none",
		// 													}}
		// 												/>

		// 												<Text
		// 													style={{
		// 														display:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? "flex"
		// 																: "none",
		// 														fontWeight:
		// 															"300",
		// 														color:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? APP_COLOURS.PRIMARY
		// 																: "#FFF",
		// 														width: "100%",
		// 														textAlign:
		// 															"right",
		// 														// opacity: 0.6,
		// 														marginTop: 20,
		// 														fontSize: 10,
		// 														alignSelf:
		// 															d.sendBy
		// 																.id ===
		// 															this.props
		// 																.user_data
		// 																.id
		// 																? "flex-end"
		// 																: "flex-start",
		// 													}}>
		// 													{moment(
		// 														d.dateCreated,
		// 													).fromNow()}{" "}
		// 													{moment(
		// 														d.dateCreated,
		// 													).format(
		// 														"HH:mm - DD/MM/YY",
		// 													)}{" "}
		// 												</Text>
		// 											</View>
		// 										</TouchableOpacity>
		// 									</View>
		// 								);
		// 							}
		// 						})}

		// 					{this.state.sendingMessage ? (
		// 						<View
		// 							style={{
		// 								width: "100%",
		// 								alignItems: "flex-end",
		// 								marginBottom: 15,
		// 							}}>
		// 							<View
		// 								style={{
		// 									width: "70%",
		// 									opacity: 0.8,
		// 									backgroundColor: APP_COLOURS.BLUE2,
		// 									borderRadius: 12,
		// 									padding: 10,

		// 									marginBottom: 3,
		// 								}}>
		// 								<Text
		// 									style={{
		// 										color: "#FFF",
		// 									}}>
		// 									{this.state.sentMessageData}
		// 								</Text>
		// 							</View>

		// 							<ActivityIndicator
		// 								color={APP_COLOURS.BLUE2}
		// 							/>
		// 						</View>
		// 					) : null}
		// 					<View
		// 						style={{
		// 							width: "100%",
		// 							height: 90,
		// 						}}
		// 					/>
		// 				</ScrollView>
		// 			</View>
		// 			{this.composeComponent()}
		// 		</View>
		// 	);
		// } else {
		return (
			<View
				style={{
					flex: 1,
					// backgroundColor:
					// 	window.innerWidth > 600 ? "#FCFCFC" : "#F1F5FA",
					// padding: 10,
					// backgroundColor: APP_COLOURS.BG3,
					borderRadius: 10,
					// maxWidth: 550,
					// padding: 10,
					// backgroundColor: "#FFF",
					// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT5,
					width: "100%",
					// maxWidth: 650,
					// padding: 5,
					paddingBottom: 10,
				}}>
				<View
					style={{
						flex: 1,
						// maxWidth: 850,
						marginHorizontal: "auto",
						width: "100%",
						// padding: 5,
						// bottom: 5,
					}}>
					<View
						style={{
							width: "100%",
							backgroundColor: "#FFF",
							borderRadius: 10,
							padding: 4,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<Image
							source={meetingNotes}
							style={{
								width: 34,
								height: 34,
								resizeMode: "contain",
								marginRight: 8,
							}}
						/>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontWeight: "600",
								fontSize: 17,
							}}>
							Chat
						</Text>

						<View
							style={{
								flex: 1,
							}}
						/>

						{this.state.loading ? null : (
							<CountDownTimer
								duration={20}
								color={APP_COLOURS.BLUE2}
								onCountdownFinish={() => {
									this.fetchChats();
									//	playAudio(4);
								}}
							/>
						)}
					</View>
					<ScrollView
						ref={(ref) => {
							this.scrollView = ref;
						}}
						showsVerticalScrollIndicator={false}
						onContentSizeChange={() =>
							this.scrollView.scrollToEnd({
								animated: true,
							})
						}
						style={{
							padding: 10,
							flex: 1,
							paddingRight: 18,
						}}>
						{/* .sort((a, b) => a.dateUnix > b.dateUnix) */}
						{this.state.messages
							.sort((a, b) => {
								return (
									moment(a.dateCreated).unix() -
									moment(b.dateCreated).unix()
								);
							})
							.map((d, i) => {
								if (d.type === "html") {
									return (
										<View
											style={{
												width: "100%",
												alignItems:
													d.sendBy.id ===
													this.props.user_data.id
														? "flex-end"
														: "flex-start",
												marginBottom: 15,
												// borderTopColor: "#E8E8E8",
											}}>
											<View
												style={{
													width: "70%",
													// marginLeft: "10%",
													maxWidth: 550,

													// backgroundColor:
													// 	d.sendBy.id ===
													// 	this.props.user_data.id
													// 		? APP_COLOURS.BLUE
													// 		: "#FFF",
													backgroundColor: "#FFF",
													borderRadius: 10,
													borderRadius: 12,
													padding: 10,

													marginBottom: 2,
													borderColor: "#D8D8D8",
													borderWidth:
														d.sendBy.id ===
														this.props.user_data.id
															? 0.5
															: 0,
												}}>
												{d.sendBy.id ===
												this.props.user_data
													.id ? null : (
													<View
														style={{
															flexDirection:
																"row",
															width: "100%",
															backgroundColor:
																APP_COLOURS.BACK_TRANSPARENT2,
															paddingVertical: 10,
															borderRadius: 10,
															// marginBottom: 10,
															top: -5,
															// opacity: 0.8,
														}}>
														{typeof d.sendBy
															.avatar !==
														"undefined" ? (
															<Image
																source={
																	Avatars[
																		typeof d
																			.sendBy
																			.avatar !==
																		"undefined"
																			? parseInt(
																					d
																						.sendBy
																						.avatar,
																			  )
																			: 0
																	].pp
																}
																style={{
																	width: 25,
																	height: 25,
																	borderRadius: 25,
																	resizeMode:
																		"contain",
																	backgroundColor:
																		"#FFF",
																	marginRight: 5,
																}}
															/>
														) : (
															<Image
																source={
																	Avatars[1]
																		.pp
																}
																style={{
																	width: 25,
																	height: 25,
																	borderRadius: 25,
																	resizeMode:
																		"contain",
																	backgroundColor:
																		"#FFF",
																	marginRight: 5,
																}}
															/>
														)}
														<View
															style={{
																// width: "100%",
																// marginLeft: 7,
																justifyContent:
																	"flex-start",
																alignItems:
																	"flex-start",
																top: 2,
															}}>
															<Text
																style={{
																	// color:
																	// 	d.sendBy
																	// 		.id ===
																	// 	this
																	// 		.props
																	// 		.user_data
																	// 		.id
																	// 		? APP_COLOURS.PRIMARY
																	// 			: "#FFF",

																	// color: APP_COLOURS.YELLOW,
																	color: APP_COLOURS.BG2,
																	fontSize: 12,
																	// opacity: 0.7,
																	fontWeight:
																		"700",
																}}>
																{
																	d.sendBy
																		.first_name
																}{" "}
																{
																	d.sendBy
																		.last_name
																}
															</Text>
														</View>
													</View>
												)}

												<HtmlLoader
													htmlData={d.htmlData}
												/>

												<Image
													source={sentTicks}
													style={{
														width: 12 * (34 / 25),
														height: 12,
														resizeMode: "contain",
														position: "absolute",
														right: -5,
														bottom: -5,
														display:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "flex"
																: "none",
													}}
												/>

												<Text
													style={{
														fontWeight: "300",
														color: APP_COLOURS.BG5,
														width: "100%",
														textAlign: "right",
														// opacity: 0.6,
														marginTop: 20,
														fontSize: 10,
														alignSelf:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "flex-end"
																: "flex-start",
													}}>
													{moment(
														d.dateCreated,
													).fromNow()}{" "}
													{moment(
														d.dateCreated,
													).format(
														"HH:mm - DD/MM/YY",
													)}{" "}
												</Text>
											</View>
										</View>
									);
								} else if (d.type === "message") {
									return (
										<View
											style={{
												width: "100%",
												alignItems:
													d.sendBy.id ===
													this.props.user_data.id
														? "flex-end"
														: "flex-start",
												marginBottom: 15,
												// borderTopColor: "#E8E8E8",
											}}>
											<View
												style={{
													width: "80%",
													// marginLeft: "10%",
													maxWidth: 450,

													backgroundColor:
														d.sendBy.id ===
														this.props.user_data.id
															? APP_COLOURS.BLUE
															: "#FFF",
													borderRadius: 12,
													padding: 10,

													marginBottom: 2,
													borderColor: "#D8D8D8",
													borderWidth:
														d.sendBy.id ===
														this.props.user_data.id
															? 0.5
															: 0,
													// shadowColor: "#D8D8D8",
													// shadowOffset: {
													// 	width: 0,
													// 	height: 8,
													// },
													// shadowOpacity: 0.44,
													// shadowRadius: 9.32,
													// elevation: 10,
													// opacity: 0.9,
												}}>
												{d.sendBy.id ===
												this.props.user_data
													.id ? null : (
													<View
														style={{
															flexDirection:
																"row",
															width: 200,
															// marginBottom: 10,
															top: -5,
															// opacity: 0.8,
														}}>
														<View
															style={{
																// width: "100%",
																// marginLeft: 7,
																justifyContent:
																	"flex-start",
																alignItems:
																	"flex-start",
																top: 2,
															}}>
															<Text
																style={{
																	// color:
																	// 	d.sendBy
																	// 		.id ===
																	// 	this
																	// 		.props
																	// 		.user_data
																	// 		.id
																	// 		? APP_COLOURS.PRIMARY
																	// 			: "#FFF",

																	// color: APP_COLOURS.YELLOW,
																	color: APP_COLOURS.BG2,
																	fontSize: 12,
																	// opacity: 0.7,
																	fontWeight:
																		"700",
																}}>
																{
																	d.sendBy
																		.first_name
																}{" "}
																{
																	d.sendBy
																		.last_name
																}
															</Text>
														</View>
													</View>
												)}
												<Text
													style={{
														color:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "#FFF"
																: APP_COLOURS.BLUE,
														// color: APP_COLOURS.BG2,
														fontSize: 15,
														// opacity: 0.9,
														fontWeight: "400",
														// paddingRight: 15,
													}}>
													{d.text}
												</Text>

												<Image
													source={sentTicks}
													style={{
														width: 12 * (34 / 25),
														height: 12,
														resizeMode: "contain",
														position: "absolute",
														right: -5,
														bottom: -5,
														display:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "flex"
																: "none",
													}}
												/>

												<Text
													style={{
														fontWeight: "300",
														color:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "#FFF"
																: APP_COLOURS.BLUE,
														width: "100%",
														textAlign: "right",
														// opacity: 0.6,
														marginTop: 20,
														fontSize: 10,
														alignSelf:
															d.sendBy.id ===
															this.props.user_data
																.id
																? "flex-end"
																: "flex-start",
													}}>
													{moment(
														d.dateCreated,
													).fromNow()}{" "}
													{moment(
														d.dateCreated,
													).format(
														"HH:mm - DD/MM/YY",
													)}{" "}
												</Text>
											</View>
											{/* <Text
											style={{
												fontWeight: "300",
												// marginLeft: 10,
												width: "100%",
												textAlign: "left",
												// paddingLeft: 10,
												opacity: 0.6,
												// fontStyle: "italic",
												// color:
												// 	d.sendBy.id ===
												// 	this.props.user_data.id
												// 		? "#FFF"
												// 		: "#797979",
												fontSize: 12,
												alignSelf:
													d.sendBy.id ===
													this.props.user_data.id
														? "flex-end"
														: "flex-start",
												// marginTop: 15,
											}}>
											{moment(d.dateCreated).format(
												"HH:mm - DD MMM YY",
											)}{" "}
										</Text> */}
										</View>
									);
								} else if (d.type === "status") {
									return (
										<View
											style={{
												width: "100%",
												alignItems: "flex-start",
												marginVertical: 10,
												// backgroundColor: TaskStatus(d.text),
												backgroundColor:
													APP_COLOURS.WHITE_TRANSPARENT9,
												borderRadius: 4,
												// opacity: 0.6,
												// shadowColor: "#D8D8D8",
												// shadowOffset: {
												// 	width: 0,
												// 	height: 8,
												// },
												// shadowOpacity: 0.44,
												// shadowRadius: 9.32,
												// elevation: 10,
												borderBottomColor: "#E8E8E8",
												borderBottomWidth: 0.5,
												borderTopColor: "#E8E8E8",
												borderTopWidth: 0.5,
											}}>
											<View
												style={{
													width: "100%",

													borderRadius: 12,
													padding: 10,
													marginBottom: 10,

													opacity: 0.9,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														// color: APP_COLOURS.PRIMARY,

														fontSize: 9,
														// opacity: 0.9,
														fontWeight: "900",
													}}>
													STATUS
												</Text>

												<Text
													style={{
														color: APP_COLOURS.PURPLE,

														fontSize: 15,
														// opacity: 0.9,
														fontWeight: "500",
													}}>
													{d.text}
												</Text>

												<Text
													style={{
														fontWeight: "300",
														fontStyle: "italic",
														color: APP_COLOURS.BG2,
														fontSize: 11,
														marginHorizontal: 15,
													}}>
													{moment(
														d.dateCreated,
													).format("HH:mm DD/MM/YY")}
												</Text>
											</View>
										</View>
									);
								} else if (d.type === "image") {
									return (
										<View
											style={{
												width: "100%",
												alignItems:
													d.sendBy.id ===
													this.props.user_data.id
														? "flex-end"
														: "flex-start",
												marginBottom: 15,
											}}>
											<TouchableOpacity
												onPress={async () => {
													window.open(
														d.imageData.url,
														"_blank",
													);
												}}>
												<View
													style={{
														// backgroundColor:
														// 	d.sendBy.id ===
														// 	this.props
														// 		.user_data
														// 		.id
														// 		? "#FFF"
														// 		: APP_COLOURS.BLUE,
														backgroundColor: "#FFF",
														borderRadius: 12,
														padding: 10,

														marginBottom: 2,
														borderColor: "#D8D8D8",
														borderWidth:
															d.sendBy.id ===
															this.props.user_data
																.id
																? 0.5
																: 0,
													}}>
													{d.sendBy.id ===
													this.props.user_data
														.id ? null : (
														<View>
															<View
																style={{
																	flexDirection:
																		"row",
																	alignItems:
																		"center",
																}}>
																<View
																	style={{}}>
																	<Text
																		style={{
																			color: APP_COLOURS.BG2,
																			fontSize: 14,
																			fontWeight:
																				"700",
																		}}>
																		{
																			d
																				.sendBy
																				.first_name
																		}{" "}
																		{
																			d
																				.sendBy
																				.last_name
																		}
																	</Text>
																</View>
															</View>
														</View>
													)}
													<AutoHeightImage
														width={
															window.innerWidth *
															0.7
														}
														style={{}}
														source={{
															uri: d.imageData
																.url,
														}}
													/>

													<Image
														source={sentTicks}
														style={{
															width:
																12 * (34 / 25),
															height: 12,
															resizeMode:
																"contain",
															position:
																"absolute",
															right: -5,
															bottom: -5,
															display:
																d.sendBy.id ===
																this.props
																	.user_data
																	.id
																	? "flex"
																	: "none",
														}}
													/>
													{/* <Text
															style={{
																fontWeight:
																	"300",
																color:
																	d.sendBy
																		.id ===
																	this.props
																		.user_data
																		.id
																		? APP_COLOURS.PRIMARY
																		: "#FFF",
																width: "100%",
																textAlign:
																	"right",
																// opacity: 0.6,
																marginTop: 20,
																fontSize: 10,
																alignSelf:
																	d.sendBy
																		.id ===
																	this.props
																		.user_data
																		.id
																		? "flex-end"
																		: "flex-start",
															}}>
															{moment(
																d.dateCreated,
															).fromNow()}{" "}
															{moment(
																d.dateCreated,
															).format(
																"HH:mm - DD/MM/YY",
															)}{" "}
														</Text> */}
													<Text
														style={{
															// display:
															// 	d.sendBy
															// 		.id ===
															// 	this.props
															// 		.user_data
															// 		.id
															// 		? "flex"
															// 		: "none",
															fontWeight: "300",
															color:
																d.sendBy.id ===
																this.props
																	.user_data
																	.id
																	? APP_COLOURS.PRIMARY
																	: "#FFF",
															width: "100%",
															textAlign: "right",
															// opacity: 0.6,
															marginTop: 20,
															fontSize: 10,
															alignSelf:
																d.sendBy.id ===
																this.props
																	.user_data
																	.id
																	? "flex-end"
																	: "flex-start",
														}}>
														{moment(
															d.dateCreated,
														).fromNow()}{" "}
														{moment(
															d.dateCreated,
														).format(
															"HH:mm - DD/MM/YY",
														)}{" "}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									);
								}
							})}

						{this.state.sendingMessage ? (
							<View
								style={{
									width: "100%",
									alignItems: "flex-end",
									marginBottom: 15,
								}}>
								<View
									style={{
										width: "70%",
										opacity: 0.8,
										backgroundColor: APP_COLOURS.BLUE2,
										borderRadius: 12,
										padding: 10,

										marginBottom: 3,
									}}>
									<Text
										style={{
											color: "#FFF",
										}}>
										{this.state.sentMessageData}
									</Text>
								</View>

								<ActivityIndicator color={APP_COLOURS.BLUE2} />
							</View>
						) : null}
						<View
							style={{
								width: "100%",
								height: 90,
							}}
						/>
					</ScrollView>
				</View>
				{this.composeComponent()}
			</View>
		);
		// }
	}

	composeComponent = () => {
		return (
			<View
				style={{
					// borderWidth: 0.5,
					// borderColor: "#797979",
					backgroundColor: APP_COLOURS.BLUE,
					// backgroundColor: APP_COLOURS.BG2,
					borderRadius: 15,
					overflow: "hidden",
					// margin: 3,
					marginTop: 0,
					// maxWidth: 850,
					width: "100%",
					// marginHorizontal: "auto",
				}}>
				{/* <View
					style={{
						width: "100%",
						height: 40,
						flexDirection: "row",
						// position: "absolute",
						// bottom: 60,
					}}>
					{["html", "Message"].map((t) => (
						<TouchableOpacity
							style={{
								flex: 1,
							}}
							onPress={async () => {
								this.setState({
									chatTab: t,
								});
							}}>
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
									padding: 4,
									backgroundColor:
										this.state.chatTab === t
											? "#FFF"
											: APP_COLOURS.BLUE,
								}}>
								<Text
									style={{
										color:
											this.state.chatTab === t
												? APP_COLOURS.TEXTCOLOR
												: "#FFF",
									}}>
									{t}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View> */}

				{this.state.chatTab === "html" ? (
					<View
						style={{
							width: "100%",
							// height: "auto",
							// maxHeight: 500,
							// height: 350,
							marginRight: "auto",
							marginLeft: "auto",
							alignItems: "center",
							maxWidth: "100%",

							flexDirection: "row",
							borderRadius: 15,
							// borderWidth: 0.5,
							// borderColor: "#797979",
							padding: 2,
							backgroundColor: "#FFF",
							borderTopLeftRadius: 0,
							justifyContent: "center",
						}}>
						<QuillEditor
							_id={this.state.proposalPageData}
							chatSendMessage={async (html) => {
								this.fetchChats();

								await this.setState({
									sendingMessage: true,
								});

								crudChatWithHtml(
									"tracker_chat",
									{
										project_id: this.props.project_id,
										text: "",
										type: "html",
										sendBy: {
											id: this.props.user_data.id,
											first_name:
												this.props.user_data.first_name,
											last_name:
												this.props.user_data.last_name,
											avatar: this.props.user_data.avatar,
										},
										dateCreated: moment().toISOString(),
										dateUnix: moment().unix(),
										html: html,
									},
									(d, s) => {
										console.log(d, s);
										this.fetchChats();
									},
								);
							}}
							lockContent={false}
						/>

						{/* <TouchableOpacity
							onPress={async () => {
								if (this.inputRef.value === "") {
									return;
								}
								await clearInterval(this.interval);
								await this.setState({
									sentMessageData: this.inputRef.value,
									sendingMessage: true,
								});
								await this.sendMessage({
									project_id: this.props.project_id,
									text: this.inputRef.value,
									type: "message",
									sendBy: {
										id: this.props.user_data.id,
										first_name:
											this.props.user_data.first_name,
										last_name:
											this.props.user_data.last_name,
										avatar: this.props.user_data.avatar,
									},
									dateCreated: moment().toISOString(),
									dateUnix: moment().unix(),
								});

								this.inputRef.clear();
							}}>
							<View
								style={{
									width: 40,
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									// opacity: 0.9,
								}}>
								<Image
									source={send}
									style={{
										width: 35,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity> */}
					</View>
				) : null}

				{this.state.chatTab === "Message" ? (
					<View
						style={{
							width: "100%",
							height: "auto",

							marginRight: "auto",
							marginLeft: "auto",
							alignItems: "center",
							maxWidth: "100%",

							flexDirection: "row",
							borderRadius: 15,
							// borderWidth: 0.5,
							// borderColor: "#797979",
							padding: 2,
							backgroundColor: "#FFF",
							borderTopLeftRadius: 0,
						}}>
						<TextInput
							ref={(ref) => (this.inputRef = ref)}
							onContentSizeChange={this.handleContentSizeChange}
							style={{
								width: "95%",
								paddingLeft: 10,
								paddingTop: 10,
								marginBottom: 5,
								marginTop: 0,
								maxHeight: 300,
								fontSize: 16,
								color: APP_COLOURS.PRIMARY,
								minHeight: 50,
								marginRight: 10,

								height: this.state.inputHeight,
							}}
							multiline
							numberOfLines={1}
							placeholderTextColor={"#797979"}
							clearButtonMode='while-editing'
							autoCorrect={true}
							placeholder={`Type a message`}
							// onChangeText={(text) => {
							// 	this.setState({
							// 		textData: text,
							// 	});
							// }}
						/>

						<TouchableOpacity
							onPress={async () => {
								if (this.inputRef.value === "") {
									return;
								}
								await clearInterval(this.interval);
								await this.setState({
									sentMessageData: this.inputRef.value,
									sendingMessage: true,
								});
								await this.sendMessage({
									project_id: this.props.project_id,
									text: this.inputRef.value,
									type: "message",
									sendBy: {
										id: this.props.user_data.id,
										first_name:
											this.props.user_data.first_name,
										last_name:
											this.props.user_data.last_name,
										avatar: this.props.user_data.avatar,
									},
									dateCreated: moment().toISOString(),
									dateUnix: moment().unix(),
								});

								this.inputRef.clear();
							}}>
							<View
								style={{
									width: 40,
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									// opacity: 0.9,
								}}>
								<Image
									source={send}
									style={{
										width: 35,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity>
					</View>
				) : null}

				{this.state.chatTab === "Status" ? (
					<View
						style={{
							width: "100%",
							height: "auto",

							marginRight: "auto",
							marginLeft: "auto",
							alignItems: "center",
							maxWidth: "100%",

							flexDirection: "row",
							borderRadius: 15,
							// borderWidth: 0.5,
							// borderColor: "#797979",
							padding: 2,
							backgroundColor: "#FFF",
						}}>
						<TextInput
							ref={(ref) => (this.inputRefStatus = ref)}
							onContentSizeChange={this.handleContentSizeChange}
							style={{
								width: "95%",
								paddingLeft: 10,
								paddingTop: 10,
								marginBottom: 5,
								marginTop: 0,
								maxHeight: 200,
								fontSize: 16,
								color: "#D2429C",
								minHeight: 50,
								marginRight: 10,

								height: this.state.inputHeight,
							}}
							multiline
							numberOfLines={1}
							placeholderTextColor={"#797979"}
							clearButtonMode='while-editing'
							autoCorrect={true}
							placeholder={`Type a status update`}
						/>
						<TouchableOpacity
							onPress={async () => {
								if (this.inputRefStatus.value === "") {
									return;
								}
								await clearInterval(this.interval);
								await this.setState({
									sentMessageData: this.inputRefStatus.value,
									sendingMessage: true,
								});

								await this.sendStatus({
									project_id: this.props.project_id,
									text: this.inputRefStatus.value,
									type: "status",
									sendBy: {
										id: this.props.user_data.id,
										first_name:
											this.props.user_data.first_name,
										last_name:
											this.props.user_data.last_name,
										avatar: this.props.user_data.avatar,
									},
									dateCreated: moment().toISOString(),
									dateUnix: moment().unix(),
								});
								this.inputRefStatus.clear();
							}}>
							<View
								style={{
									width: 40,
									height: "auto",
									justifyContent: "center",
									alignItems: "center",
									// opacity: 0.9,
								}}>
								<Image
									source={status}
									style={{
										width: 35,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity>
					</View>
				) : null}

				{this.state.chatTab === "Image" ? (
					<View
						style={{
							width: "100%",
							padding: 4,
							// backgroundColor: "#000",
						}}>
						<View
							style={{
								// flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								width: "auto",
								overflow: "hidden",
								fontSize: 16,
								borderRadius: 12,
								backgroundColor: APP_COLOURS.WHITE,
								color: "#000",
								fontWeight: "200",
								fontFamily: "Gotham",
							}}>
							<ImageUploader
								assets={(assets) => {
									console.log(assets);
									this.setState({
										imagesToUpload: assets,
									});
								}}
							/>
						</View>
						{this.state.imagesToUpload !== "" ? (
							<TouchableOpacity
								onPress={async () => {
									this.setState({
										sentMessageData: "IMAGE UPLOAD",
										sendingMessage: true,
									});
									let obj = {
										project_id: this.props.project_id,
										text: "Image",
										type: "image",
										sendBy: {
											id: this.props.user_data.id,
											first_name:
												this.props.user_data.first_name,
											last_name:
												this.props.user_data.last_name,
										},
										dateCreated: moment().toISOString(),
										dateUnix: moment().unix(),
									};

									const uploadPromises =
										await this.state.imagesToUpload.map(
											(image) => {
												obj.document = image;

												crudCreateWithBase64(
													this.state.crudDocument,
													obj,
													(status_, data) => {
														if (status_ === 200) {
														} else {
														}
													},
												);
											},
										);
									this.setState({
										imagesToUpload: "",
									});
									this.sendImage(obj);
								}}>
								<View
									style={{
										// width: 90,
										width: "100%",
										height: 40,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: APP_COLOURS.GREEN,
										borderRadius: 15,
										marginTop: 4,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 13,
										}}>
										Upload
									</Text>
								</View>
							</TouchableOpacity>
						) : null}
					</View>
				) : null}
			</View>
		);
	};
}
