import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Modal,
	Alert,
	ScrollView,
} from "react-native";
import { APP_COLOURS, TaskStatus, PROJECTSTATUS } from "../../../APP_VARS";
import { crudCreate } from "../../../CRUDHELPER";
import moment from "moment";

export default class StatusItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: this.props.status,
		};

		this.statusTypes = [
			{ title: "Pending", color: "transparent" },
			{ title: "Inprogress", color: "#E8E8E8" },
			{ title: "Delayed", color: APP_COLOURS.ORANGE },
			{ title: "Complete", color: APP_COLOURS.GREEN },
		];
	}

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setState({
				status: this.props.status,
			});
		}
	}

	handleStatusChange = (status) => {
		// if (status === "Completed") {
		// 	// Display an alert for the user to confirm

		// 	Alert.alert(
		// 		"Are you sure?",
		// 		"Do you accept this task to be completed?",

		// 		[
		// 			{
		// 				text: "Cancel",
		// 				onPress: () => console.log("Cancel Pressed"),
		// 				style: "cancel",
		// 			},
		// 			{
		// 				text: "Accept",
		// 				onPress: () => {
		// 					this.setState({
		// 						status: status,
		// 						showModalArea: false,
		// 					});
		// 					this.addPoints();
		// 					setTimeout(() => {
		// 						this.processParent(status);
		// 					}, 200);
		// 				},
		// 			},
		// 		],
		// 		{ cancelable: true },
		// 	);
		// } else {
		this.setState({ status: status, showModalArea: false });

		setTimeout(() => {
			this.processParent(status);
		}, 200);
		// }
	};

	processParent = (status) => {
		this.props.handleStatusChange(this.props.id, status);
	};

	// addPoints = () => {
	// 	let points = this.props.priority;
	// 	let { assignment, assignment_ids } = this.props;

	// 	console.log("assignment", assignment);
	// 	//  return;

	// 	points = points > 0 ? points : 1;

	// 	assignment_ids.forEach((id_) => {
	// 		let item = {
	// 			title: `${this.props.title} - ${this.props.project}`,
	// 			task_id: this.props.id,
	// 			points: points,
	// 			dateCreated: moment(),
	// 			user_id: id_,
	// 		};
	// 		crudCreate("wallet_points", item, (status_) => {});
	// 	});

	// 	Alert.alert(
	// 		"Congratulations!",
	// 		`You received ${points} points into your wallet`,

	// 		[
	// 			{
	// 				text: "Done",
	// 				onPress: () => {},
	// 			},
	// 		],
	// 		{ cancelable: true },
	// 	);

	// 	assignment.forEach((e) => {
	// 		crudSendEmail(
	// 			"SKrum  <skrum@tetrice.co.za>",
	// 			e.username,
	// 			"Task completed",
	// 			`Task: ${this.props.title},  has been completed
	//       <br>
	//       <br>You received ${points} points into your wallet
	//       `,
	// 		);
	// 	});
	// };

	render() {
		let d = this.props;
		let fs = this.props.fs;
		return (
			<View
				style={{
					// flex: 1,
					width: fs * 7,
					// maxWidth: 200,
					backgroundColor: TaskStatus(this.state.status),
					borderRadius: fs / 2,
					overflow: "hidden",
				}}>
				<TouchableOpacity
					style={{
						flex: 1,
					}}
					onPress={(event) => {
						const layout = event.nativeEvent;
						let x = layout.pageX;
						let y = layout.pageY;
						this.setState({
							showModalArea: !this.state.showModalArea,
							// xPos: x + 20,
							// yPos: y - 40,
							selectedTask: d,
							selectedID: d.id,
						});
					}}>
					<View
						style={{
							backgroundColor: TaskStatus(this.state.status),
							padding: fs * 0.6,
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Text
							numberOfLines={1}
							adjustsFontSizeToFit
							style={{
								textAlign: "center",
								color:
									this.state.status === "" ||
									this.state.status === "pending" ||
									this.state.status === "copy" ||
									this.state.status === "Design"
										? "#000"
										: "#000",
								fontSize: fs * 0.8,
							}}>
							{this.state.status}
						</Text>
					</View>
				</TouchableOpacity>

				{this.state.showModalArea ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.75)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.75)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: "",
									});
								}}
							/>

							<View
								style={{
									position: "absolute",
									// top: this.state.yPos + 10,
									// left: this.state.xPos - 270,

									width: 300,
									height: "auto",
									maxHeight: 350,
									backgroundColor: "#20242B",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<ScrollView>
									{PROJECTSTATUS.sort(
										(a, b) => a.type > b.type,
									).map((status, ii) => (
										<TouchableOpacity
											key={ii}
											onPress={() => {
												// if (
												// 	this.state.status ===
												// 	"Completed"
												// ) {
												// 	alert(
												// 		"This task is already complete",
												// 		[
												// 			{
												// 				text: "Cancel",
												// 				onPress: () =>
												// 					console.log(
												// 						"Cancel Pressed",
												// 					),
												// 				style: "done",
												// 			},
												// 		],
												// 	);
												// } else {
												this.handleStatusChange(
													status.status,
												);
												// }
											}}
											style={{
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 2,
												backgroundColor: TaskStatus(
													status.status,
												),
												width: "100%",
												borderRadius: 10,
												// borderWidth: 0.5,
												marginRight: 4,
												// borderColor: '#797979',
											}}>
											<View
												style={{
													padding: 10,
												}}>
												<Text
													numberOfLines={1}
													adjustsFontSizeToFit
													style={{
														fontFamily: "Gotham",
														fontSize: 15,
														fontWeight: "600",
														color:
															status.status ===
															"pending"
																? "#FFF"
																: status.status ===
																		"copy" ||
																  status.status ===
																		"Design" ||
																  status.status ===
																		"Paused" ||
																  status.status ===
																		"Cancelled"
																? "#FFF"
																: "#000",
													}}>
													{status.status}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</ScrollView>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}
}
