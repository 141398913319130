// export const api_login =
// 	"https://workflow.tetrice.co.za/webhook/f23d44a3-d98b-4cca-81c1-9de544935c53";

export const api_getslas =
	"https://workflow.tetrice.co.za/webhook/0c9723f4-2ea1-483c-b2fd-22f98ba067b7";

//
//
//
//
//
//
//
//
//
//

let e = "https://us-central1-tetricepm-5f972.cloudfunctions.net/app/pm";

export const api_login = e + "/login";
export const api_crudCreate = e + "/c";
export const api_crudRead = e + "/r";
export const api_crudWithHtml = e + "/cwhtml";
export const api_crudWChatithHtml = e + "/chatwhtml";
export const api_crudReadUnass = e + "/runass";
export const api_crudWithImage = e + "/cwimg";
export const api_crudUpdate = e + "/u";
export const api_crudDeleteWithImage = e + "/cdelimg";
export const api_crudDeleteDocument = e + "/d";
export const api_crudDeleteAllChatsWithHtml = e + "/chatdelwithhtml";
