import React, { Component } from "react";
import { View, Image, TouchableOpacity, Linking, Text } from "react-native";
import { ScrollView } from "react-native-web";
import { crudGetSlas } from "../CRUDHELPER";
import { APP_COLOURS } from "../APP_VARS";

export default class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slas: [],
			sla2: [
				{
					title: "Community Management - REVLON & Personal Care",
					id: "SLA88421",
					total: "46305",
					brand: "REVLON",
					status: "In Progress",
					code: "CM",
				},
				{
					title: "Community Management - Elizabeth Arden",
					id: "SLA88321",
					total: "19845",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "CM",
				},
				{
					title: "Content Creation - REVLON & Personal Care",
					id: "SLA88422",
					total: "153300",
					brand: "REVLON",
					status: "In Progress",
					code: "CC",
				},
				{
					title: "Content Creation - Elizabeth Arden",
					id: "SLA88322",
					total: "67400",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "CC",
				},

				{
					title: "Digital Merchandising - REVLON & Personal Care",
					id: "SLA88423",
					total: "12950",
					brand: "REVLON",
					status: "In Progress",
					code: "DM",
				},
				{
					title: "Digital Merchandising - Elizabeth Arden",
					id: "SLA88323",
					total: "5550",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "DM",
				},
				{
					title: "Secondment - REVLON & Personal Care",
					id: "SLA88424",
					total: "20125",
					brand: "REVLON",
					status: "In Progress",
					code: "SC",
				},
				{
					title: "Secondment - Elizabeth Arden",
					id: "SLA88324",
					total: "8625",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "SC",
				},
				{
					title: "Tech support - REVLON & Personal Care",
					id: "SLA88425",
					total: "0",
					brand: "REVLON",
					status: "In Progress",
					code: "TS",
				},
				{
					title: "Tech support - Elizabeth Arden",
					id: "SLA88325",
					total: "0",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "TS",
				},
				{
					title: "Skin Tools web portal - Elizabeth Arden",
					id: "SLA88302",
					total: "7500",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "PWA",
				},
				{
					title: "Skin Transformation App - Elizabeth Arden",
					id: "SLA88312",
					total: "8270",
					brand: "Elizabeth Arden",
					status: "In Progress",
					code: "SaaS",
				},

				{
					title: "TRAQ -  Elizabeth Arden",
					id: "SLATRAQ",
					total: "6700",
					brand: "REVLON",
					status: "Pending",
					code: "SaaS",
				},
				{
					title: "Creme of nature web portal",
					id: "SLA88300",
					total: "7500",
					brand: "REVLON",
					status: "Paused",
					code: "PWA",
				},
			],
			selectdMonth: "",
			reports: [
				{
					title: "DIGITAL REPORT - Q1:24",
					id: "SLA88421",
					period: "Dec '23",
					brand: "REVLON",
					category: "digital report",
					url: "SLA88421_Q1_24",
				},
				{
					title: "DIGITAL REPORT - Q1:24",
					id: "SLA88421",
					period: "Dec '23",
					brand: "ELIZABETH ARDEN",
					category: "CM",
					url: "SLA88321_Q1_24",
				},
				{
					title: "DIGITAL REPORT - Q1:24",
					id: "SLA88421",
					period: "Dec '23",
					brand: "CREME OF NATURE",
					category: "CM",
					url: "test",
				},
				{
					title: "Community Management - REVLON & Personal Care",
					id: "SLA88421",
					period: "Dec '23",
					brand: "REVLON",
					category: "CM",
					url: "test",
				},
				{
					title: "test",
					id: "uysdgvy",
					period: "Jan '23",
					brand: "REVLON",
					category: "CM",
					url: "test",
				},
			],
		};
	}

	handlePress = (url) => {
		Linking.openURL(url);
	};

	componentDidMount() {
		// this.getSlas();
	}

	getSlas = () => {
		crudGetSlas((s, d) => {
			if (s === 200) {
				console.log();
				this.setState({
					slas: d.data,
				});
			}
		});
	};

	render() {
		let items = this.state.slas;
		let reports = this.state.reports;
		return (
			<View
				style={{
					flex: 1,
					height: window.innerHeight,
					// backgroundColor: "#1C2B46",

					backgroundColor: "#121419",
					// width: "100%",
					// alignItems: "center",
					overflow: "hidden",
				}}>
				<View
					style={{
						height: window.innerHeight,

						padding: "5%",
						width: "100%",
						// alignItems: "center",
						maxWidth: 1600,
						marginLeft: "auto",
						marginRight: "auto",
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							justifyContent: "space-between",
							flexWrap: "wrap-reverse",
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// minWidth: 320,
								width: "100%",
								paddingBottom: 20,
							}}>
							<TouchableOpacity
								style={{
									// backgroundColor: "#FFF",
									// backgroundColor: "#1F2229",
									// padding: 20,
									borderRadius: 15,
									width: "100%",
									// alignItems: "center",
									// justifyContent: "center",
								}}
								onPress={() =>
									this.setState({
										showSidebar: !this.state.showSidebar,
									})
								}>
								<View
									style={{
										width: "80%",
										maxWidth: 400,
									}}>
									<img
										src={require("./assets/tetrice-revlon-main.png")}
										alt='Description'
										className='responsive'
									/>
								</View>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								height: 0.5,
								backgroundColor: "#1F2229",
							}}
						/>
					</View>
					<View style={{}}>
						<Text
							ellipsizeMode='tail'
							style={{
								color: APP_COLOURS.DESELECTEDCOLOR,
								fontSize: 25,
								fontWeight: "700",
							}}>
							Reports
						</Text>
					</View>
					<View style={{ width: "100%", marginVertical: 10 }}>
						<ScrollView horizontal>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										selectdMonth: "",
									});
								}}>
								<View
									style={{
										backgroundColor:
											this.state.selectdMonth === ""
												? APP_COLOURS.BLUE
												: APP_COLOURS.BG4,
										borderRadius: 10,
										// width: 90,
										padding: 15,
										marginRight: 5,
									}}>
									<Text
										ellipsizeMode='tail'
										style={{
											color: APP_COLOURS.DESELECTEDCOLOR,
											fontSize: 14,
											fontWeight: "700",
										}}>
										All
									</Text>
								</View>
							</TouchableOpacity>
							{[
								`Dec '23`,
								`Jan '24`,
								`Feb '24`,
								`Mar '24`,
								`Apr '24`,
								`May '24`,
								`Jun '24`,
								`Jul '24`,
								`Aug '24`,
								`Sep '24`,
								`Oct '24`,
								`Nov '24`,
								`Dec '24`,
							].map((d, i) => {
								return (
									<TouchableOpacity
										key={i}
										onPress={() => {
											this.setState({
												selectdMonth: d,
											});
										}}>
										<View
											style={{
												backgroundColor:
													this.state.selectdMonth ===
													d
														? APP_COLOURS.BLUE
														: APP_COLOURS.BG4,
												borderRadius: 10,
												// width: 90,
												padding: 15,
												marginRight: 5,
											}}>
											<Text
												ellipsizeMode='tail'
												style={{
													color: APP_COLOURS.DESELECTEDCOLOR,
													fontSize: 14,
													fontWeight: "700",
												}}>
												{d}
											</Text>
										</View>
									</TouchableOpacity>
								);
							})}
						</ScrollView>
					</View>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
						}}>
						<ScrollView showVerticalIndicator={false}>
							<View
								style={
									{
										// flex: 1,
										// width: "100%",
									}
								}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
									}}>
									<View
										style={{
											flex: 1,
											paddingTop: 30,
											justifyContent: "flex-start",
											alignItems: "flex-start",
											flexWrap: "wrap",
											// maxWidth: 1200,
										}}>
										{reports
											.filter((d) =>
												this.state.selectdMonth !== ""
													? d.period ===
													  this.state.selectdMonth
													: d,
											)
											.map((item, i) => (
												<TouchableOpacity
													key={i}
													style={{
														padding: 10,
														// backgroundColor: "#3A475E",
														// backgroundColor: "#1F2229",
														marginBottom: 20,
														borderRadius: 10,
														width: "100%",
														flexDirection: "row",
														alignItems: "center",
													}}
													onPress={() =>
														this.handlePress(
															`/reports/${item.url}?token=1234`,
														)
													}>
													<View
														style={{
															width: 40,
															height: 40,
															borderRadius: 10,
															backgroundColor:
																APP_COLOURS.BG5,
															marginRight: 15,
														}}
													/>
													<Text
														style={{
															fontSize: 16,
															fontWeight: "bold",
															color: "#FFF",
															// opacity: 0.8,
														}}>
														{item.date}
													</Text>
													<View>
														<Text
															style={{
																fontSize: 12,
																color: "#FFF",
																opacity: 0.7,
															}}>
															{item.brand}
														</Text>
														<Text
															style={{
																fontSize: 16,
																// fontWeight: "bold",
																color: "#FFF",
																// opacity: 0.8,
															}}>
															{item.title}
														</Text>

														<Text
															style={{
																fontSize: 9,
																color: "#FFF",
																opacity: 0.5,
															}}>
															{item.id}
														</Text>
													</View>
												</TouchableOpacity>
											))}
									</View>
								</View>
							</View>
						</ScrollView>
						<ScrollView showVerticalIndicator={false}>
							<View
								style={
									{
										// flex: 1,
										// width: "100%",
									}
								}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
									}}>
									<View
										style={{
											flex: 1,
											paddingTop: 30,
											justifyContent: "flex-start",
											alignItems: "flex-start",
											flexWrap: "wrap",
											// maxWidth: 1200,
										}}>
										{items.map((item, i) => (
											<TouchableOpacity
												key={i}
												style={{
													padding: 10,
													// backgroundColor: "#3A475E",
													backgroundColor: "#1F2229",
													marginBottom: 20,
													borderRadius: 10,
													width: "100%",
													minWidth: 300,
													maxWidth: 340,
												}}
												onPress={() =>
													this.handlePress(item.url)
												}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														color: "#FFF",
														opacity: 0.8,
													}}>
													{item.period}
												</Text>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														color: "#FFF",
														opacity: 0.8,
													}}>
													{item.title}
												</Text>
												<Text
													style={{
														fontSize: 14,
														color: "#FFF",
														opacity: 0.5,
													}}>
													{item.description}
												</Text>
											</TouchableOpacity>
										))}
									</View>
								</View>
							</View>
						</ScrollView>
					</View>
				</View>
				<View
					style={{
						// flexDirection: "row",
						// justifyContent: "flex-end",
						// maxWidth: 1200,
						// flex: 1,
						// alignItems: "flex-end",
						position: "absolute",
						bottom: 0,
						right: 0,
						zIndex: 99,
						width: 200,
						height: "auto",
						// flexDirection: "row",
						alignItems: "center",
					}}>
					{/* <Image
						source={require("./assets/infod.png")}
						style={{
							width: 200,
							maxWidth: 300,
							height: 30,
							resizeMode: "contain",
						}}
					/> */}
					<Image
						source={require("./assets/services.png")}
						style={{
							width: 200,
							height: 50,
							maxWidth: 300,
							resizeMode: "contain",
						}}
					/>
				</View>
			</View>
		);
	}
}
