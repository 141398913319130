import React, { useState, useEffect } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	TextInput,
} from "react-native";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./custome.css";
import { crudCreateWithHtml } from "../../../../../CRUDHELPER";
// import { APP_COLOURS } from "../../../../../APP_VARS";

const QuillEditor = ({ _id, chatSendMessage, lockContent }) => {
	const [pageTitle, setpageTitle] = useState("");
	const [editorHtml, setEditorHtml] = useState("");
	const [saveHtml, setSaveHtml] = useState(false);

	const modules = {
		toolbar: {
			container: [
				[{ header: "1" }, { header: "2" }, { font: [] }],
				// [{ size: [] }],
				["bold", "italic", "underline"],
				[
					{ list: "ordered" },
					{ list: "bullet" },
					// { indent: "-1" },
					// { indent: "+1" },
				],
				["link", "image", "video"],
				// ["clean"],
			],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	const handleSave = async () => {
		setSaveHtml(true);
		chatSendMessage(editorHtml);
		setEditorHtml("");
	};

	return (
		<div className='ql-editor'>
			<ReactQuill
				theme='bubble'
				value={editorHtml}
				onChange={handleChange}
				modules={modules}
				formats={formats}
			/>
			<div className='cursor'>
				<TouchableOpacity
					style={{
						borderRadius: 10,
						justifyContent: "center",
						alignItems: "center",
						marginVertical: 5,
						flexDirection: "row",
						overflow: "hidden",
					}}
					onPress={() => {
						handleSave();
					}}>
					<Image
						source={require("./button.svg").default}
						style={{
							width: "101%",
							height: 45,
							resizeMode: "cover",
						}}
					/>

					<Text
						style={{
							fontSize: 16,
							marginTop: 2,
							fontWeight: "600",
							color: "#FFF",
							position: "absolute",
						}}>
						Send
					</Text>
				</TouchableOpacity>
			</div>
		</div>
	);
};

export default QuillEditor;
