import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { InView } from "react-intersection-observer";
import logo_tetrice from "./assets/logo_tetrice.png";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/animation_lm5gydv9.json";
import * as animationData from "./assets/animation_lm5h8sfa.json";

import bg2 from "./assets/bg2.svg";
import bg1 from "./assets/bg_tech.png";
import { APP_COLOURS } from "../APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class ProposalHeading2 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		const defaultOptions1 = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			window.innerWidth < window.innerHeight
				? window.innerWidth * 0.9
				: window.innerHeight * 0.7;

		let maxContain = contain > 600 ? 600 : contain;

		let ar = 636 / 545;

		let h = window.innerHeight;
		let w = window.innerWidth;

		let { h1, h2, pp } = this.props;

		h1 = w > 750 ? h1 * 1.5 : h1;
		h2 = w > 750 ? h2 * 1.5 : h2;
		pp = w > 750 ? pp * 1.5 : pp;

		let isMobile = w < 750;

		if (!this.state.inView) {
			return (
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight * 0.8,
						// backgroundColor: "#1F2229",
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
					}}>
					{/* <Image
						source={logo_tetrice}
						style={{
							width: 150,
							height: 90,
							resizeMode: "contain",
						}}
					/> */}
					{/* <View
						style={{
							width: "90%",
							maxWidth: 700,
						}}>
						<img
							src={require("./assets/tetrice-revlon-main.png")}
							alt='Description'
							className='responsive'
						/>
					</View> */}
				</View>
			);
		} else
			return (
				<View
					style={{
						width: window.innerWidth,
						backgroundColor: "#171A20",
						zIndex: 999,
					}}>
					<View
						style={{
							width: window.innerWidth,
							// flex: 1,
							flexDirection: isMobile ? "column" : "row",
							alignItems: "center",
							justifyContent: "space-between",
							flexWrap: "wrap",
							height: window.innerHeight * 0.9,

							// backgroundColor: "#171A20",
							padding: "5%",
							// maxHeight: 750,
							// overflow: "hidden",
							// maxWidth: 1500,
							marginLeft: "auto",
							marginRight: "auto",
						}}>
						{/* <View
							style={{
								position: "absolute",
								top: 0,
								right: 0,
								width: "100%",
								alignItems: "flex-end",
								justifyContent: "center",
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: "100%",
									height: "100%",
									resizeMode: "cover",
								}}
							/>
						</View> */}
						<View
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								width: "100%",
								opacity: 0.3,
							}}>
							<Lottie
								options={defaultOptions}
								height={window.innerHeight}
								width={window.innerWidth}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								top: 0,
								right: 0,
								width: "100%",
								height: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									width: "90%",
									maxWidth: 700,
								}}>
								<img
									src={require("./assets/tetrice-revlon-main.png")}
									alt='Description'
									className='responsive'
								/>
							</View>
						</View>
					</View>
				</View>
			);
	}
}
