import React, { Component } from "react";
import { View, Text } from "react-native";

export default class HtmlLoader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			html: "",
		};
	}

	componentDidMount() {
		this.getLargeHtmlData(this.props.htmlData?.htmlFileUrl, (html) => {});
	}

	getLargeHtmlData = (geturl, next) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				url: geturl,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((html) => {
				this.setState({
					html: html.data,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	render() {
		return (
			<View style={{ width: "100%" }}>
				<div
					dangerouslySetInnerHTML={{
						__html: this.state.html,
					}}
				/>
			</View>
		);
	}
}
