import React, { useState, useEffect } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
} from "react-native";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import Quill styles
import "./custome.css";

const MyQuillEditor = ({
	proposalData,
	proposalPageData,
	reloadData,
	lockContent,
}) => {
	const [editorHtml, setEditorHtml] = useState("");
	const [saveHtml, setSaveHtml] = useState(false);

	useEffect(() => {
		setSaveHtml(lockContent);
		if (proposalPageData && proposalPageData.html) {
			setEditorHtml(proposalPageData.html);
		}
	}, [proposalPageData, lockContent]);

	const modules = {
		toolbar: {
			container: [
				[{ header: "1" }, { header: "2" }, { font: [] }],
				[{ size: [] }],
				["bold", "italic", "underline", "strike", "blockquote"],
				[
					{ list: "ordered" },
					{ list: "bullet" },
					{ indent: "-1" },
					{ indent: "+1" },
				],
				["link", "image", "video"],
				["clean"],
			],
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
	];

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	// const handleSave = async () => {
	// 	setSaveHtml(true);
	// 	try {
	// 		const response = await fetch(
	// 			"https://workflow.tetrice.co.za/webhook-test/a643d6b3-e05c-47da-a13c-77e4951c6508",
	// 			{
	// 				// Replace with your backend endpoint
	// 				method: "POST",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 				},
	// 				body: JSON.stringify({
	// 					html: editorHtml,
	// 					proposal_id: proposalData._id,
	// 					_id: proposalPageData._id,
	// 				}),
	// 			},
	// 		)
	// 			.then((response) => {
	// 				if (!response.ok) {
	// 					throw new Error("Failed to fetch data");
	// 				}
	// 				return response.json();
	// 			})
	// 			.then((data) => {
	// 				console.log(data);
	// 				reloadData();
	// 			})
	// 			.catch((error) => {});
	// 	} catch (error) {
	// 		console.error("Error saving HTML content:", error);
	// 	}
	// };

	const handleSave = async () => {
		setSaveHtml(true);

		try {
			const chunkSize = 1024 * 1024; // 1MB chunks (adjust as needed)
			const totalChunks = Math.ceil(editorHtml.length / chunkSize);

			for (let i = 0; i < totalChunks; i++) {
				const chunk = editorHtml.slice(
					i * chunkSize,
					(i + 1) * chunkSize,
				);

				const response = await fetch(
					"https://workflow.tetrice.co.za/webhook-test/a643d6b3-e05c-47da-a13c-77e4951c6508",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							html: chunk,
							proposal_id: proposalData._id,
							_id: proposalPageData._id,
							totalChunks,
							currentChunk: i + 1, // Chunk index (1-based)
						}),
					},
				);

				if (!response.ok) {
					throw new Error("Failed to upload chunk");
				}
			}

			// All chunks uploaded successfully
			console.log("All chunks uploaded successfully");
			reloadData();
		} catch (error) {
			console.error("Error uploading chunks:", error);
		} finally {
			setSaveHtml(false); // Reset save state
		}
	};

	return (
		<div className='ql-editor'>
			{!saveHtml ? (
				<>
					<ReactQuill
						theme='snow'
						value={editorHtml}
						onChange={handleChange}
						modules={modules}
						formats={formats}
					/>
					{/* <TouchableOpacity> */}
					{/* <button onClick={handleSave}>Save</button> */}
					<div className='cursor'>
						<TouchableOpacity
							style={{
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								marginVertical: 25,
								flexDirection: "row",
								overflow: "hidden",
							}}
							onPress={() => {
								handleSave();
							}}>
							<Image
								source={require("./button.svg").default}
								style={{
									width: "101%",
									height: 45,
									resizeMode: "cover",
								}}
							/>

							<Text
								style={{
									fontSize: 16,
									marginTop: 2,
									fontWeight: "600",
									color: "#FFF",
									position: "absolute",
								}}>
								Save & Update
							</Text>
						</TouchableOpacity>
					</div>
				</>
			) : (
				<div dangerouslySetInnerHTML={{ __html: editorHtml }} />
			)}
		</div>
	);
};

export default MyQuillEditor;
