import React, { Component } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
} from "react-native";
import Fade from "react-reveal/Fade";
import AuditLandingPage from "./LandingPage";

import logo_tetrice from "../assets/tetrice-revlon-main.png";

import ProposalPage from "./PresentationPage";

import * as findTarget from "../assets/64198-find-target.json";
import * as creation from "../assets/134987-expo-logic-eventreg-marketing-lp-hero.json";
import * as ecosystem from "../assets/86836-data-dashboard.json";
import * as infographics from "../assets/91239-customer.json";
import * as customer from "../assets/28608-make-payment.json";
import * as brandidentity from "../assets/122694-brand-identity.json";
import * as animationData2 from "../assets/23626-infographics-circle.json";
import * as content from "../assets/141255-influencer-green.json";
import * as social from "../assets/72675-social-media.json";
import { crudGetPresentationData } from "../../CRUDHELPER";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
import EtailerAuditPage from "./EtailerAudit";
import VTOPage from "./VTOPage";
import ProductListingPage from "./ProductListing";
import Font from "react-font";
// import ProposalPageEditor from "./ProposalPageEditor.js";

import QuillEditor from "./Editor/QuillEditor";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class AuditReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,

			proposalData: "",
			proposalPages: [],
			proposalPageData: "",
			lockContent: true,
		};
	}

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = localStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			localStorage.clear();
			this.setState({
				loading: false,
			});
		}

		this.fetchProposalData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		// setTimeout(() => {
		// 	this.setState({
		// 		loading: false,
		// 	});
		// }, 2000);
	}

	fetchProposalData = async () => {
		this.setState({
			loading: true,
			proposalData: "",
			proposalPages: [],
			proposalPageData: "",
		});
		let url =
			"https://workflow.tetrice.co.za/webhook/b860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				_id: "wbSf4WOgIlrS6HUL2FOB",
				title: "Platform Audit",
				description:
					"Platform audit of revlon.co.za and all it's respective ecommerce partner pages.",
				createdBy: {
					avatar: "4",
					fullName: "Wesley Cheia",
				},
				title2: "Q1 & Q2 - 2024",
				subHeading: "revlon.co.za",
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalData: data,
				});
				this.fetchProposalPages(data._id);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};
	fetchProposalPages = async (_id) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/a61608aa-7bdb-4ca0-88ce-58ce856ee0f4";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				proposal_id: _id,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalPages: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	updateDocument = () => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook-test/b860d5cc-6e8e-4542-8566-af350fe44694";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				_id: "wbSf4WOgIlrS6HUL2FOB",
				title: "Platform Audit",
				description:
					"Platform audit of revlon.co.za and all it's respective ecommerce partner pages.",
				createdBy: {
					avatar: "4",
					fullName: "Wesley Cheia",
				},
				title2: "Q1 & Q2 - 2024",
				subHeading: "revlon.co.za",
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				this.setState({
					proposalData: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	getLargeHtmlData = (geturl, next) => {
		// POST this data from the parent list or create a new proposal
		let url =
			"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				url: geturl,
			}),
		};
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				return response.json();
			})
			.then((html) => {
				return next(html);
			})
			.catch((error) => {
				this.setState({
					error: error,
					loading: false,
				});
			});
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getPresentationData = async (sheetName) => {
		let dd = [];
		await crudGetPresentationData(sheetName, (s, d) => {
			if (s === 200) {
				return (dd = d);
			}
		});
		await console.log(dd);
		return dd;
	};

	render() {
		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1000;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 16 : 20;
		let h2 = pp * 1.2618;
		let h1 = h2 * 1.2618;
		let h0 = h1 * 1.2618;
		let maxFont = 40;

		let mw = 1000;

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						overflow: "hidden",
						backgroundColor: "#171A20",
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollRef = ref;
						}}
						overScrollMode='never'
						showsVerticalScrollIndicator={false}
						// pagingEnabled={true}
						// decelerationRate='fast'
						// scrollEventThrottle={160}
						// snapToInterval={this.state.screenHeight}
						contentContainerStyle={{
							alignItems: "center",

							// backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
						}}>
						<AuditLandingPage
							{...this.state}
							h0={h0}
							h1={h1}
							h2={h2}
							pp={pp}
							mw={mw}
							// title={this.state.proposalData?.title}
							// title2={this.state.proposalData?.title2}
							// subTitle={this.state.proposalData?.subTitle}
							// description={this.state.proposalData?.description}
							// createdBy={this.state.proposalData?.createdBy}
						/>

						<View
							style={{
								flexDirection: "row",
								marginBottom: 20,
								width: "100%",
								maxWidth: 1000,
								alignItems: "center",
							}}>
							<ScrollView horizontal>
								{this.state.proposalPages.map((d) => (
									<TouchableOpacity
										onPress={async () => {
											await this.setState({
												proposalPageData: "",
											});
											if (
												typeof d.htmlData !==
												"undefined"
											) {
												this.getLargeHtmlData(
													d.htmlData?.htmlFileUrl,
													(html) => {
														console.log(html);
														this.setState({
															proposalPageData: {
																...d,
																html: html.data,
															},
														});
													},
												);
											} else {
												await this.setState({
													proposalPageData: {
														...d,
														html: "",
													},
												});
											}
										}}>
										<View
											style={{
												flex: 1,
												alignItems: "center",
												justifyContent: "center",
												borderRadius: 10,
												borderWidth: 1,
												borderColor: "#FFF",
												height: 60,
												marginRight: 5,
												padding: 20,
											}}>
											<Text
												style={{
													fontSize: 16,
													color: APP_COLOURS.DESELECTEDCOLOR,
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{d._id}
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</ScrollView>
							{/* <TouchableOpacity
								onPress={() => {
									this.setState({
										lockContent: !this.state.lockContent,
									});
								}}>
								<View
									style={{
										width: 120,
										alignItems: this.state.lockContent
											? "flex-start"
											: "flex-end",
										backgroundColor: APP_COLOURS.BG4,
										borderRadius: 15,
										height: 40,
									}}>
									<View
										style={{
											width: 80,
											height: 40,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 13,
											backgroundColor: !this.state
												.lockContent
												? "white"
												: APP_COLOURS.BLUE,
											paddingHorizontal: 10,
										}}>
										<Text
											style={{
												fontSize: 13,
												color: !this.state.lockContent
													? "blue"
													: APP_COLOURS.DESELECTEDCOLOR,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{!this.state.lockContent
													? "Unlocked"
													: "Locked"}
											</Font>
										</Text>
									</View>
								</View>
							</TouchableOpacity> */}
						</View>

						{/* {this.state.proposalPages.map((d, i) => ( */}
						{this.state.proposalPageData !== "" ? (
							<View
								style={{
									width: "100%",
									// alignItems: "center",
									paddingTop: 100,
									justifyContent: "center",
									width: "100%",
									maxWidth: 1000,
								}}>
								<QuillEditor
									proposalData={this.state.proposalData}
									proposalPageData={
										this.state.proposalPageData
									}
									reloadData={() => {
										this.fetchProposalData();
									}}
									lockContent={this.state.lockContent}
								/>
							</View>
						) : null}

						{/* ))} */}

						<View
							style={{
								height: 300,
							}}
						/>

						<View style={{ width: "100%" }}>
							{/* <ProposalPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
									{...{
										title: "Digital Audit",
										title2: "",
										animationData: infographics,
										items: [],
										sheetName: "OverviewPage",
									}}
								/> */}

							{/* <VTOPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "VTO - revlon.co.za",
									title2: "",
									animationData: content,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<EtailerAuditPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "E-Commerce Audit",
									title2: "",
									animationData: brandidentity,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<ProductListingPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "Website Listings",
									title2: "",
									animationData: infographics,
									items: [],
									sheetName: "OverviewPage",
								}}
							/>

							<ProposalPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
								{...{
									title: "Page 2 month",
									title2: "",
									animationData: findTarget,
									items: [],
									sheetName: "Page2",
								}}
							/> */}
						</View>
					</ScrollView>
				</View>
			);
	}
}
