import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "../APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/83340-social-media-icons.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page6 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							paddingTop: "10%",
							zIndex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Connecting on Every Level
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,

													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													Building meaningful
													relationships with our
													clients across all social
													platforms.
												</Font>
											</Text>
										</View>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.4}
											width={h * 0.35}
										/>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: window.innerWidth * 0.8,
										maxWidth: mw,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginVertical: 20,
												},
												fontS,
											]}>
											Our vision is to establish Revlon &
											Elizabeth Arden as a leading luxury
											retail brands, renowned for its
											exceptional customer engagement,
											innovative communication strategies,
											and exclusive community experiences.
											By leveraging the power of PR and
											community management, we aim to
											create a lasting connection with our
											target audience and elevate our
											brand's reputation in the luxury
											market. Our PR and community
											management strategy is designed to
											achieve the following objectives:
										</Text>

										<View
											style={{
												// flex: 1,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											{[
												{
													title: "Increase brand awareness:",
													desc: "Utilize targeted PR efforts and compelling content to expand our reach, making Revlon & Elizabeth Arden  synonymous with luxury and sophistication.",
												},
												{
													title: "Foster brand loyalty:",
													desc: "Utilize targeted PR efforts and compelling content to expand our reach, making Revlon & Elizabeth Arden  synonymous with luxury and sophistication.",
												},
												{
													title: "Attract new customers: ",
													desc: "Leverage the influence of industry tastemakers and creative marketing campaigns to reach potential customers and showcase the unparalleled quality and craftsmanship of Revlon & Elizabeth Arden  products.",
												},
												{
													title: "Drive revenue growth: ",
													desc: "Strengthen our brand's position in the market, driving customer acquisition and repeat purchases, ultimately resulting in increased sales and profitability",
												},
												{
													title: "Enhance brand reputation: ",
													desc: "Establish Revlon & Elizabeth Arden  as an industry leader by showcasing our commitment to sustainability, social responsibility, and exceptional customer experiences.",
												},
											].map((d, i) => (
												<View
													style={{
														flex: 1,
														padding: 10,
														backgroundColor: "#FFF",
														marginRight: 10,
														borderRadius: 15,
													}}>
													<Text
														style={[
															fontS,
															{
																fontWeight:
																	"500",
																marginBottom: 5,
																marginTop: 10,
															},
														]}>
														{d.title}
													</Text>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.TEXTCOLOR,
															marginBottom: 5,
															flexDirection:
																"row",
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															{d.desc}
														</Font>
													</Text>
												</View>
											))}
										</View>

										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											By focusing on these objectives and
											implementing a data-driven,
											customer-centric approach, we will
											propel Revlon & Elizabeth Arden to
											new heights in the luxury retail
											landscape, creating a legacy that
											endures for generations to come.
										</Text>
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
