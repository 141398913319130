import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import Lottie from "react-lottie";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import { crudGetPresentationData } from "../../CRUDHELPER";

export default class ReportProposalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
		};
	}

	componentDidMount() {
		crudGetPresentationData(this.props.sheetName, (s, d) => {
			if (s === 200) {
				this.setState({
					items: d,
				});
			}
		});
	}

	render() {
		let h = window.innerHeight;
		let w = window.innerWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 265 / 345;

		let bias = h > w ? w : h;

		let mw = 800;
		let { items } = this.state;
		let { h1, h2, pp, h0 } = this.props;

		return (
			<View
				style={{
					width: "100%",
					height: "auto",
					// justifyContent: "center",
					// alignItems: "center",
					paddingTop: h1,
				}}>
				{this.left(h1, h2, pp, h0)}

				<View
					style={{
						width: "100%",
						// maxWidth: mw,
					}}>
					{items.map((d) => {
						console.log(d);
						return (
							<View style={{ width: "100%" }}>
								<Text
									style={{
										fontSize: h2,
										color: APP_COLOURS.DESELECTEDCOLOR,
										marginBottom: pp,
										// marginTop: pp,
									}}>
									<Font family='Montserrat' weight={500}>
										{d.subHead}
									</Font>
								</Text>
								{/* {d.subParagraphs.map((sp) => (
									<Text
										style={{
											fontSize: pp,
											color: APP_COLOURS.DESELECTEDCOLOR,
											marginBottom: pp,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={300}>
											{sp}
										</Font>
									</Text>
								))} */}
								<Text
									style={{
										fontSize: pp,
										color: APP_COLOURS.DESELECTEDCOLOR,
										marginBottom: pp,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										{d.subParagraphs}
									</Font>
								</Text>
							</View>
						);
					})}
				</View>
			</View>
		);
	}

	left = (h1, h2, pp, h0) => {
		let h = window.innerHeight * 0.5;
		let w = window.innerWidth * 0.5;

		return (
			<View
				style={{
					flexDirection:
						window.innerWidth < 800 ? "column" : "row-reverse",
					flexWrap: "wrap",
					marginBottom: h1,
					// marginBottom: "10%",
					// minHeight: window.innerHeight,
					justifyContent: "space-between",
					height: "auto",
					alignItems:
						window.innerWidth < 800 ? "flex-start" : "flex-end",
				}}>
				<View
					style={{
						width: "90%",
						maxWidth: 500,
						justifyContent: "flex-end",
						alignItems: "flex-end",
					}}>
					{/* <img
						src={require("../assets/shoutImage.png")}
						alt='img'
						className='responsive'
					/> */}

					<View
						style={{
							// flex: 1,
							padding: 20,
						}}>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: this.props.animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={h * 0.75 > 350 ? 350 : h * 0.75}
							width={h * 0.75 > 350 ? 350 : h * 0.75}
						/>
					</View>
				</View>
				<View
					style={
						{
							// opacity: 0.9,
							// flexDirection: "row",
						}
					}>
					<View
						style={{
							width: "90%",
							maxWidth: 200,
							height: pp,
							marginBottom: 4,
						}}>
						<img
							src={require("./Social Media.png")}
							alt='img'
							className='responsive'
						/>
					</View>
					<Font family='Gotham' weight={300}>
						<Text
							style={{
								fontSize: h0,
								color: "#FFF",
								// fontFamily: "Gotham",
								fontWeight: "900",
							}}>
							{this.props.title}:
						</Text>
					</Font>
					<Font family='Gotham' weight={300}>
						<Text
							ellipsizeMode='clip'
							style={{
								fontSize: h1,
								opacity: 0.7,
								color: "#FFF",
								// fontFamily: "Gotham",
								flexWrap: "wrap",
							}}>
							{this.props.title2}
						</Text>
					</Font>
				</View>
			</View>
		);
	};
}
