import React, { useState, useEffect } from "react";
import {
	View,
	Image,
	ScrollView,
	Linking,
	Text,
	TouchableOpacity,
	TextInput,
} from "react-native";

import { APP_COLOURS } from "../../../../../APP_VARS";
import ReactQuill from "react-quill";

// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "./custome.css";

const SimpleHtmlEditor = ({ setHtml, updatehtml, lockContent }) => {
	const [pageTitle, setpageTitle] = useState("");
	const [editorHtml, setEditorHtml] = useState("");
	const [saveHtml, setSaveHtml] = useState(false);

	useEffect(() => {
		setSaveHtml(lockContent);
		console.log(setHtml);
		if (setHtml.title) {
			setpageTitle(setHtml.title);
		}
		if (setHtml.html) {
			setEditorHtml(setHtml.html);
		}
	}, [setHtml, lockContent]);

	const modules = {
		toolbar: {
			container: [
				[{ header: "1" }, { header: "2" }],
				// [{ header: [1, 2, 3] }],

				["bold", "italic", "underline", "strike"],
				[
					{ list: "ordered" },
					{ list: "bullet" },
					{ indent: "-1" },
					{ indent: "+1" },
				],
				// [{ color: [] }, { background: [] }],

				["link", "image", "video"],
				// ["clean"],
			],
		},
		clipboard: {
			matchVisual: false, // allows inserting raw HTML
		},
	};

	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"video",
		"table",
		"align",
		"color",
		"background",
	];

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	const handleSave = async () => {
		setSaveHtml(true);
		// console.log({
		// 	html: editorHtml,
		// 	_id: setHtml._id,
		// 	title: pageTitle,
		// });

		updatehtml(editorHtml, pageTitle);
	};

	return (
		<>
			<div className='ql-editor'>
				{!saveHtml ? (
					<>
						{/* <View
						style={{
							// height: "auto",
							// width: "100%",
							flex: 1,
							minWidth: 200,
							// maxWidth: 400,
							// backgroundColor: APP_COLOURS.BLACK_TRANS,
							borderRadius: 13,
						}}>
						<TextInput
							style={{
								fontWeight: "400",
								fontFamily: "Gotham",

								width: "100%",
								paddingLeft: 15,
								padding: 10,
								fontSize: 20,
								borderRadius: 13,
								marginRight: 15,
								color: "#FFF",
								borderWidth: 1,
								borderColor: APP_COLOURS.DESELECTEDCOLOR,
								// backgroundColor:
								// 	APP_COLOURS.WHITE_TRANSPARENT4,
								backgroundColor: "#FFF",
								marginBottom: 20,
								fontWeight: "500",
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={true}
							autoFocus
							keyboardType='default'
							textContentType='none'
							placeholder={` Page title `}
							// defaultValue={this.state.user_data.email}
							defaultValue={pageTitle}
							onChangeText={(text) => {
								console.log(text);
								setpageTitle(text);
							}}
						/>
					</View> */}
						<ReactQuill
							theme='bubble'
							value={editorHtml}
							onChange={handleChange}
							modules={modules}
							formats={formats}
							preserveWhitespace={true}
						/>
						{/* <TouchableOpacity> */}
						{/* <button onClick={handleSave}>Save</button> */}
						<div className='cursor'>
							<TouchableOpacity
								style={{
									borderRadius: 10,
									justifyContent: "center",
									alignItems: "center",
									marginVertical: 25,
									flexDirection: "row",
									overflow: "hidden",
								}}
								onPress={() => {
									handleSave();
								}}>
								<Image
									source={require("./button.svg").default}
									style={{
										width: "101%",
										height: 45,
										resizeMode: "cover",
									}}
								/>

								<Text
									style={{
										fontSize: 16,
										marginTop: 2,
										fontWeight: "600",
										color: "#FFF",
										position: "absolute",
									}}>
									Update
								</Text>
							</TouchableOpacity>
						</div>
					</>
				) : (
					<>
						<div dangerouslySetInnerHTML={{ __html: editorHtml }} />
					</>
				)}
			</div>
		</>
	);
};

export default SimpleHtmlEditor;
