import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	Alert,
	LayoutAnimation,
	Animated,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
// import debbie from "./cloud.png";
import bin from "./bin.png";

import { TaskStatus, APP_COLOURS } from "../../../APP_VARS";
// import Fade from "react-reveal/Fade";
import {
	crudCreate,
	crudDelete,
	crudDeleteAllChatsWithHtml,
	crudDeleteImage,
	crudUpdate,
} from "../../../CRUDHELPER";
// import FullCalendar from "../DailyPlanner/FullCalendar";
import CoverageCalendar from "./CoveragCalendar";
// import UsersSelectList from "../UsersSelectList";
import SmallerTabs from "./SmallerTabs";
import ChatBox from "./Chat/ChatBox";
import User from "./User";
import SimpleHtmlEditor from "./Chat/Editor/SimpleHtmlEditor";
import StatusItem from "./StatusItem";
// import StatusItem from "../DailyPlanner/StatusItem";

const collectionName = "tracker_items";

export default class EditTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.d,

			dateAllocation: "start_date",
			loading: false,

			subPage: "Overview",

			editableDescription: false,
			html: "",

			saveRequired: false,
		};
	}

	saveEdit = async () => {
		let obj = {
			...this.state,
		};
		this.setState({
			loading: true,
			saveRequired: false,
		});
		// console.log(this.state);
		// return;

		// 		let user_data = JSON.parse(localStorage.getItem("user_data"));
		// 		sendDiscordMessage(
		// 			`${user_data.first_name} ${user_data.last_name}`,
		// 			`[Task Updated]
		// - ${obj.status}
		// - ${obj.title}`,
		// 			`${window.location.origin}${Avatars[user_data.avatar].pp}`,
		// 			"0QPZYrY6zpAnJXqX9gQl",
		// 		);

		// 		const mapThrough = await this.state.assignment_ids.forEach(
		// 			async (id) => {
		// 				await sendDiscordMessage(
		// 					`${user_data.first_name} ${user_data.last_name}`,
		// 					`### *${this.props.selectedProject.brand.title} - ${
		// 						this.props.selectedProject.title
		// 					}*
		// [Edited Task]
		// - *${obj.status}*
		// > ${obj.title}
		// > ${moment(obj.start_date).format("DD/MM")} to ${moment(obj.end_date).format(
		// 						"DD/MM",
		// 					)}
		// `,
		// 					`${window.location.origin}${Avatars[user_data.avatar].pp}`,
		// 					id,
		// 				);
		// 			},
		// 		);

		// return;
		crudUpdate(collectionName, obj, (status, d) => {
			// console.log(status, d);
			console.log("updateItem");
			if (status === 200) {
				this.props.close();
				this.props.reloadData();
			}
		});
	};

	deleteItem = (d) => {
		let user_data = JSON.parse(localStorage.getItem("user_data"));
		// 		sendDiscordMessage(
		// 			`${user_data.first_name} ${user_data.last_name}`,
		// 			`[Task Deleted]
		// - ${d.title}`,
		// 			`${window.location.origin}${Avatars[user_data.avatar].pp}`,
		// 			"0QPZYrY6zpAnJXqX9gQl",
		// 		);

		crudDeleteAllChatsWithHtml("tracker_chat", this.state.id, () => {
			if (typeof d?.imageData?.url !== "undefined") {
				crudDeleteImage(collectionName, d, () => {
					this.props.close();
					this.props.reloadData();
				});
			} else {
				crudDelete(collectionName, d, () => {
					this.props.close();
					this.props.reloadData();
				});
			}
		});
	};

	componentDidUpdate(prevProps) {
		console.log("change", this.props.d);
		if (prevProps.d !== this.props.d) {
			this.setState({
				...this.props.d,
			});
		}
	}

	componentDidMount() {
		console.log("selectedProject", this.props);
		this.setState({
			...this.props.d,
			subPage: "Overview",
			saveRequired: false,
		});
	}

	render() {
		let color = APP_COLOURS.BG2;
		let d = this.props.d;

		let size = 35;
		let fs = 13;

		let textBox = {
			fontSize: 16,
			fontWeight: "600",
			color: color,
			width: "100%",
			height: "auto",
			// borderBottomWidth: 0.5,
			padding: 10,
			borderRadius: 10,
			backgroundColor: "#FFF",
		};

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						// backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<TouchableOpacity
						style={{
							width: window.innerWidth,
							height: window.innerHeight,
							justifyContent: "center",
							alignItems: "center",
							// backgroundColor: "rgba(52, 52, 52, 0.6)",
							// backgroundColor: APP_COLOURS.BG2,
							backgroundColor: APP_COLOURS.MAIN_BG,
						}}
						onPress={() => this.props.close()}></TouchableOpacity>

					<View
						style={{
							// width: "100%",
							alignItems: "center",
							position: "absolute",
							zIndex: 10,
							// top: 0,
							// maxWidth: 1100,
							width: "90%",
							height: "90%",
						}}>
						<View
							style={{
								flex: 1,
								// backgroundColor: APP_COLOURS.TEXTWHITE,
								backgroundColor: "#F1F5FA",
								// backgroundColor: APP_COLOURS.RED,
								padding: 5,
								// maxWidth: 800,
								width: "100%",
								borderRadius: 15,
								// maxHeight: window.innerHeight - 2,
								// backgroundColor: APP_COLOURS.BG2,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										// marginTop: 30,
										// marginBottom: 10,
										height: "auto",
										// borderBottomWidth: 0.5,
										// paddingBottom: 10,
										// backgroundColor: TaskStatus(
										// 	this.state.status,
										// ),
										borderRadius: 10,
										padding: 7,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<TouchableOpacity
											onPress={() => this.props.close()}>
											<Image
												source={require("./close.png")}
												style={{
													width: 25,
													height: 25,
													resizeMode: "contain",
												}}
											/>
										</TouchableOpacity>
										<View
											style={{
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Text
												numberOfLines={1}
												ellipsizeMode='tail'
												style={{
													color: APP_COLOURS.BLUE,
													fontSize: 16,
													marginLeft: 10,
													textAlign: "center",
												}}>
												{this.props.divisionData.title}
												{" >"}
											</Text>
											<Text
												numberOfLines={1}
												ellipsizeMode='tail'
												style={{
													color: APP_COLOURS.BLUE,
													fontSize: 16,
													textAlign: "center",
													marginLeft: 10,
												}}>
												{this.props.milestoneData.title}
												{" >"}
											</Text>
											{/* <Text
												numberOfLines={1}
												ellipsizeMode='tail'
												style={{
													// color: APP_COLOURS.BLUE,
													fontSize: 16,
													fontWeight: "500",
													marginLeft: 10,
													textAlign: "center",
												}}>
												{this.state.title}
											</Text> */}

											<View
												style={{
													flex: 1,
													// minWidth: 200,
													borderRadius: 13,
													alignItems: "center",
													paddingLeft: 5,
												}}>
												<TextInput
													style={{
														fontWeight: "400",
														fontFamily: "Gotham",

														width: "100%",
														paddingLeft: 15,
														padding: 10,
														fontSize: 20,
														borderRadius: 13,
														// marginRight: 15,
														color: APP_COLOURS.BG4,
														borderWidth: 1,
														borderColor:
															APP_COLOURS.DESELECTEDCOLOR,
														// backgroundColor:
														// 	APP_COLOURS.WHITE_TRANSPARENT4,
														backgroundColor: "#FFF",

														fontWeight: "500",
													}}
													placeholderTextColor={
														"#797979"
													}
													autoCapitalize='sentences'
													clearButtonMode='while-editing'
													autoCompleteType='off'
													editable={true}
													autoCorrect={true}
													autoFocus
													keyboardType='default'
													textContentType='none'
													placeholder={` title `}
													// defaultValue={this.state.user_data.email}
													defaultValue={
														this.state.title
													}
													onChangeText={(text) => {
														console.log(text);
														this.setState({
															title: text,
															saveRequired: true,
															loading: false,
														});
													}}
												/>
											</View>
										</View>
									</View>

									{/* <TouchableOpacity
										onPress={() => {
											this.deleteItem(this.state);
										}}
										style={
											{
												// position: "absolute",
												// right: 10,
												// top: 0,
											}
										}>
										<Image
											source={bin}
											style={{
												width: 30,
												height: 30,
												marginRight: 5,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity> */}
								</View>

								<View
									style={{
										flex: 1,
										flexDirection: "row",
									}}>
									<View
										style={{
											flex: 1,
											padding: 5,
											// maxWidth: 400,
											// width: window.innerWidth * 0.3,
											// alignItems: "center",
											width: "100%",
											// paddingTop: 30,
										}}>
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "space-between",
											}}>
											<SmallerTabs
												selectedSubPage={(d) => {
													this.setState({
														subPage: d,
													});
													console.log(d);
												}}
												tabs={[
													"Overview",
													"Date",
													"Assignment",
													"Edit",
												]}
											/>

											<StatusItem
												{...this.props}
												{...d}
												fs={19}
												handleStatusChange={async (
													_id,
													_status,
												) => {
													let user_data = JSON.parse(
														localStorage.getItem(
															"user_data",
														),
													);

													await this.setState({
														editableDescription: false,
														status: _status,
													});

													let obj = {
														id: this.state.id,
														_id: this.state.id,
														status: _status,
													};

													console.log(
														"StatusItem",
														obj,
													);
													// return;
													await crudUpdate(
														collectionName,
														obj,
														(status, d) => {
															console.log(
																status,
																d,
															);
															console.log(
																"updateItem",
															);
															if (
																status === 200
															) {
																this.props.reloadData();
															}
														},
													);
												}}
											/>
										</View>

										<View
											style={{
												flex: 1,
												// width: 350,

												// marginBottom: 10,
												padding: 5,

												// backgroundColor: "red",
												// height:
												// 	window.innerHeight - 1,
												display:
													this.state.subPage ===
													"Overview"
														? "flex"
														: "none",
											}}>
											{typeof this.state?.imageData
												?.url !== "undefined" ? (
												<TouchableOpacity
													onPress={() => {
														window.open(
															this.state
																?.imageData
																?.url,
															"_blank",
														);
													}}>
													<View
														style={{
															backgroundColor:
																"#FFF",
															borderRadius: 12,
															overflow: "hidden",
															justifyContent:
																"center",
															alignItems:
																"center",
														}}>
														<Image
															source={
																this.state
																	?.imageData
																	?.url
															}
															style={{
																width: "100%",
																height: 250,
																resizeMode:
																	"contain",
															}}
														/>
													</View>
												</TouchableOpacity>
											) : null}

											<View
												style={{
													flex: 1,
													backgroundColor: "#FFF",
													borderRadius: 12,
													// height:
													// 	window.innerHeight -
													// 	1,
													marginTop: 10,
													padding: 10,
												}}>
												<TouchableOpacity
													onPress={() => {
														this.setState({
															editableDescription:
																!this.state
																	.editableDescription,
														});
													}}>
													<View
														style={{
															width: 110,
															alignItems: this
																.state
																.editableDescription
																? "flex-end"
																: "flex-start",
															backgroundColor:
																APP_COLOURS.BACK_TRANSPARENT,
															borderColor:
																APP_COLOURS.BG4,
															borderWidth: 0.5,
															borderRadius: 20,
															padding: 2,
														}}>
														<View
															style={{
																width: 60,
																height: 35,

																alignItems:
																	"center",
																justifyContent:
																	"center",
																borderRadius: 17,
																backgroundColor:
																	!this.state
																		.editableDescription
																		? APP_COLOURS.RED
																		: APP_COLOURS.GREEN,
																paddingHorizontal: 15,
															}}>
															<Text
																style={{
																	fontSize: 13,
																	color: !this
																		.state
																		.editableDescription
																		? "#FFF"
																		: APP_COLOURS.DESELECTEDCOLOR,
																}}>
																{!this.state
																	.editableDescription
																	? "Locked"
																	: "Unlocked"}
															</Text>
														</View>
													</View>
												</TouchableOpacity>
												<SimpleHtmlEditor
													updatehtml={async (
														html,
														pageTitle,
													) => {
														await this.setState({
															editableDescription: false,
															html: html,
															title: pageTitle,
															loading: true,
														});

														let obj = {
															...this.state,
															html: html,
															title: pageTitle,
														};

														// console.log(
														// 	"obj",
														// 	obj,
														// );
														// return;
														await crudUpdate(
															collectionName,
															obj,
															(status, d) => {
																console.log(
																	status,
																	d,
																);
																console.log(
																	"updateItem",
																);
																if (
																	status ===
																	200
																) {
																	this.props.reloadData();
																	this.setState(
																		{
																			loading: false,
																		},
																	);
																}
															},
														);
													}}
													setHtml={{
														html: this.state.html,
														_id: this.state
															.project_id,
														title: this.state.title,
													}}
													lockContent={
														this.state.html === ""
															? false
															: !this.state
																	.editableDescription
													}
												/>
											</View>

											{/* <View
														style={{
															marginBottom: 10,
															// width: 350,
															width: "100%",
															marginVertical: 20,
														}}>
														<Text
															style={{
																color: color,
																fontSize: 11,
															}}>
															Description
														</Text>
														<TextInput
															returnKeyType='done'
															autoCorrect={false}
															style={textBox}
															value={
																this.state
																	.description
															}
															editable={true}
															onChangeText={(
																text,
															) =>
																this.setState({
																	description:
																		text,
																})
															}
															multiline
															numberOfLines={10}
															autoCapitalize='sentences'
															placeholder='Description'
															ref='taskName'
															clearButtonMode='while-editing'
															autoFocus
														/>
													</View> */}

											{typeof this.state.documentUrl !==
											"undefined" ? (
												<TouchableOpacity
													onPress={() => {
														window.open(
															this.state
																?.documentUrl,
															"_blank",
														);
													}}>
													<View
														style={{
															backgroundColor:
																"#FFF",
															padding: 9,
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"center",
															marginBottom: 5,
															borderRadius: 9,
															marginTop: 10,
														}}>
														<Image
															source={require("./pdf.png")}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"contain",
																marginRight: 10,
															}}
														/>
														<View
															style={{
																flex: 1,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontFamily:
																		"Avenir",
																	fontWeight:
																		"400",
																	fontSize: 15,
																}}>
																{d.title}
															</Text>
														</View>
													</View>
												</TouchableOpacity>
											) : null}

											{/* {typeof this.state
														.documentUrl !==
													"undefined" ? (
														<View
															style={{
																width: "100%",
															}}>
															<Text
																style={{
																	color: color,
																	fontSize: 11,
																}}>
																Document URL
															</Text>
															<TextInput
																returnKeyType='done'
																autoCorrect={
																	false
																}
																style={textBox}
																value={
																	this.state
																		.documentUrl
																}
																editable={true}
																onChangeText={(
																	text,
																) =>
																	this.setState(
																		{
																			documentUrl:
																				text,
																		},
																	)
																}
																autoCapitalize='sentences'
																placeholder='Document URL'
																ref='url'
																clearButtonMode='while-editing'
															/>
														</View>
													) : null} */}
										</View>

										{this.state.subPage === "Assignment" ? (
											<View
												style={{
													flex: 1,
													padding: 5,
													width: "100%",
												}}>
												<View
													style={{
														width: "100%",
													}}>
													<View
														style={{
															width: 350,
															// display: this.state.showCalendar
															// 	? "flex"
															// 	: "none",
															// justifyContent: "center",
															// alignItems: "center",
														}}>
														<View
															style={{
																flexDirection:
																	"row",
																flex: 1,
																width: "100%",
															}}>
															<View
																style={{
																	// flex: 1,
																	// width: 350,
																	padding: 10,
																}}>
																<Text
																	style={{
																		color: color,
																		fontSize: 11,
																	}}>
																	Assignment
																</Text>
																{typeof this
																	.props
																	.selecteddivision !==
																"undefined"
																	? this.props.selecteddivision?.assignment?.map(
																			(
																				ass,
																				ind,
																			) => (
																				<User
																					index={
																						ind
																					}
																					d={
																						ass
																					}
																					preview={
																						false
																					}
																				/>
																			),
																	  )
																	: null}
															</View>
														</View>
													</View>
												</View>
												{/* <View
													style={{
														// backgroundColor: 'red',
														width: "100%",
														height: 40,
														flexDirection: "row",
														justifyContent:
															"space-between",
														marginTop: 15,
													}}>
													<TouchableOpacity
														disabled={
															this.state.loading
														}
														onPress={() =>
															this.saveEdit()
														}
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.RED,
															justifyContent:
																"center",
															borderRadius: 20,
														}}>
														{this.state.loading ? (
															<ActivityIndicator
																color={"#FFF"}
															/>
														) : (
															<Text
																style={{
																	width: "100%",
																	textAlign:
																		"center",
																	color: "white",
																	fontSize: 10,
																	fontWeight:
																		"600",
																	padding: 5,
																}}>
																SAVE
															</Text>
														)}
													</TouchableOpacity>
												</View> */}
											</View>
										) : null}

										{this.state.subPage === "Edit" ? (
											<View style={{ flex: 1 }}>
												<Text>{d.createdAt}</Text>

												<Text>brand_id</Text>
												<TextInput
													returnKeyType='done'
													autoCorrect={false}
													style={{
														fontSize: 16,
														fontWeight: "600",
														color: APP_COLOURS.BG2,
														width: "100%",
														height: "auto",
														borderWidth: 0.5,
														borderRadius: 15,
														padding: 10,
														// backgroundColor:
														// 	this.state.selectedColor,
													}}
													editable={true}
													defaultValue={d.brand_id}
													onChangeText={(text) =>
														this.setState({
															brand_id: text,
														})
													}
													placeholder='brand_id'
													clearButtonMode='while-editing'
												/>

												<Text>division_id</Text>
												<TextInput
													returnKeyType='done'
													autoCorrect={false}
													style={{
														fontSize: 16,
														fontWeight: "600",
														color: APP_COLOURS.BG2,
														width: "100%",
														height: "auto",
														borderWidth: 0.5,
														borderRadius: 15,
														padding: 10,
														// backgroundColor:
														// 	this.state.selectedColor,
													}}
													editable={true}
													defaultValue={d.division_id}
													onChangeText={(text) =>
														this.setState({
															division_id: text,
														})
													}
													placeholder='division_id'
													clearButtonMode='while-editing'
												/>

												<Text>milestone_id</Text>
												<TextInput
													returnKeyType='done'
													autoCorrect={false}
													style={{
														fontSize: 16,
														fontWeight: "600",
														color: APP_COLOURS.BG2,
														width: "100%",
														height: "auto",
														borderWidth: 0.5,
														borderRadius: 15,
														padding: 10,
														// backgroundColor:
														// 	this.state.selectedColor,
													}}
													editable={true}
													defaultValue={
														d.milestone_id
													}
													onChangeText={(text) =>
														this.setState({
															milestone_id: text,
														})
													}
													placeholder='Milestone milestone_id'
													clearButtonMode='while-editing'
												/>
												<View style={{ flex: 1 }} />
												<View
													style={{
														padding: 10,
														width: "100%",
														flexDirection: "row",
													}}>
													<TouchableOpacity
														onPress={() => {
															this.deleteItem(
																this.state,
															);
														}}
														style={
															{
																// position: "absolute",
																// right: 10,
																// top: 0,
															}
														}>
														<Image
															source={bin}
															style={{
																width: 30,
																height: 30,
																marginRight: 5,
																resizeMode:
																	"contain",
															}}
														/>
													</TouchableOpacity>
													<Text
														style={{
															margin: 5,
															fontSize: 18,
														}}>
														Delete item & associated
														data
													</Text>
												</View>
											</View>
										) : null}

										{this.state.subPage === "Date" ? (
											<View
												style={{
													flex: 1,
													backgroundColor: "#FFF",
													borderRadius: 12,
													marginTop: 10,
													padding: 10,
												}}>
												<View
													style={{
														padding: 10,
														width: "100%",
														justifyContent:
															"center",
														alignItems: "center",
													}}>
													<View
														style={{
															width: 350,
														}}>
														<View
															style={{
																flexDirection:
																	"row",
																flex: 1,
															}}>
															<View
																style={{
																	marginTop: 20,
																	flex: 1,
																	marginRight: 10,
																	opacity: 0.6,
																}}>
																<Text
																	style={{
																		fontWeight:
																			"400",
																		fontFamily:
																			"Gotham",

																		fontSize: 10,
																		color: APP_COLOURS.BG2,
																		marginBottom: 4,
																		position:
																			"absolute",
																		top: -12,
																		left: 9,
																		backgroundColor:
																			"#FFF",
																		padding: 4,
																		borderRadius: 8,
																		zIndex: 99,
																	}}>
																	Start date
																</Text>
																<View
																	style={{
																		borderRadius: 7,
																		borderColor:
																			APP_COLOURS.BG2,
																		borderWidth: 1,
																	}}>
																	<TouchableOpacity
																		onPress={() => {
																			this.setState(
																				{
																					dateAllocation:
																						"start_date",
																					showCalendar:
																						!this
																							.state
																							.showCalendar,

																					subPage:
																						"Date",
																				},
																			);
																		}}>
																		<View
																			style={{
																				width: "100%",
																				padding: 13,
																				// justifyContent: "center",
																				// alignItems: "center",
																				paddingLeft: 10,
																			}}>
																			<Text
																				style={{
																					color: APP_COLOURS.BG2,
																					fontWeight:
																						"600",
																					fontSize: 16,
																				}}>
																				{moment(
																					this
																						.state
																						.start_date,
																				)
																					.endOf(
																						"day",
																					)
																					.format(
																						"DD/MM/YYYY",
																					)}
																			</Text>
																		</View>
																	</TouchableOpacity>
																</View>
															</View>
															<View
																style={{
																	opacity: 0.6,
																	marginTop: 20,
																	flex: 1,
																}}>
																<Text
																	style={{
																		fontWeight:
																			"400",
																		fontFamily:
																			"Gotham",

																		fontSize: 10,
																		color: APP_COLOURS.BG2,
																		marginBottom: 4,
																		position:
																			"absolute",
																		top: -12,
																		left: 9,
																		backgroundColor:
																			"#FFF",
																		padding: 4,
																		borderRadius: 8,
																		zIndex: 99,
																	}}>
																	Due date
																</Text>
																<View
																	style={{
																		borderRadius: 7,
																		borderColor:
																			APP_COLOURS.BG2,
																		borderWidth: 1,
																	}}>
																	<TouchableOpacity
																		onPress={() => {
																			this.setState(
																				{
																					dateAllocation:
																						"end_date",
																					subPage:
																						"Date",
																				},
																			);
																		}}>
																		<View
																			style={{
																				width: "100%",
																				padding: 13,
																				// justifyContent: "center",
																				// alignItems: "center",
																				paddingLeft: 10,
																			}}>
																			<Text
																				style={{
																					color: APP_COLOURS.BG2,
																					fontWeight:
																						"600",
																					fontSize: 16,
																				}}>
																				{moment(
																					this
																						.state
																						.end_date,
																				).format(
																					"DD/MM/YYYY",
																				)}
																			</Text>
																		</View>
																	</TouchableOpacity>
																</View>
															</View>
														</View>
														<CoverageCalendar
															compWidth={350}
															calendarList={[]}
															selectedDate={
																this.state[
																	this.state
																		.start_date
																]
															}
															setStartDate={moment(
																this.state
																	.start_date,
															).format(
																"DD/MM/YYYY",
															)}
															setEndtDate={moment(
																this.state
																	.end_date,
															).format(
																"DD/MM/YYYY",
															)}
															startDate={(
																date,
															) => {
																this.setState({
																	start_date:
																		date,
																	saveRequired: true,
																});
															}}
															endDate={(date) => {
																this.setState({
																	end_date:
																		date,
																	saveRequired: true,
																});
															}}
														/>
													</View>
												</View>
											</View>
										) : null}

										{this.state.saveRequired ? (
											<View
												style={{
													// backgroundColor: 'red',
													width: "100%",
													height: 40,
													flexDirection: "row",
													justifyContent:
														"space-between",
													marginTop: 15,
												}}>
												<TouchableOpacity
													disabled={
														this.state.loading
													}
													onPress={() =>
														this.saveEdit()
													}
													style={{
														flex: 1,
														backgroundColor:
															APP_COLOURS.BG4,
														justifyContent:
															"center",
														borderRadius: 20,
													}}>
													{this.state.loading ? (
														<ActivityIndicator
															color={"#FFF"}
														/>
													) : (
														<Text
															style={{
																width: "100%",
																textAlign:
																	"center",
																color: "white",
																fontSize: 16,
																fontWeight:
																	"600",
																padding: 5,
															}}>
															SAVE
														</Text>
													)}
												</TouchableOpacity>
											</View>
										) : null}
									</View>

									<View
										style={{
											flex: 1,
											// marginLeft: 5,
											// backgroundColor: "#FFF",
											// backgroundColor: APP_COLOURS.RED,
											padding: 5,
											borderRadius: 13,
											marginLeft: 10,
											height: "100%",
										}}>
										<ChatBox
											chatTitle={this.state.title}
											project_id={this.state.id}
											selectedProject={this.state.id}
											assignment_ids={
												this.state.assignment_ids
											}
											user_data={JSON.parse(
												localStorage.getItem(
													"user_data",
												),
											)}
										/>
									</View>
								</View>

								<View
									style={
										{
											// flex: 1,
										}
									}>
									{/* <View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(
									d.id,
									"right",
									"minus",
								)
							}>
							<View
								style={{
									width: 60,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Image
									style={{
										width: 40,
										height: 40,
										resizeMode: "contain",
									}}
									source={require("./min_btn2.png")}
								/>
							</View>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "right", "plus")
							}>
							<View
								style={{
									width: 60,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Image
									style={{
										width: 40,
										height: 40,
										resizeMode: "contain",
									}}
									source={require("./add_btn2.png")}
								/>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "left", "move")
							}>
							<View
								style={{
									marginRight: 4,
								}}>
								<Image
									style={{
										width: 60,
										height: 60,
										resizeMode: "contain",
									}}
									source={require("./left_btn.png")}
								/>
							</View>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() =>
								this.props.updateMoveTask(d.id, "right", "move")
							}>
							<View
								style={{
									marginLeft: 2,
								}}>
								<Image
									style={{
										width: 60,
										height: 60,
										resizeMode: "contain",
									}}
									source={require("./right_btn.png")}
								/>
							</View>
						</TouchableOpacity>
					</View> */}
								</View>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}
