import React, { Component } from "react";
import { View } from "react-native";

import JarvisChat from "../JarvisChat";

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			secondsCountdown: 10,
			page: "main",
			// page: "newQuery",
			ticketRef: "",
			emailValid: false,
			checkingRVP: false,
			notInvited: false,
			rsvpHasBeenSubmitted: false,

			screenWidth: null,
			screenHeight: null,
			tabs: "New Query",
			queryFilter: "",
			queryTypeSelected: "",
			showQueries: true,
			queries: [],
			userid: "",
			full_name: "",
			queryTypes: [],
			queryCategories: [],

			currentQuery: "",
		};
	}

	async componentDidMount() {
		await this.setState({
			full_name: `${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
			userid: this.props.user_data.username,
		});

		await console.log(this.props.user_data);
	}

	render() {
		return (
			<div
				style={{
					height: this.props.screenHeight,
					width: this.props.screenWidth,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					overflow: "hidden",
					borderRadius: 18,
					// backgroundColor: "#D1DEE7",
					// backgroundColor: APP_COLOURS.BACKGROUND,
				}}>
				{/* <Image
					source={wfh}
					style={{
						resizeMode: "contain",
						width: 150,
						height: 220,
						resizeMode: "contain",
						position: "absolute",
						bottom: 300,
						right: 20,
						opacity: 0.8,
					}}
				/> */}

				<View
					style={{
						alignItems:
							this.props.screenWidth > 600
								? "center"
								: "flex-start",
						// padding: 1,
						width: "100%",
						marginLeft: "auto",
						marginRight: "auto",
						height: this.props.screenHeight,
					}}>
					<View
						style={{
							// flex: 1,
							width: "100%",
							flexDirection: "row",
							height: this.props.screenHeight - 65,
							// padding: 10,
						}}>
						<View
							style={{
								flex: 1,
								// paddingLeft: 35,
								zIndex: 997,
								width: "100%",
							}}>
							<JarvisChat {...this.props} />
						</View>
					</View>
				</View>
			</div>
		);
	}
}

export default index;
