import React, { Component } from "react";
import { View, Image, ScrollView, Linking, Text } from "react-native";

import LandingPage from "./LandingPage";
import IntroPage from "./IntroPage";

import logo_tetrice from "../assets/tetrice-revlon-main.png";
// import Page2 from "./Page2.js";
// import Font from "react-font";
// import { APP_COLOURS } from "./APP_VARS";
// import Page1 from "./Page1";
// import Page3 from "./Page3";
import ProposalPage from "./PresentationPage";
import GoogleSlidesEmbed from "./GoogleSlide";
// import Page4 from "./Page4";
// import Page5 from "./Page5";
// import Page6 from "./Page6";
// import Page7 from "./Page7";
// import Page8 from "./Page8";
// import Page9 from "./Page9";
// import Page10 from "./Page10";
// import Page11 from "./Page11";
// import Page12 from "./Page12";
// import Page13 from "./Page13";
// import Page14 from "./Page14";
// import Page15 from "./Page15";
// import PageFooter from "./PageFooter";

import * as findTarget from "../assets/64198-find-target.json";
import * as creation from "../assets/134987-expo-logic-eventreg-marketing-lp-hero.json";
import * as ecosystem from "../assets/86836-data-dashboard.json";
import * as infographics from "../assets/91239-customer.json";
import * as customer from "../assets/28608-make-payment.json";
import * as brandidentity from "../assets/122694-brand-identity.json";
import * as animationData2 from "../assets/23626-infographics-circle.json";
import * as content from "../assets/141255-influencer-green.json";
import * as social from "../assets/72675-social-media.json";
import { crudGetPresentationData } from "../../CRUDHELPER";
import { TouchableOpacity } from "react-native-web";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class ProposalReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
			loading: true,

			pageNumber: 0,
		};
	}

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = localStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			localStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 2000);
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getPresentationData = async (sheetName) => {
		// return [
		// 	{
		// 		subHead: "Summary page",
		// 		subParagraphs: [
		// 			`Extending beyond the realms of traditional PR and social media management, our strategy also encompasses comprehensive digital community and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
		// 		],
		// 	},
		// 	{
		// 		subHead: "Summary page",
		// 		subParagraphs: [
		// 			`Extending beyond the realms of traditional PR and social media management, our strategy also encompasses comprehensive digital community and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
		// 		],
		// 	},
		// ];
		let dd = [];
		await crudGetPresentationData(sheetName, (s, d) => {
			if (s === 200) {
				return (dd = d);
			}
		});
		await console.log(dd);
		return dd;
	};

	render() {
		const { screenWidth } = this.state;
		const isSmallScreen = screenWidth < 1000;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let fs = window.innerWidth * 0.07;
		// let maxFont = 40;
		// let h1 = fs < maxFont ? fs : maxFont;
		// let h2 = h1 / 1.618;
		// let pp = h2 / 1.618;

		let pp = isSmallScreen ? 16 : 20;
		let h2 = pp * 1.2618;
		let h1 = h2 * 1.2618;
		let h0 = h1 * 1.2618;
		let maxFont = 40;

		let mw = 1000;

		if (this.state.loading) {
			return (
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: "#171A20",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: 0,
						zIndex: 999,
						width: "100%",
					}}>
					<Image
						source={logo_tetrice}
						style={{
							width: 350,
							height: 100,
							resizeMode: "contain",
						}}
					/>
				</View>
			);
		} else
			return (
				<View
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						overflow: "hidden",
						backgroundColor: "#171A20",
					}}>
					<ScrollView
						ref={(ref) => {
							this.scrollRef = ref;
						}}
						overScrollMode='never'
						showsVerticalScrollIndicator={false}
						// pagingEnabled={true}
						// decelerationRate='fast'
						// scrollEventThrottle={160}
						// snapToInterval={this.state.screenHeight}
						contentContainerStyle={{
							alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								width: "100%",
								maxWidth: mw,
								padding: "5%",
								// paddingRight: "15%",
								minHeight: screenHeight,
							}}>
							{/* <LandingPage
								{...this.state}
								h0={h0}
								h1={h1}
								h2={h2}
								pp={pp}
							/> */}

							{/* <IntroPage
	{...this.state}
	h1={h1}
	h2={h2}
	pp={pp}
/> */}

							{/* {[
								{
									title: "Overview",
									title2: "",
									animationData: animationData2,
									items: [
										{
											subHead: "Digital overview",
											subParagraphs: [
												`Extending beyond the realms of traditional PR and social media management, our strategy aims at encompassing a  comprehensive digital strategy and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
												"Each months report aims to enhance the user experience on Revlon & Elizabeth Arden's platforms. Driving current and prospective customer journeys to complete a successful sale. This report seeks to highlight improvements made, navigation channels, optimizing content for search pipelines, integrating social media and  monitoring analytic trackers to report key insights about customer behavior.",
												`When it comes to e-commerce management, we will take a holistic approach to manage your product listings. From optimizing product descriptions and images to implementing effective SEO strategies, we'll make sure that your products are easy to find and appealing to potential customers.`,
												`Moreover, we'll also handle all aspects of digital community management, from engaging with customers on social media and responding to their comments andqueries, to managing online reviews and feedback. We understand the power of a strong, active online community and will work tirelessly to nurture these relationships.`,
												`Additionally, we'll leverage data-driven strategies to constantly assess, tweak and improve our approach. With real-time analytics, we'll be able to track the performance of our campaigns, identify trends, and make informed decisions to ensure the success of our strategy.`,
												`By offering a full suite of services that go beyond traditional PR, we aim to provide an all-encompassing solution to meet Revlon & Elizabeth Arden's digital community and e-commerce management needs. Through this integrated approach, we are confident in our ability to elevate brand awareness, foster trust, and drive engagement across all digital platforms.`,
											],
										},
									],
								},
								{
									title: "Focus for the month",
									title2: "",
									animationData: findTarget,
									items: [
										{
											subHead: "Executive Summary",
											subParagraphs: [
												`Extending beyond the realms of traditional PR and social media management, our strategy also encompasses comprehensive digital community and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
											],
										},
									],
								},
								{
									title: "Brand Strategy Alignment",
									title2: "",
									animationData: brandidentity,
									items: [
										{
											subHead:
												"Alignment with Brands overall direction",
											subParagraphs: [
												`Extending beyond the realms of traditional PR and social media management, our strategy also encompasses comprehensive digital community and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
											],
										},
									],
								},
								{
									title: "Community management",
									title2: "",
									animationData: social,
									items: [
										{
											subHead:
												"Alignment with Brands overall direction",
											subParagraphs: [
												`Extending beyond the realms of traditional PR and social media management, our strategy also encompasses comprehensive digital community and e-commerce management. We will ensure that every online touchpoint, be it your website, social media platforms, or e-commerce presence, are all working in unison to deliver a seamless, engaging, and compelling brand experience.`,
											],
										},
									],
								},

								{
									title: "Content creation",
									title2: "",
									animationData: creation,
									items: [
										{
											subHead: "",
											subParagraphs: [``],
										},
									],
								},
								{
									title: "Persona management",
									title2: "",
									animationData: content,
									items: [
										{
											subHead: "",
											subParagraphs: [``],
										},
									],
								},
								{
									title: "Digital ecosystem",
									title2: "",
									animationData: ecosystem,
									items: [
										{
											subHead: "Social media",
											subParagraphs: [
												`Platforms under management`,
											],
										},
										{
											subHead: "E-Tailers",
											subParagraphs: [``],
										},
										{
											subHead: "Websites",
											subParagraphs: [``],
										},
										{
											subHead: "Search Optomisation",
											subParagraphs: [``],
										},
										{
											subHead: "Paid Media",
											subParagraphs: [``],
										},
										{
											subHead: "Apps",
											subParagraphs: [
												`Skin diagnostics`,
												`Skin transformation`,
												`skintools.co.za`,
											],
										},
									],
								},
								{
									title: "Performance",
									title2: "Month on Month",
									animationData: findTarget,
									items: [
										{
											subHead: "Bounce rates",
											subParagraphs: [`Bounce rates`],
										},
										{
											subHead: "Social growth",
											subParagraphs: [`growth rates`],
										},
										{
											subHead: "Website traffic",
											subParagraphs: [`growth rates`],
										},
									],
								},
								{
									title: "Competition",
									title2: "Competitor analysis",
									animationData: findTarget,
									items: [
										{
											subHead:
												"What our competition did for the month",
											subParagraphs: [``],
										},
									],
								},
								{
									title: "E-Tailers",
									title2: "Changes and updates",
									animationData: customer,
									items: [
										{
											subHead: "",
											subParagraphs: [``],
										},
									],
								},
								{
									title: "Improvements",
									title2: "Goals for the coming period",
									animationData: infographics,
									items: [
										{
											subHead:
												"elizabetharden.co.za - Banners",
											subParagraphs: [`Banner updates`],
										},
										{
											subHead: "Events",
											subParagraphs: [`Banner updates`],
										},
									],
								},
								{
									title: "Summary",
									title2: "Goals for the coming period",
									animationData: infographics,
									items: [
										{
											subHead:
												"elizabetharden.co.za - Banners",
											subParagraphs: [`Banner updates`],
										},
										{
											subHead: "Events",
											subParagraphs: [`Banner updates`],
										},
									],
								},
							].map((d) => (
								<ProposalPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
									{...d}
								/>
							))} */}

							{this.state.pageNumber === 0 && (
								<LandingPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
								/>
							)}

							{this.state.pageNumber === 1 && (
								<ProposalPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
									{...{
										title: "Focus for the month",
										title2: "",
										animationData: findTarget,
										items: [],
										sheetName: "OverviewPage",
									}}
								/>
							)}
							{this.state.pageNumber === 2 && (
								<ProposalPage
									{...this.state}
									h0={h0}
									h1={h1}
									h2={h2}
									pp={pp}
									{...{
										title: "Page 2 month",
										title2: "",
										animationData: findTarget,
										items: [],
										sheetName: "Page2",
									}}
								/>
							)}

							<View style={{ flex: 1 }} />
							<TouchableOpacity
								onPress={() => {
									this.setState({
										pageNumber: this.state.pageNumber + 1,
									});
								}}>
								<View
									style={{
										opacity: 0.8,
										borderRadius: 10,
										backgroundColor: APP_COLOURS.BLUE,
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										padding: 12,
									}}>
									<Text style={{ color: "#FFF" }}>Begin</Text>
								</View>
							</TouchableOpacity>
						</View>

						{/* <GoogleSlidesEmbed />

						<TeamTetrice />

						<PageFooter /> */}
					</ScrollView>

					{/* {this.state.enquireNowForm ? (
         <EnquireNow
            {...this.props}
            {...this.state}
            nextPage={() =>
               this.setState({
                  resultsPage: true,
               })
            }
            close={() =>
               this.setState({
                  enquireNowForm: false,
               })
            }
         />
      ) : null} */}
				</View>
			);
	}
}
