import moment from "moment";
import {
	api_getslas,
	api_crudCreate,
	api_crudDeleteWithImage,
	api_crudWithImage,
	api_crudReadUnass,
	api_crudUpdate,
	api_crudDeleteDocument,
	api_crudRead,
	api_crudWithHtml,
	api_crudWChatithHtml,
	api_crudDeleteAllChatsWithHtml,
} from "./Api";

export const chatGetMessages = async (obj, next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/2ba62ff8-dc4a-4dfd-aa1d-0df84ef766b8";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(obj),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const chatSendMessage = async (obj, next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/5845af3a-b3d2-4f5d-ad8e-5650b7b7808c";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify(obj),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetUsers = async (next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/d33ae4b8-9355-499e-9f3d-7de51868da3d";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},

		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						console.log("result ", result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetSlas = async (next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/0c9723f4-2ea1-483c-b2fd-22f98ba067b7";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},

		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const updateAssignements = async (obj, next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/a1922e8d-4a79-4bec-8832-7cd87b27ed3b";
	let user_data = await getUserData();
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			...obj,
			_id: obj.id,
		}),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else if (response.status === 401) {
				logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetBrandTrackers = async (next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook/0c9723f4-2ea1-483c-b2fd-22f98ba067b7";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},

		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetPresentationData = async (sheetName, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/49d770aa-2ee7-43a5-9a9c-92209f41c2e5";
	let accessToken = await getAccessToken();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify({
			sheetName: sheetName,
		}),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithHtml = async (collection, where, next) => {
	let url = api_crudWithHtml;

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: collection,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			// if (response.status === 200) {
			response
				.json()
				.then((result) => {
					// console.log('result ', result);
					return next(200, result);
				})
				.catch((error) => {
					console.log(error);
					return next(response.status);
				});
			// } else {
			// 	// logOut();
			// 	// console.log()
			// 	return next(response.status);
			// }
		})
		.catch((error) => console.log("error", error));
};

export const crudChatWithHtml = async (collection, where, next) => {
	let url = api_crudWChatithHtml;

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	// 	collection: collection,
	// 	data: where,
	// });
	// return;

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			// if (response.status === 200) {
			response
				.json()
				.then((result) => {
					// console.log('result ', result);
					return next(200, result);
				})
				.catch((error) => {
					console.log(error);
					return next(response.status);
				});
			// } else {
			// 	// logOut();
			// 	// console.log()
			// 	return next(response.status);
			// }
		})
		.catch((error) => console.log("error", error));
};

export const crudTrackerItems = async (collection, where, next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook-test/itemsc7f1c-ed27-4b53-9d54-ced2836a0dac";
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudTrackerMileStones = async (collection, where, next) => {
	// let url = api_getslas;
	let url =
		"https://workflow.tetrice.co.za/webhook-test/1bc72db8-708e-475b-9642-1023663fcb8f";
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};
//
//
export const crudCreateTrackerItem = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};
//
//
//
//

export const crudCreate = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudRead = async (collection, where, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	console.log(user_data.accessToken);
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadUnass = async (collection, where, next) => {
	let url = api_crudReadUnass;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	console.log(user_data.accessToken);
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdate = async (collection, obj, next) => {
	let url = api_crudUpdate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithBase64 = async (collection, where, next) => {
	let url = api_crudWithImage;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: collection,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteImage = async (collection, where, next) => {
	let url = api_crudDeleteWithImage;

	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	console.log({
		collection: collection,
		data: where,
	});
	// return;
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDelete = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteAllChatsWithHtml = async (
	collection,
	project_id,
	next,
) => {
	let url = api_crudDeleteAllChatsWithHtml;

	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			project_id: project_id,
		}),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//
//
//
//
//
////
//
//
//
//
////
//
//
//
//
////
//
//
//
//
//

async function getUserData() {
	try {
		const value = await localStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}

async function getAccessToken() {
	try {
		const value = await localStorage.getItem("accessToken");
		// let parse = JSON.parse();
		if (value !== null) {
			return value;
		} else {
			return "";
		}
	} catch (error) {
		return "";
	}
}

async function logOut() {
	// localStorage.clear();
	// window.location.href = "/";
}
