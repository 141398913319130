import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { APP_COLOURS, Avatars, StatusColour } from "../../../APP_VARS";
import moment from "moment";

export default class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
		};
	}

	render() {
		let d = this.props.d;
		if (this.props.preview) {
			return (
				<View
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
					style={{
						// width: 14,
						padding: 1,
						borderRadius: 5,
						marginRight: 5,
						// flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: 1,
						// width: 34,
						// height: 50,
					}}>
					<Image
						source={
							Avatars[
								typeof d.avatar !== "undefined"
									? parseInt(d.avatar)
									: 0
							].pp
						}
						style={{
							width: 34,
							height: 34,
							borderRadius: 25,
							resizeMode: "contain",
							backgroundColor: "#000",
							borderColor: this.state.hover
								? "#C5C5C5"
								: StatusColour(d.status),
							// borderColor: "#C5C5C5",
							borderWidth: 2,
							shadowColor: StatusColour(d.status),
							shadowOffset: {
								width: 0,
								height: 8,
							},
							shadowOpacity: 0.44,
							shadowRadius: 10.32,
							elevation: 16,
							opacity: this.state.hover ? 1 : 0.9,
						}}
					/>
					<Text
						// ellipsizeMode='tail'
						// numberOfLines={1}
						style={{
							display: this.state.hover ? "flex" : "none",
							fontSize: 7,
							color: APP_COLOURS.DESELECTEDCOLOR,
							fontWeight: "300",
							top: -10,
							position: "absolute",
							// zIndex: 99,
							left: 0,
							width: 60,
						}}>
						{d.first_name}
					</Text>
				</View>
			);
		} else
			return (
				<View
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
					style={{
						width: "100%",
						// width: "auto",
						padding: 2,
						borderRadius: 10,
						marginRight: 5,
						flexDirection: "row",
						alignItems: "center",
						marginBottom: 1,
						backgroundColor: this.state.hover
							? "#FFF"
							: "transparent",
					}}>
					{typeof d.avatar !== "undefined" && d.avatar !== null ? (
						<Image
							source={
								Avatars[
									typeof d.avatar !== "undefined"
										? parseInt(d.avatar)
										: 0
								].pp
							}
							style={{
								width: 34,
								height: 34,
								borderRadius: 25,
								resizeMode: "contain",
								backgroundColor: "#000",
								borderColor: this.state.hover
									? "#C5C5C5"
									: StatusColour(d.status),
								// borderColor: "#C5C5C5",
								borderWidth: 2,
								shadowColor: StatusColour(d.status),
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,
								elevation: 16,
								opacity: this.state.hover ? 1 : 0.9,
							}}
						/>
					) : (
						<Image
							source={Avatars[1].pp}
							style={{
								width: 30,
								height: 30,
								borderRadius: 25,
								resizeMode: "contain",
								backgroundColor: "#FFF",
								marginRight: 5,
							}}
						/>
					)}

					<View
						style={{
							// flex: 1,
							paddingLeft: 5,
							width: 150,
							// backgroundColor: "red",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								width: "100%",
							}}>
							<Text
								numberOfLines={1}
								ellipsizeMode='tail'
								style={{
									fontSize: 13,
									color: APP_COLOURS.BG3,
									fontWeight: "600",
									marginRight: 3,
								}}>
								{d.first_name}
								{"  "}
							</Text>
							<Text
								numberOfLines={1}
								ellipsizeMode='tail'
								style={{
									fontSize: 13,
									color: APP_COLOURS.BG3,
									fontWeight: "600",
								}}>
								{d.last_name}
							</Text>
						</View>

						<Text
							numberOfLines={1}
							ellipsizeMode='tail'
							style={{
								fontSize: 8,
								fontWeight: "400",
								display: this.state.hover ? "flex" : "none",
							}}>
							{typeof d.organisation !== "undefined"
								? d.organisation
								: ""}
						</Text>
					</View>
					{/* <View
						style={{
							padding: 3,
							backgroundColor: "red",
							borderRadius: 4,
							width: 16,
							height: 16,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text
							style={{
								color: "#FFF",
							}}>
							-
						</Text>
					</View> */}
				</View>
			);
	}
}
