import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
// import Fade from "react-reveal/Fade";
import Font from "react-font";

class CircularFlow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
		};

		this.steps = [
			{
				id: 1,
				step: "1. Assessing Brand Perception",
				points: [
					"Analyze current customer perception of the brand through social listening, reviews, and market research.",
					"Identify strengths, weaknesses, and areas for improvement.",
				],
			},
			{
				id: 2,
				step: "2. Defining Brand Goals",
				points: [
					"Collaborate with the client to establish clear brand goals and objectives.",
					"Determine desired brand positioning and target audience.",
				],
			},
			{
				id: 3,
				step: "3. Crafting the Journey",
				points: [
					"Develop a comprehensive PR and community management strategy that bridges the gap between the current brand perception and desired goals.",
					"Outline specific tactics, campaigns, and initiatives that will support the journey.",
				],
			},
			{
				id: 4,
				step: "4. Implementation",
				points: [
					"Execute the PR and community management strategy through targeted campaigns, engaging content, and consistent messaging.",
					"Utilize social media, influencer partnerships, and events to increase brand visibility and attract customers.",
				],
			},
			{
				id: 5,
				step: "5. Expanding Sales Channels",
				points: [
					"Optimize direct-to-consumer sales through a user-friendly website and effective marketing campaigns.",
					"Manage in-store product placements and promotions to enhance brand presence in the retail environment.",
				],
			},
			{
				id: 6,
				step: "6. Tracking & Analytics",
				points: [
					"Monitor key performance indicators (KPIs) and analytics across all channels to assess the impact of the strategy.",
					"Identify trends, successes, and areas for improvement, adjusting the strategy as needed.",
				],
			},
			{
				id: 7,
				step: "7. Measuring the Shift",
				points: [
					"Compare pre-implementation sales, brand positioning, and customer sentiment with post-implementation results.",
					"Showcase the positive impact of the PR and community management strategy on brand perception and performance.",
				],
			},
		];

		this.scrollViewRef = React.createRef();
	}

	componentDidMount() {
		// this.interval = setInterval(() => {
		// 	this.setState((prevState) => {
		// 		const nextStep = (prevState.activeStep + 1) % this.steps.length;
		// 		if (this.scrollViewRef.current) {
		// 			this.scrollViewRef.current.scrollTo({
		// 				x: nextStep * 400, // Adjust the multiplier according to the item width
		// 				y: 0,
		// 				animated: true,
		// 			});
		// 		}
		// 		return { activeStep: nextStep };
		// 	});
		// }, 6000); // Change the interval duration as needed

		this.props.stepTitle(this.steps[0].step);
	}

	startInterval() {
		this.interval = setInterval(() => {
			if (!this.state.isPaused) {
				this.nextStep();
			}
		}, 6000); // Change the interval duration as needed
	}

	nextStep() {
		this.setState((prevState) => {
			const nextStep = (prevState.activeStep + 1) % this.steps.length;
			this.scrollToStep(nextStep);
			this.props.stepTitle(this.steps[nextStep].step);

			return { activeStep: nextStep };
		});
	}

	prevStep() {
		this.setState((prevState) => {
			const prevStep =
				(prevState.activeStep - 1 + this.steps.length) %
				this.steps.length;
			this.scrollToStep(prevStep);
			this.props.stepTitle(this.steps[prevStep].step);
			return { activeStep: prevStep };
		});
	}

	scrollToStep(step) {
		if (this.scrollViewRef.current) {
			this.scrollViewRef.current.scrollTo({
				x: step * 400, // Adjust the multiplier according to the item width
				y: 0,
				animated: true,
			});
		}
	}

	togglePause() {
		this.setState((prevState) => ({ isPaused: !prevState.isPaused }));
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		return (
			<View style={styles.container}>
				<ScrollView
					horizontal
					ref={this.scrollViewRef}
					showsHorizontalScrollIndicator={false}>
					{this.steps.map((step, index) => (
						<View
							style={{
								width: 350,
								marginRight: 50,
								opacity:
									this.state.activeStep === index ? 1 : 0.2,
								backgroundColor: "#FFF",
								borderRadius: 15,
								padding: 20,
							}}>
							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
									flexDirection: "row",
									// opacity: 0.5,
								}}>
								<Font family='Montserrat' weight={500}>
									{step.step}
								</Font>
							</Text>

							{step.points.map((dd) => (
								<Text
									style={{
										fontSize: 20,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 10,
										paddingLeft: 20,
										// textAlign: "justify",
										fontFamily: "Montserrat",
										fontWeight: "300",
									}}>
									- {dd}
								</Text>
							))}
						</View>
					))}
					<View
						style={{
							width: 800,
						}}
					/>
				</ScrollView>

				<View style={styles.buttonsContainer}>
					<TouchableOpacity
						onPress={() => this.prevStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Prev</Text>
					</TouchableOpacity>
					{/* <TouchableOpacity
						onPress={() => this.togglePause()}
						style={styles.button}>
						<Text style={styles.buttonText}>
							{this.state.isPaused ? "Resume" : "Pause"}
						</Text>
					</TouchableOpacity> */}
					<TouchableOpacity
						onPress={() => this.nextStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Next</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		// justifyContent: "center",
		// alignItems: "center",
	},
	stepText: {
		fontSize: 18,
		textAlign: "center",
		margin: 10,
	},

	buttonsContainer: {
		flexDirection: "row",
		// justifyContent: "space-around",
		alignItems: "center",
		marginTop: 20,
	},
	button: {
		backgroundColor: APP_COLOURS.TEXTCOLOR,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 5,
		marginRight: 20,
		// minWidth: 100,
		// alignItems: "center",
		// justifyContent: "center",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	stepText: {
		fontSize: 18,
		textAlign: "center",
		margin: 10,
	},
});

export default CircularFlow;
