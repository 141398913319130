import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
	Dimensions,
} from "react-native";
import moment from "moment";
import { APP_COLOURS, StatusColour, TaskStatus } from "../../../APP_VARS";
import {
	crudCreate,
	crudRead,
	crudReadUnass,
	crudTrackerItems,
	crudTrackerMileStones,
} from "../../../CRUDHELPER";
import { socialCalendarDays, southAfricanHolidays } from "./SocialHolidays";
import DivisionDetails from "./DivisionDetails";
import User from "./User";

import * as animationData2 from "./Animation-Ai.json";
import TechSupportComponent from "../../TechSupport";
import AutoRefresh from "../AutoRefresh";
import Lottie from "react-lottie";
import close from "./close.png";
import AddTask from "./AddTask";
import EditTask from "./EditTask";
import StatusItem from "./StatusItem";
// import EditMilestone from "./EditMilestone";

// import starFill from "./Table/starFill.png";
// import star from "./Table/star.png";

async function getUserData() {
	try {
		const value = await localStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			// localStorage.clear();
			// window.location.href = "/";
			return;
		}
	} catch (error) {
		// localStorage.clear();
		// window.location.href = "/";
		return;
	}
}

async function getBrand() {
	try {
		const value = await localStorage.getItem("brand_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			// localStorage.clear();
			// window.location.href = "/";
			return [];
		}
	} catch (error) {
		// localStorage.clear();
		// window.location.href = "/";
		return [];
	}
}

async function getDivisions() {
	try {
		const value = await localStorage.getItem("divisions");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			// localStorage.clear();
			// window.location.href = "/";
			return [];
		}
	} catch (error) {
		// localStorage.clear();
		// window.location.href = "/";
		return [];
	}
}

async function getMilestones() {
	try {
		const value = await localStorage.getItem("milestones");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			// localStorage.clear();
			// window.location.href = "/";
			return [];
		}
	} catch (error) {
		// localStorage.clear();
		// window.location.href = "/";
		return [];
	}
}

async function getEvents() {
	try {
		const value = await localStorage.getItem("events");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			// localStorage.clear();
			// window.location.href = "/";
			return [];
		}
	} catch (error) {
		// localStorage.clear();
		// window.location.href = "/";
		return [];
	}
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function firstAndLast(array) {
	var firstItem = array[0];
	var lastItem = array[array.length - 1];

	var objOutput = {
		start: firstItem,
		end: lastItem,
	};

	return objOutput;
}

export default class GanttComponent extends Component {
	constructor(props) {
		// if (!firebaseApp.apps.length) {
		//   firebaseApp.initializeApp(config);
		// }
		super(props);
		this.state = {
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,

			saved: false,
			itemWidth: 100,
			startLeft: 100,
			enableScroll: true,
			totalWidth: 100,
			calendar: [],
			calendarDays: [],
			startweekNumber: moment().startOf("week").week(),
			endweekNumber: moment().add(5, "week").startOf("week").week(),
			hover: "",
			currentSubItemId: "",

			fontSize: 14,

			colours: ["#0468FC", "#E0A749", "#FF5F5F", "#45B88B", "#AF78FF"],

			expandRow: [],
			expandMilestoneRow: [],

			todayPreviewList: [],

			new_start_date: new Date(),
			new_end_date: new Date(),
			taskName: "",

			selectedSubTask: "",
			currentStatus: "",

			addDivisionModal: false,
			addMilestoneModal: false,
			addCalendarItem: false,
			filterOnlyToday: false,
			filterOnlyThisWeek: false,

			showDivision: "",

			selecteddivision: "",
			selectedMilestoneComp: "",
			showEditModal: false,

			user_data: "",
			brand_id: this.props.brand_id,

			divisions: [
				// {
				// 	id: "npd",
				// 	title: "NPD's",
				// },
				// {
				// 	id: "promos",
				// 	title: "Promos",
				// },
				// {
				// 	id: "paidmedia",
				// 	title: "Paid Media",
				// },
				// {
				// 	id: "website",
				// 	title: "Website",
				// },
				// {
				// 	id: "socialmedia",
				// 	title: "Social Media",
				// },
				// {
				// 	id: "reporting",
				// 	title: "Reports",
				// },
				// {
				// 	id: "events",
				// 	title: "Events",
				// },
				// {
				// 	id: "pr",
				// 	title: "PR",
				// },
				// {
				// 	id: "brandpages",
				// 	title: "Brand Pages",
				// },
			],

			seasons: [
				{
					id: "12sdefvical",
					title: "AUTUMN SEASON",
					start_date: moment("01/03/2024", "DD/MM/YYYY"),
					end_date: moment("01/06/2024", "DD/MM/YYYY"),
					status: "",
				},
				{
					id: "1234444tactical",
					title: "WINTER SEASON",
					start_date: moment("01/06/2024", "DD/MM/YYYY"),
					end_date: moment("01/09/2024", "DD/MM/YYYY"),
					status: "",
				},
				{
					id: "1234444tjnjactical",
					title: "SPRING SEASON",
					start_date: moment("01/09/2024", "DD/MM/YYYY"),
					end_date: moment("01/11/2024", "DD/MM/YYYY"),
					status: "",
				},

				{
					id: "123444dfvdf4tjnjactical",
					title: "Fathers Day",
					start_date: moment("16/06/2024", "DD/MM/YYYY"),
					end_date: moment("17/06/2024", "DD/MM/YYYY"),
					status: "",
				},
				{
					id: "sdfvsdfvsf",
					title: "Youth Day",
					start_date: moment("16/06/2024", "DD/MM/YYYY"),
					end_date: moment("17/06/2024", "DD/MM/YYYY"),
					status: "",
				},
				...southAfricanHolidays,
				...socialCalendarDays,
			],

			milestones: [
				// {
				// 	id: "winter",
				// 	division_id: "npd",
				// 	title: "Winter season - Launch July",
				// 	description: "",
				// },
				// {
				// 	id: "face123123",
				// 	division_id: "npd",
				// 	title: "FACE",
				// 	description: "",
				// },
				// {
				// 	id: "lip231sfv23",
				// 	division_id: "npd",
				// 	title: "LIP",
				// 	description: "",
				// },
				// {
				// 	id: "eye231sfv23",
				// 	division_id: "npd",
				// 	title: "EYE",
				// 	description: "",
				// },
				// {
				// 	id: "nail231sfv23",
				// 	division_id: "npd",
				// 	title: "NAIL",
				// 	description: "",
				// },
				// {
				// 	id: "hai123123",
				// 	division_id: "npd",
				// 	title: "HAIR",
				// 	description: "",
				// },
				// {
				// 	id: "npdpromo",
				// 	division_id: "npd",
				// 	title: "PROMO",
				// 	description: "",
				// },
				// {
				// 	id: "promos",
				// 	division_id: "promos",
				// 	title: "Age-Defying P/O - All retailers",
				// 	description: "SL in-store P/O Q3 (July, August, September)",
				// },
				// {
				// 	id: "websitebanner",
				// 	division_id: "website",
				// 	title: "Hero (Promo 1)",
				// },
				// {
				// 	id: "prm234234",
				// 	division_id: "website",
				// 	title: `Product Carousel 1 (New Arrivals)`,
				// },
				// {
				// 	id: "1234dgdbfgb5",
				// 	division_id: "website",
				// 	title: "Homepage (Promo 2)",
				// },
				// {
				// 	id: "pc1bestsellers",
				// 	division_id: "website",
				// 	title: `Product Carousel 2 (Best sellers)`,
				// },
				// {
				// 	id: "hptrending",
				// 	division_id: "website",
				// 	title: `Homepage banner (Trending)`,
				// },
				// {
				// 	id: "hptrendingcarousel",
				// 	division_id: "website",
				// 	title: `Product Carousel 3 (Trending)`,
				// },
				// {
				// 	id: "hpfeaturedcategories",
				// 	division_id: "website",
				// 	title: `Featured Categories`,
				// },
				// {
				// 	id: "report1website",
				// 	division_id: "website",
				// 	title: `Website Update Report`,
				// },
				{
					id: "digitalreports",
					division_id: "reporting",
					title: `Digital EoM Reports`,
				},

				{
					id: "instagram",
					division_id: "socialmedia",
					title: `Instagram @revlonsa`,
				},
				{
					id: "facebook",
					division_id: "socialmedia",
					title: `Facebook - Revlon South Africa`,
				},
				{
					id: "tiktok",
					division_id: "socialmedia",
					title: `TikTok - @revlonsa`,
				},
				{
					id: "twitter",
					division_id: "socialmedia",
					title: `Twitter/X - @revlonsa`,
				},
				{
					id: "socialmediacontentcalendar",
					division_id: "socialmedia",
					title: `Content Calendar`,
				},
				{
					id: "brandpagedischem",
					division_id: "brandpages",
					title: `Brand Page - Dis-Chem`,
				},
				{
					id: "brandpagetakealot",
					division_id: "brandpages",
					title: `Brand Page - TakeAlot`,
				},
				{
					id: "brandpagefoschini",
					division_id: "brandpages",
					title: `Brand Page - Foschini`,
				},
				{
					id: "brandpageww",
					division_id: "brandpages",
					title: `Brand Page - WoolWorths`,
				},
				{
					id: "brandpagetw",
					division_id: "brandpages",
					title: `Brand Page - Truworths`,
				},
				{
					id: "brandpagesup",
					division_id: "brandpages",
					title: `Brand Page - Superbalist`,
				},
				{
					id: "brandpagesedgars",
					division_id: "brandpages",
					title: `Brand Page - Edgars`,
				},
				{
					id: "brandpagesascot",
					division_id: "brandpages",
					title: `Brand Page - Ascot`,
				},
			],

			events: [],

			loading: false,
			seasonHover: "",
			selectedMilestoneData: "",

			leftPanelMainWidth: 650,
			leftPanelWidth: 650,
			milestoneWidth: 250,

			fontColor: "#FFF",
			bgColor1: APP_COLOURS.BG2,
			calendarBgColour: APP_COLOURS.BG3,
			divisionsLeftBgColour: APP_COLOURS.BG3,
			divisionsTaskBgColour: APP_COLOURS.BG4,
			borderLeftColour: APP_COLOURS.BG4,
			dateBorders: APP_COLOURS.BG3,

			brand_data: "",
		};

		this._timeout = 500;
	}

	//
	//
	addTodayPreviewList = async () => {
		let data = this.state.events;
		// console.log(data);
		let milestones = this.state.milestones;

		// console.log("addTodayPreviewList");

		let listArray = [];

		for (const sub of data) {
			let isSubitem = (sub.milestone_division = milestones.filter(
				(d) => d.id == sub.milestone_id,
			));
			if (isSubitem.length > 0) {
				var sd = await moment(sub.start_date);
				var ed = await moment(sub.end_date);
				let today = moment();
				let isOnToday = sd <= today && ed >= today;

				if (isOnToday) {
					listArray.push(sub);
				}
			}
		}

		// console.log("TEMP TO DO LIST", listArray);
		let gantID = "tetrice_app";
		await this.sendToFiebase({
			data: JSON.parse(JSON.stringify(listArray)),
			gantt_id: gantID,
		});
		await this.setState({
			todayPreviewList: listArray,
		});
	};

	async sendToFiebase(data) {
		// let gantID = 'tetrice_app';
		// var db = firebaseApp.firestore();
		// const document = db.collection('gantt_today').doc(gantID);
		// await document
		//   .set(data)
		//   .then(() => {
		//     console.log('items sent');
		//   })
		//   .catch(error => console.log(error));
	}

	//
	//
	//
	expandRow = async (id) => {
		let exists = this.state.expandRow.some((d) => d == id);

		if (exists) {
			let filter = await this.state.expandRow.filter((d) => d !== id);
			await this.setState({
				expandRow: filter,
			});
		} else {
			let add = await this.state.expandRow.concat(id);
			await this.setState({
				expandRow: add,
			});
		}

		// await this.saveAppStates();
	};

	expandMilestoneRow = async (id) => {
		let exists = this.state.expandMilestoneRow.some((d) => d == id);

		if (exists) {
			let filter = await this.state.expandMilestoneRow.filter(
				(d) => d !== id,
			);
			await this.setState({
				expandMilestoneRow: filter,
			});
		} else {
			let add = await this.state.expandMilestoneRow.concat(id);
			await this.setState({
				expandMilestoneRow: add,
			});
		}

		// await this.saveAppStates();
	};
	//
	//
	// addCalendarItem = async () => {
	// 	let { new_start_date, new_end_date, taskName, selectedMainItemID } =
	// 		this.state;

	// 	if (taskName == "") return;
	// 	let id = uuid();
	// 	let obj = {
	// 		id: id,
	// 		milestone_id: selectedMainItemID,
	// 		title: taskName,
	// 		status: "pending",
	// 		order: 0,
	// 		start_date: moment(new_start_date).startOf("day"),
	// 		end_date: moment(new_end_date).startOf("day").add(1, "day"),
	// 	};

	// 	await this.addToArray(obj, "events");
	// 	await this.setState({
	// 		new_start_date: new Date(),
	// 		new_end_date: new Date(),
	// 		selectedSubTask: id,
	// 		currentStatus: "pending",
	// 		taskName: "",
	// 		addCalendarItem: false,
	// 	});
	// };

	addDivision = async () => {
		let { selectedColor, selectedDivisionData } = this.state;

		let obj = {
			id: uuid(),
			title: this.state.divisionTitleNew,
			color: selectedColor,
			brand_id: this.props.brand_id,
			order: this.state.divisions.length,
			assignment: [this.state.user_data],
			assignment_ids: [`${this.state.user_data.id}`],
		};
		console.log(obj);
		await this.addToArray(obj, "divisions");

		crudCreate("tracker_divisions", obj, () => {
			this.getTrackerData_();
			this.setState({
				selectedColor: "",
				divisionTitleNew: "",
				addDivisionModal: false,
			});
		});
	};

	addMilestone = async () => {
		let { selectedColor, selectedDivisionData } = this.state;

		let obj = {
			id: uuid(),
			title: this.state.milestoneTitleNew,
			color: selectedColor,
			division_id: selectedDivisionData.id,
			brand_id: this.props.brand_id,
			assignment: [{ ...this.state.user_data }],
			assignment_ids: [this.state.user_data.id],
		};
		console.log(obj);
		await this.addToArray(obj, "milestones");

		crudCreate("tracker_milestones", obj, () => {
			this.getTrackerData_();
			this.setState({
				selectedColor: "",
				divisionTitle: "",
				addMilestoneModal: false,
			});
		});
	};

	updateItem = async (id, side, oper) => {
		let array = await this.state.events;
		// console.log("ARRAY ===>", array);
		let index = await array.findIndex((d) => d.id == id);

		if (side == "left" && oper == "move") {
			array[index].end_date = moment(array[index].end_date).subtract(
				1,
				"day",
			);
			array[index].start_date = moment(array[index].start_date).subtract(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "move") {
			array[index].end_date = moment(array[index].end_date).add(1, "day");
			array[index].start_date = moment(array[index].start_date).add(
				1,
				"day",
			);
		}

		if (side == "left" && oper == "minus") {
			array[index].start_date = moment(array[index].start_date).subtract(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "plus") {
			array[index].end_date = moment(array[index].end_date).add(1, "day");
		}

		if (side == "left" && oper == "plus") {
			array[index].start_date = moment(array[index].start_date).add(
				1,
				"day",
			);
		}
		if (side == "right" && oper == "minus") {
			array[index].end_date = moment(array[index].end_date).subtract(
				1,
				"day",
			);
		}

		if (side == "status" && oper == "pending") {
			array[index].status = "inprogress";
			await this.setState({
				currentStatus: "inprogress",
			});
		}
		if (side == "status" && oper == "inprogress") {
			array[index].status = "complete";
			await this.setState({
				currentStatus: "complete",
			});
		}
		if (side == "status" && oper == "complete") {
			array[index].status = "pending";
			await this.setState({
				currentStatus: "pending",
			});
		}

		await this.setState({
			events: array,
		});

		// console.log(id, array[index]);

		// Add items to Temp List
		// await this.addTodayPreviewList();
		await this.saveupdateItem(array[index]);
	};

	saveupdateItem = (d) => {
		// console.log(d);
		// return;
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			this.setState({
				loading: true,
			});
		}, 1500);
		// return;
	};

	//
	// CRUD
	addToArray = async (data, array_name) => {
		let array = await this.state[array_name];
		await array.push(data);
		// console.log("addToArray", array_name, data);
		await this.setState({
			[array_name]: array,
		});
		await this.saveAppStates();
		await this.calculateCalendar();
	};

	removeFromArray = async (id, array_name) => {
		let array = this.state[array_name];
		const newarr = await array.filter((d) => d.id !== id);
		await this.setState({
			[array_name]: newarr,
		});

		if (array_name == "milestones") {
			// IF YOU DELETE THE MAIN ITEM AND IT HAS CHILDREN
			let array2 = this.state.events;
			const newarr2 = await array2.filter((d) => d.milestone_id !== id);
			await this.setState({
				events: newarr2,
			});
		}
		await this.saveAppStates();
		await this.calculateCalendar();
	};
	// CRUD END
	//

	calculateCalendar = async () => {
		const startWeek = this.state.startweekNumber;
		const endWeek = this.state.endweekNumber;

		// const startWeek = moment().startOf('month').week();
		// const endWeek = moment().endOf('month').week();

		let calendar = [];
		let calendarDays = [];
		for (var week = startWeek; week < endWeek; week++) {
			let days = await Array(7)
				.fill(0)
				.map((n, i) =>
					moment()
						.week(week)
						.startOf("week")
						.clone()
						.add(n + i, "day"),
				);
			// console.log(days);
			await calendar.push({
				week: week,
				days: days,
			});

			calendarDays = await [...calendarDays, ...days];
		}

		let sorted = await calendarDays.sort(function (a, b) {
			let first = new Date(a.date);
			let last = new Date(b.date);
			return first - last;
		});
		// console.log('sorted', sorted);
		let diff = await firstAndLast(sorted);

		await this.setState({
			calendar: calendar,
			calendarDays: calendarDays,
			calStartEnd: diff,
		});
		await this.addTodayPreviewList();
		// console.log('caslendar', JSON.stringify(diff));
	};

	saveAppStates = async () => {
		const {
			startweekNumber,
			endweekNumber,
			milestones,
			events,
			fontSize,
			expandRow,
		} = this.state;
		let obj = {
			startweekNumber,
			endweekNumber,
			milestones,
			events,
			fontSize,
			expandRow,
		};
		await this.onValueChange("ganttStates", JSON.stringify(obj));
	};

	async removeAsyncItem(key) {
		// try {
		//   await AsyncStorage.removeItem(key);
		//   return true;
		// } catch (exception) {
		//   alert('Error removing item');
		//   return false;
		// }
	}

	async onValueChange(item, selectedValue) {
		// await this.snoozeUpdateTimer();
		// try {
		//   await AsyncStorage.setItem(item, selectedValue).then(x => {});
		// } catch (error) {
		//   console.error(error);
		// }
	}

	async componentDidMount() {
		let user_data = await getUserData();
		let brand_data = await getBrand();

		let divisions = await getDivisions();
		let milestones = await getMilestones();
		let events = await getEvents();

		if (typeof user_data === "undefined") {
			// localStorage.clear();
			// window.location.href = "/";
		}
		await this.setState({
			user_data: user_data,
			brand_data: brand_data,
			divisions: typeof divisions !== "undefined" ? divisions : [],
			milestones: typeof milestones !== "undefined" ? milestones : [],
			events: typeof events !== "undefined" ? events : [],
		});
		await this.getTrackerData_();
		await this.calculateCalendar();

		this.setState({
			...this.props,
		});

		window.addEventListener("resize", this.resize.bind(this));
	}

	getTrackerData_ = async () => {
		console.log("this.props.brand_id", this.props.brand_id);

		await crudRead(
			"tracker_divisions",
			{
				field: "brand_id",
				action: "==",
				value: this.props.brand_id,
			},
			(s, d) => {
				console.log("divisions", d);
				this.setState({
					divisions: d,
				});
				localStorage.setItem("divisions", JSON.stringify(d));
				d.map((m) => console.log(m.id, m.title));
			},
		);

		await crudReadUnass(
			"tracker_items",
			{
				field: "brand_id",
				action: "==",
				value: this.props.brand_id,
			},
			(s, d) => {
				console.log(d);
				this.setState({
					events: d,
				});
				localStorage.setItem("events", JSON.stringify(d));
			},
		);

		await crudReadUnass(
			"tracker_milestones",
			{
				field: "brand_id",
				action: "==",
				value: this.props.brand_id,
			},
			(s, d) => {
				console.log(d);
				this.setState({
					milestones: d,
				});
				localStorage.setItem("milestones", JSON.stringify(d));
				d.map((m) => console.log(m.id, m.title));
			},
		);
	};

	// getTrackerData_ = async () => {
	// 	console.log("this.props.brand_id", this.props.brand_id);

	// 	await crudRead(
	// 		"tracker_divisions",
	// 		{
	// 			field: "brand_id",
	// 			action: "==",
	// 			value: this.props.brand_id,
	// 		},
	// 		async (s, d) => {
	// 			console.log(d);
	// 			this.setState({
	// 				divisions: d,
	// 			});

	// 			const get_ = d.forEach(async (m) => {
	// 				console.log(m.id);
	// 				await crudReadUnass(
	// 					"tracker_milestones",
	// 					{
	// 						field: "division_id",
	// 						action: "==",
	// 						value: m.id,
	// 					},
	// 					(s, ms) => {
	// 						console.log(ms);
	// 						// this.setState({
	// 						// 	milestones: d,
	// 						// });
	// 						// this.state.milestones.concat(d);
	// 						// d.map((m) => console.log(m.id, m.title));
	// 						this.setState({
	// 							milestones: [...this.state.milestones, ...ms],
	// 						});
	// 					},
	// 				);

	// 				await crudReadUnass(
	// 					"tracker_items",
	// 					{
	// 						field: "division_id",
	// 						action: "==",
	// 						value: m.id,
	// 					},
	// 					(s, ev) => {
	// 						console.log(ev);

	// 						this.setState({
	// 							events: [...this.state.events, ...ev],
	// 						});
	// 					},
	// 				);
	// 			});
	// 		},
	// 	);
	// };

	componentDidUpdate(prevProps) {}

	render() {
		return (
			<View
				style={{
					flex: 1,
					height: this.props.screenHeight,
					// padding: 5,
					overflow: "hidden",
					// backgroundColor: APP_COLOURS.BG,
					// backgroundColor: "#171A20",
				}}>
				{/* <View
					style={{
						// flex: 1,
						position: "absolute",
						left: 10,
						top: 10,
					}}>
					<Image
						// source={bg1}
						source={require("../../assets/logoTetrice.png")}
						style={{
							width: 150,
							height: 80,

							resizeMode: "contain",
						}}
					/>
				</View> */}
				{this.controles()}
				<AutoRefresh />
				{this.techSupportModal()}

				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							width: this.state.leftPanelMainWidth,
							// marginRight: 1,
							// borderRightWidth: 1,
							borderRightColor: this.state.borderLeftColour,
						}}
					/>
					{this.leftgroupsComp()}
					{this.calendarBars()}
				</View>

				{this.state.selectedMilestoneComp !== ""
					? this.selectedMilestoneComp(
							this.state.selectedMilestoneComp,
					  )
					: null}

				{this.state.showEditModal
					? this.selectedTaskComp(this.state.selectedSubTask)
					: null}

				{this.state.addCalendarItem ? (
					<AddTask
						{...this.state}
						close={() =>
							this.setState({
								addCalendarItem: false,
							})
						}
						reloadData={() => this.getTrackerData_()}
					/>
				) : null}

				{this.state.showDivision !== ""
					? this.showDivisionDetails()
					: null}

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.addMilestoneModal}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<ScrollView>
							<View
								style={{
									width: 500 - 40,
									maxWidth: 400,
									height: "auto",
									backgroundColor: "white",
									// alignItems: 'center',
									borderRadius: 12,
									paddingTop: 20,
									paddingBottom: 10,
									padding: 10,
									marginTop: 40,
								}}>
								<View
									style={{
										width: "100%",
										height: 30,
										backgroundColor:
											this.state.selectedColor == ""
												? APP_COLOURS.BG2
												: this.state.selectedColor,
										borderRadius: 5,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontSize: 19,
											fontWeight: "600",
										}}>
										{this.state.milestoneTitleNew}
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										justifyContent: "space-between",
										flexDirection: "row",
										marginTop: 5,
										marginBottom: 5,
									}}>
									{this.state.colours.map((d, i) => (
										<TouchableOpacity
											style={{
												flex: 1,
												marginRight: 5,
											}}
											key={`color_${i}`}
											onPress={() =>
												this.setState({
													selectedColor:
														this.state
															.selectedColor == d
															? ""
															: d,
												})
											}>
											<View
												style={{
													width: "100%",
													height: 30,
													backgroundColor: d,
													borderRadius: 5,
													alignItems: "center",
													justifyContent: "center",
													opacity:
														this.state
															.selectedColor !== d
															? 1
															: 0.1,
												}}></View>
										</TouchableOpacity>
									))}
								</View>

								{this.state.selectedColor == "" ? null : (
									<TextInput
										returnKeyType='done'
										autoCorrect={false}
										style={{
											fontSize: 16,
											fontWeight: "600",
											color: APP_COLOURS.BG2,
											width: "100%",
											height: "auto",
											borderWidth: 0.5,
											borderRadius: 15,
											padding: 10,
											// backgroundColor:
											// 	this.state.selectedColor,
										}}
										editable={true}
										onChangeText={(text) =>
											this.setState({
												milestoneTitleNew: text,
											})
										}
										placeholder='Milestone Title'
										ref='divisionasdc'
										clearButtonMode='while-editing'
										autoFocus
									/>
								)}

								<View
									style={{
										// backgroundColor: 'red',
										width: "100%",
										height: 40,
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 15,
									}}>
									<TouchableOpacity
										style={{
											flex: 1,
											justifyContent: "center",
										}}
										onPress={() => {
											this.setState({
												addMilestoneModal: false,
											});
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												fontSize: 16,
												fontWeight: "300",
											}}>
											Cancel
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => this.addMilestone()}
										style={{
											flex: 1,
											backgroundColor: APP_COLOURS.BLUE,
											justifyContent: "center",
											borderRadius: 20,
										}}>
										{this.state.newCatLoading ? (
											<View
												style={{
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<ActivityIndicator
													size='small'
													color='#000'
												/>
											</View>
										) : (
											<Text
												style={{
													width: "100%",
													textAlign: "center",
													color: "white",
													fontSize: 16,
													fontWeight: "600",
													padding: 5,
												}}>
												Save
											</Text>
										)}
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</View>
				</Modal>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.addDivisionModal}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<ScrollView>
							<View
								style={{
									width: 500 - 40,
									maxWidth: 400,
									height: "auto",
									backgroundColor: "white",
									// alignItems: 'center',
									borderRadius: 12,
									paddingTop: 20,
									paddingBottom: 10,
									padding: 10,
									marginTop: 40,
								}}>
								<View
									style={{
										width: "100%",
										height: 30,
										backgroundColor:
											this.state.selectedColor === ""
												? APP_COLOURS.BG2
												: this.state.selectedColor,
										borderRadius: 5,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontSize: 19,
											fontWeight: "600",
										}}>
										{this.state.divisionTitleNew}
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										justifyContent: "space-between",
										flexDirection: "row",
										marginTop: 5,
										marginBottom: 5,
									}}>
									{this.state.colours.map((d, i) => (
										<TouchableOpacity
											style={{
												flex: 1,
												marginRight: 5,
											}}
											key={`color_${i}`}
											onPress={() =>
												this.setState({
													selectedColor:
														this.state
															.selectedColor === d
															? ""
															: d,
												})
											}>
											<View
												style={{
													width: "100%",
													height: 30,
													backgroundColor: d,
													borderRadius: 5,
													alignItems: "center",
													justifyContent: "center",
													opacity:
														this.state
															.selectedColor !== d
															? 1
															: 0.1,
												}}></View>
										</TouchableOpacity>
									))}
								</View>

								<TextInput
									returnKeyType='done'
									autoCorrect={false}
									style={{
										fontSize: 16,
										fontWeight: "600",
										color: APP_COLOURS.BG2,
										width: "100%",
										height: "auto",
										borderWidth: 0.5,
										borderRadius: 15,
										padding: 10,
										// backgroundColor:
										// 	this.state.selectedColor,
									}}
									editable={true}
									onChangeText={(text) =>
										this.setState({
											divisionTitleNew: text,
										})
									}
									placeholder='Division Title'
									ref='divisionasdc'
									clearButtonMode='while-editing'
									autoFocus
								/>

								<View
									style={{
										// backgroundColor: 'red',
										width: "100%",
										height: 40,
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 15,
									}}>
									<TouchableOpacity
										style={{
											flex: 1,
											justifyContent: "center",
										}}
										onPress={() => {
											this.setState({
												addDivisionModal: false,
											});
										}}>
										<Text
											style={{
												width: "100%",
												textAlign: "center",
												fontSize: 16,
												fontWeight: "300",
											}}>
											Cancel
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => this.addDivision()}
										style={{
											flex: 1,
											backgroundColor: APP_COLOURS.BLUE,
											justifyContent: "center",
											borderRadius: 20,
										}}>
										{this.state.newCatLoading ? (
											<View
												style={{
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<ActivityIndicator
													size='small'
													color='#000'
												/>
											</View>
										) : (
											<Text
												style={{
													width: "100%",
													textAlign: "center",
													color: "white",
													fontSize: 16,
													fontWeight: "600",
													padding: 5,
												}}>
												Save
											</Text>
										)}
									</TouchableOpacity>
								</View>
							</View>
						</ScrollView>
					</View>
				</Modal>
			</View>
		);
	}

	controles = () => {
		return (
			<View
				style={{
					justifyContent: "space-between",
				}}>
				{/* <View style={{ paddingBottom: 4, flexDirection: "row" }}>
					<Text
						style={{
							color: "#FFF",
							fontSize: 15,
							fontWeight: "900",
							marginRight: 5,
						}}>
						{this.state.user_data.first_name}
					</Text>
					<Text
						style={{
							color: "#FFF",
							fontSize: 15,
							fontWeight: "900",
						}}>
						{this.state.user_data.last_name}
					</Text>
				</View> */}

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}>
					<TouchableOpacity
						style={{
							padding: 10,
							// backgroundColor: APP_COLOURS.BG2,

							borderRadius: 10,
							// width: "100%",
							flexDirection: "row",
							alignItems: "center",
						}}
						onPress={() => {
							window.location = "/tracker";
						}}>
						{/* <View
							style={{
								// width: 65,
								// height: 40,
								borderRadius: 10,
								// backgroundColor: APP_COLOURS.BG5,
								marginRight: 15,
								overflow: "hidden",
								backgroundColor: "#FFF",
							}}>
							<Image
								style={{
									width: 200,
									height: 100,
									resizeMode: "contain",
									// opacity: 0.7,
								}}
								source={{
									uri: this.state.brand_data?.logo_icon_url,
								}}
							/>
						</View> */}

						<View>
							<Text
								style={{
									fontSize: this.state.fontSize * 1.5,
									// fontWeight: "bold",
									color: "#FFF",
									// opacity: 0.8,
								}}>
								{this.state.brand_data?.title}
							</Text>

							<Text
								style={{
									fontSize: 9,
									color: "#FFF",
									opacity: 0.5,
								}}>
								{this.state.brand_data?.id}
							</Text>
						</View>
					</TouchableOpacity>
					<View />
					<View
						style={{
							width: 150,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}>
						<TouchableOpacity
							onPress={async () => {
								let start = this.state.startweekNumber;
								let eow = this.state.endweekNumber - 1;

								if (eow > start) {
									await this.setState({
										endweekNumber: eow,
									});
									await this.calculateCalendar();
									// await this.saveAppStates();
								}
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("./min_btn.png")}
							/>
						</TouchableOpacity>

						<Text
							style={{
								color: this.state.fontColor, // '#5A7BBA'
								fontSize: 17,
								fontWeight: "900",
								paddingBottom: 4,
							}}>
							{this.state.endweekNumber -
								this.state.startweekNumber}{" "}
							{this.state.endweekNumber -
								this.state.startweekNumber >
							1
								? "weeks"
								: "week"}
						</Text>

						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									endweekNumber: this.state.endweekNumber + 1,
								});
								await this.calculateCalendar();
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("./add_btn.png")}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							width: 150,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}>
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									startweekNumber:
										this.state.startweekNumber - 1,
									endweekNumber: this.state.endweekNumber - 1,
								});
								await this.calculateCalendar();
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 45,
									height: 45,
									resizeMode: "contain",
								}}
								source={require("./left_btn.png")}
							/>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									startweekNumber:
										this.state.startweekNumber + 1,
									endweekNumber: this.state.endweekNumber + 1,
								});
								await this.calculateCalendar();
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 45,
									height: 45,
									resizeMode: "contain",
								}}
								source={require("./right_btn.png")}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							width: 100,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-evenly",
							marginBottom: 5,
						}}>
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									fontSize: this.state.fontSize - 1,
								});
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("./min_btn.png")}
							/>
						</TouchableOpacity>

						<Text
							style={{
								color: this.state.fontColor, // '#5A7BBA'
								fontSize: 17,
								fontWeight: "900",
								paddingBottom: 4,
							}}>
							{this.state.fontSize}
						</Text>

						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									fontSize: this.state.fontSize + 1,
								});
								// await this.saveAppStates();
							}}>
							<Image
								style={{
									width: 25,
									height: 25,
									resizeMode: "contain",
								}}
								source={require("./add_btn.png")}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							padding: 7,
							display:
								this.state.user_data.username ===
								"wesley@tetrice.co.za"
									? "flex"
									: "none",
						}}>
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									addDivisionModal: true,

									divisionTitleNew: "",
								});
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									marginRight: 10,
								}}>
								<Image
									style={{
										width: 25,
										height: 25,
										resizeMode: "contain",
										marginRight: 5,
									}}
									source={require("./add_btn.png")}
								/>

								<Text
									style={{
										color: this.state.fontColor, // '#5A7BBA'
										fontSize: 14,
										fontWeight: "500",
									}}>
									Division
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	leftgroupsComp = () => {
		return (
			<View
				style={{
					height: "100%",
					flex: 1,
					width: "100%",
					position: "absolute",
					left: 0,
					zIndex: 99,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						width: this.state.leftPanelMainWidth,
						// paddingRight: 5,
						marginTop: 5,
						marginBottom: 5,
						// backgroundColor: "red",
						height: 10,
					}}></View>

				<View
					style={{
						width: this.state.leftPanelMainWidth,
						height: 100,
						justifyContent: "flex-end",
						alignItems: "center",
						// paddingRight: 5,
						flexDirection: "row",
					}}>
					{/* {this.state.filterOnlyThisWeek ? (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyThisWeek: false,
								})
							}>
							<Text
								style={{
									color: "#E0A749",
									fontSize: 13,
									fontWeight: "300",
									marginRight: 10,
								}}>
								Only in View Events
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyThisWeek: true,
								})
							}>
							<Text
								style={{
									color: this.state.fontColor,
									fontSize: 13,
									marginRight: 10,
									fontWeight: "300",
									opacity: 0.5,
								}}>
								Only in View Events
							</Text>
						</TouchableOpacity>
					)}

					{this.state.filterOnlyToday ? (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyToday: false,
								})
							}>
							<Text
								style={{
									color: "#E0A749",
									fontSize: 13,
									fontWeight: "300",
								}}>
								Today Only
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									filterOnlyToday: true,
								})
							}>
							<Text
								style={{
									color: this.state.fontColor,
									fontSize: 13,
									fontWeight: "300",
									opacity: 0.5,
								}}>
								Today Only
							</Text>
						</TouchableOpacity>
					)} */}
					{/* {this.state.expandRow.length > 0 ? (
			<TouchableOpacity
				style={{}}
				onPress={() =>
					this.setState({
						expandRow: [],
					})
				}>
				<Image
					style={{
						width: 25,
						height: 25,
						marginRight: 0,
						marginLeft: 20,
						resizeMode: "contain",
					}}
					source={require("./expand.png")}
				/>
			</TouchableOpacity>
		) : null} */}
				</View>

				<View style={{ flex: 1, width: "100%", marginTop: 20 }}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								flex: 1,
								width: "100%",
								flexDirection: "column",
							}}>
							{this.seasons()}
							{this.proejctsItem()}
						</View>
						<View
							style={{
								height: 300,
							}}></View>
					</ScrollView>
				</View>

				{this.state.selectedSubTask !== "" && !this.state.showEditModal
					? this.selectedTaskItem()
					: null}
			</View>
		);
	};

	seasons = () => {
		let { seasons } = this.state;

		return (
			<View>
				<View
					style={{
						width: "100%",
						marginBottom: 3,
						// backgroundColor: "red",
						// backgroundColor: APP_COLOURS.TRANSHALO,
					}}>
					<View
						style={{
							width: this.state.leftPanelMainWidth,
						}}
					/>

					<View
						style={
							{
								// backgroundColor: "rgba(0,0, 0, 0.5)",
							}
						}>
						{seasons
							.sort(function (a, b) {
								let first = new Date(a.start_date);
								let last = new Date(b.start_date);
								return first - last;
							})
							.map((d, i) => this.seasonalItems(d))}
					</View>
				</View>
			</View>
		);
	};

	seasonalItems = (sub) => {
		let calendarDays = this.state.calendarDays;
		if (typeof calendarDays[0] !== "undefined") {
			var sd = moment(sub.start_date).startOf("day");
			var ed = moment(sub.end_date).endOf("day");
			let cs = moment(this.state.calStartEnd.start);

			let left = sd.diff(cs, "days");
			let duration = ed.diff(sd, "days");

			let right = calendarDays.length - left - duration;

			let inView =
				left + duration <= 0
					? false
					: right + duration <= 0
					? false
					: true;

			let today = moment();
			let isOnToday = sd <= today && ed >= today;

			let isDueToday =
				moment().format("DD/MM/YYY") ===
				moment(sub.end_date).format("DD/MM/YYY");
			let isPastDue = moment().isSameOrAfter(moment(sub.end_date));
			isPastDue = isPastDue && sub.status !== "Completed";
			let showItem = true;

			if (this.state.filterOnlyThisWeek) {
				if (this.state.filterOnlyThisWeek && inView) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			if (this.state.filterOnlyToday) {
				if (this.state.filterOnlyToday && isOnToday) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			return (
				<View
					key={`season-${sub.id}`}
					onMouseEnter={() =>
						this.setState({ seasonHover: sub.start_date })
					}
					onMouseLeave={() => this.setState({ seasonHover: "" })}
					style={{
						width: "100%",
						// position: "absolute",
						flexDirection: "row",
						display: inView ? "flex" : "none",
					}}>
					<TouchableOpacity
						onPress={() => {
							console.log(sub);
						}}>
						<View
							style={{
								flex: 1,
								width: this.state.leftPanelWidth,
								paddingLeft: 5,
								marginBottom: 1,
								justifyContent: "center",
								// minHeight: 25,
							}}>
							{/* <View
								style={{
									flex: 1,
									flexDirection: "row",
									paddingLeft: 5,
									justifyContent: "space-between",
									marginRight: 2,
								}}>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										opacity: isOnToday
											? 1
											: inView
											? 0.7
											: 0.5,
										paddingLeft: 35,
									}}>
									<Text
										ellipsizeMode='clip'
										numberOfLines={1}
										style={{
											// flex: 1,
											color: this.state.fontColor,
											fontSize: this.state.fontSize + 1,
											fontWeight: isOnToday
												? "600"
												: "400",

											height:
												sub.status === "Completed"
													? this.state.fontSize
													: "auto",
										}}>
										{sub.title}
									</Text>

									<Text
										style={{
											color: this.state.fontColor,
											// width: 120,
											fontSize: this.state.fontSize - 3,
											fontWeight: "500",
											opacity:
												this.state.taskHover === sub.id
													? 1
													: 0,
										}}>
										{moment(sd).format("DD/MM")} -
										{" Due date: "}
										{moment(ed).format("DD/MM")}
									</Text>
								</View>
								<View
									style={{
										overflow: "hidden",
										width: 80,
										// backgroundColor: "green",
									}}>
									<View style={{}}>
										<Image
											source={sub?.image}
											// src={sub?.image}
											style={{
												width: 50,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							</View> */}
						</View>
					</TouchableOpacity>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							marginBottom: 1,
						}}>
						{left <= 0 ? null : (
							<View
								style={{
									// display: left <= 0 ? "none" : "flex",
									flex: left,
									alignItems: "center",
									justifyContent: "flex-end",
									flexDirection: "row",
								}}></View>
						)}

						<TouchableOpacity
							style={{
								display:
									left + duration <= 0
										? "none"
										: right + duration <= 0
										? "none"
										: "flex",
								flex:
									left <= 0
										? left + duration
										: right <= 0
										? right + duration
										: duration,
								// minWidth: 3,
								justifyContent: "center",
								// borderRadius: 8,
								borderLeftColor: APP_COLOURS.ORANGE,
								borderLeftWidth: 3,
								borderBottomColor: APP_COLOURS.ORANGE,
							}}
							onPress={() => {}}>
							<View
								style={{
									flexDirection: "row",
									paddingLeft: 5,
									alignItems: "center",
								}}>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View style={{}}>
										<Text
											style={{
												color: "#FFF",
												fontSize:
													this.state.fontSize - 3,
												fontWeight: "700",
											}}>
											{sub.title}
										</Text>
									</View>
								</View>
							</View>
						</TouchableOpacity>

						<View
							style={{
								display: right <= 0 ? "none" : "flex",
								flex: right <= 0 ? 0 : right,
								alignItems: "center",
								justifyContent: "flex-start",
								flexDirection: "row",
							}}>
							{/* {this.assignment(sub, 25)} */}
						</View>
					</View>
				</View>
			);
		}
	};

	proejctsItem = () => {
		let { divisions } = this.state;

		return (
			<View>
				{/* .sort((a, b) => a.title > b.title)
					.sort((a, b) => b.priority - a.priority) */}
				{divisions
					.sort((a, b) => a.order > b.order)

					.filter((d_) => {
						let filter = d_;

						if (
							typeof this.state.filterTab !== "undefined" &&
							this.state.filterTab !== ""
						) {
							let text_ = this.state.filterTab;
							var outString = text_.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							let join_names = `${d_.brand?.title}`;

							join_names = join_names.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							return join_names
								.toLowerCase()
								.includes(outString.toLowerCase());
						}

						return filter;
					})
					.filter((d_) => {
						let filter = d_;

						if (
							typeof this.state.filterSearch !== "undefined" &&
							this.state.filterSearch !== ""
						) {
							let text_ = this.state.filterSearch;
							var outString = text_.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							let join_names = `${d_.title} ${d_.brand?.title}`;

							join_names = join_names.replace(
								/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
								"",
							);

							return join_names
								.toLowerCase()
								.includes(outString.toLowerCase());
						}

						return filter;
					})
					.map((division) => (
						<View
							onMouseEnter={() => {
								this.setState({ divhover: division.id });
							}}
							onMouseLeave={() => this.setState({ divhover: "" })}
							key={`divisions-${division.id}`}
							style={{
								width: "100%",
								// marginBottom: 100,
								borderTopWidth: 1,
								borderTopColor: APP_COLOURS.BG4,
								marginBottom: 20,
								// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT1,
								backgroundColor: APP_COLOURS.BACK_TRANSPARENT3,
								// opacity:
								// 	this.state.milestoneHover === division.id
								// 		? 1
								// 		: 0.8,
							}}>
							<View
								style={{
									width: this.state.leftPanelMainWidth,
								}}
							/>
							<View>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
										// backgroundColor: "#FFF",
										// backgroundColor:
										// 	this.state.calendarBgColour,
										// backgroundColor: "rgba(0,0, 0, 0.4)",
									}}>
									{/* <TouchableOpacity
										style={{
											opacity:
												this.state.milestones.filter(
													(d_) =>
														d_.division_id ===
														division.id,
												).length > 0
													? 1
													: 0.1,
										}}
										onPress={() =>
											this.expandMilestoneRow(division.id)
										}>
										<Image
											style={{
												width: 35,
												height: 35,
												marginRight: 10,
												marginLeft: 5,
												resizeMode: "contain",
											}}
											source={require("./expand.png")}
										/>
									</TouchableOpacity> */}

									<View
										style={{
											// flex: 1,

											flexDirection: "row",
											justifyContent: "space-between",
											width: this.state
												.leftPanelMainWidth,
											alignItems: "center",
											// backgroundColor: APP_COLOURS.BG2,
											borderRadius: 2,
										}}>
										<TouchableOpacity
											style={{
												opacity:
													this.state.milestones.filter(
														(d_) =>
															d_.division_id ===
															division.id,
													).length > 0
														? 1
														: 0.1,
												flex: 1,
											}}
											onPress={() =>
												this.expandMilestoneRow(
													division.id,
												)
											}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													// padding: 5,
													marginRight: 2,
													borderTopRightRadius: 5,
													// paddingLeft: 6,
													minHeight: 50,
													borderLeftWidth: 5,
													borderLeftColor:
														division.color,
												}}>
												<View
													style={{
														flex: 1,
														paddingLeft: 8,
													}}>
													<Text
														style={{
															color: "#FFF", // '#5A7BBA'
															fontSize:
																this.state
																	.fontSize *
																1.5,
															fontWeight: "500",
															// marginBottom: 6,
														}}>
														{division.title}
													</Text>
												</View>

												{/* <View
													style={{
														flexDirection: "row",
														alignItems: "center",
														width: 80,
													}}>
													{this.assignment(
														division,
														size,
													)}
												</View> */}
											</View>
										</TouchableOpacity>

										<TouchableOpacity
											style={{
												width: 100,
												// backgroundColor: "red",
												paddingLeft: 10,
												overflow: "hidden",
												paddingTop: 10,
											}}
											onPress={() => {
												this.setState({
													showDivision: division.id,
													divData: {
														...division,
														milestones:
															this.state.milestones.filter(
																(d_) =>
																	d_.division_id ===
																	division.id,
															),
													},
												});
												console.log(division.id);
											}}>
											<View
												style={{
													flexDirection: "row",
													// width: 100,
													// height: 50,
													// backgroundColor: "red",
													// maxWidth: 220,
													// overflow: "hidden",
													marginRight: 10,
												}}>
												{/* .slice(0, 5) */}
												{typeof division.assignment !==
												"undefined"
													? division.assignment
															.sort(
																(a, b) =>
																	a.first_name >
																	b.first_name,
															)
															.map((ass, ind) => (
																<View
																	style={{
																		width: 20,
																		zIndex:
																			99 -
																			ind,
																		height: "auto",
																		overflow:
																			"visible",
																	}}>
																	<User
																		index={
																			ind
																		}
																		d={ass}
																		preview={
																			true
																		}
																	/>
																</View>
															))
													: null}
											</View>
										</TouchableOpacity>
									</View>

									{this.state.divhover === division.id ? (
										<View>
											<TouchableOpacity
												onPress={async () => {
													await this.setState({
														addMilestoneModal: true,
														selectedDivisionData:
															division,
														milestoneTitleNew: "",
													});
												}}>
												<View
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent:
															"center",
														marginRight: 10,
													}}>
													<Image
														style={{
															width: 16,
															height: 16,
															resizeMode:
																"contain",
															marginRight: 5,
														}}
														source={require("./add_btn.png")}
													/>

													<Text
														style={{
															color: this.state
																.fontColor, // '#5A7BBA'
															fontSize: 12,
															fontWeight: "500",
														}}>
														Add Milestone
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									) : null}
								</View>
							</View>

							<View
								style={
									{
										// backgroundColor:
										// 	"rgba(255,255, 255, 0.015)",
										// backgroundColor: "rgba(0,0, 0, 0.2)",
										// backgroundColor: "rgba(255,255, 255, 0.17)",
										// display: this.state.expandMilestoneRow.some(
										// 	(d) => division.id === d,
										// )
										// 	? "flex"
										// 	: "none",
									}
								}>
								{this.state.milestones
									.filter(
										(d_) => d_.division_id === division.id,
									)
									.map((d, i) =>
										this.milstoneItems(d, division),
									)}
							</View>
							{/* .filter((f) => f.status !== "Completed") */}
						</View>
					))}
			</View>
		);
	};

	showDivisionDetails = () => {
		return (
			<DivisionDetails
				divData={this.state.divData}
				close={() =>
					this.setState({
						showDivision: "",
					})
				}
				reload={() => {
					this.getTrackerData_();
					this.setState({
						showDivision: "",
					});
				}}
			/>
		);
	};

	milstoneItems = (milestone, division) => {
		let calendarDays = this.state.calendarDays;
		if (typeof calendarDays[0] !== "undefined") {
			let cs = moment(this.state.calStartEnd.start);
			let ce = moment(this.state.calStartEnd.end);
			let events = this.state.events.filter(
				(d) => d.milestone_id === milestone.id,
			);
			let sDates = events
				.sort(function (a, b) {
					let first = new Date(a.start_date);
					let last = new Date(b.start_date);
					return first - last;
				})
				.map((it) => it.start_date);
			let eDates = events
				.sort(function (a, b) {
					let first = new Date(a.end_date);
					let last = new Date(b.end_date);
					return first - last;
				})
				.map((it) => it.end_date);
			let lastDateCovered = firstAndLast(eDates);
			let LastDate = lastDateCovered.end;

			let leftDate = moment(sDates[0]);
			let rightDate = moment(LastDate);

			let leftStart = leftDate.diff(cs, "days");

			let milestoneDuration = rightDate.diff(leftDate, "days") + 1;

			let rightEnd = calendarDays.length - leftStart - milestoneDuration;

			// leftStart = leftStart < 0 ? 0 : leftStart;
			// rightEnd = rightEnd < 0 ? 0 : rightEnd;

			let calendarEvents = this.state.events.filter(
				(d) => d.milestone_id === milestone.id,
			);

			let inView =
				leftStart + milestoneDuration <= 0
					? false
					: rightEnd + milestoneDuration <= 0
					? false
					: true;

			return (
				<View
					onMouseEnter={() => this.setState({ hover: milestone.id })}
					onMouseLeave={() => this.setState({ hover: "" })}
					style={{
						width: "100%",
						// marginBottom: 5,
						opacity: inView
							? this.state.hover === milestone.id
								? 1
								: 0.7
							: 0.7,

						// paddingLeft: 2,
						flexDirection: "row",
						// display: inView
						// 	? calendarEvents.length > 0
						// 		? "flex"
						// 		: "none"
						// 	: "none",
					}}
					key={`${milestone.id}-mid`}>
					{/* <View
						style={{
							width: this.state.leftPanelMainWidth - 10,
						}}
					/> */}
					<View
						style={{
							// backgroundColor: "rgba(0,0, 0, 0.1)",
							// backgroundColor: this.state.divisionsTaskBgColour,
							display: "none",
							// backgroundColor: "red",
						}}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								borderLeftWidth: 10,
								// borderLeftColor: this.state.expandRow.some(
								// 	(d) => milestone.id === d,
								// )
								// 	? division.color
								// 	: "transparent",

								// 	? division.color
								// 	: "transparent",
								borderLeftColor: division.color,
								// borderTopWidth: inView ? 2 : 0,
								// borderTopWidth: 2,
								// borderTopColor: "rgba(255,255, 255, 0.1)",
								//
								// borderTopColor: this.state.expandRow.some(
								// 	(d) => milestone.id === d,
								// )
								// 	? "rgba(255,255, 255, 0.17)"
								// 	: "transparent",
								// borderTopLeftRadius: 10,
								// paddingTop: 8,

								// backgroundColor: "rgba(0,0, 0, 0.5)",
							}}>
							<TouchableOpacity
								activeOpacity={0.8}
								onPress={() => this.expandRow(milestone.id)}>
								<View
									style={{
										width: this.state.leftPanelWidth - 10,
										flexDirection: "row",
										alignItems: "center",
										// padding: 8,
										justifyContent: "space-between",
										// backgroundColor:
										// 	"rgba(255,255, 255, 0.7)",
										// borderRadius: 5,
										// marginBottom: 5,
										// marginRight: 2,
										// paddingLeft: 50,
									}}>
									{calendarEvents.length > 0 ? (
										<View
											style={{
												padding: 3,
												backgroundColor:
													APP_COLOURS.BG3,
												borderRadius: 4,
												borderTopLeftRadius: 0,
												width: 25,
												height: 35,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													color: milestone.color,
													fontSize:
														this.state.fontSize,
													fontWeight: "500",
													opacity: inView ? 1 : 0,
													// marginRight: 2,
												}}>
												{calendarEvents.length}
											</Text>
										</View>
									) : null}
									<View
										style={{
											flex: 1,
											paddingLeft: 10,
											opacity:
												calendarEvents.length > 0
													? inView
														? 1
														: 0.6
													: 0.6,
											// position: "absolute",
										}}>
										{/* <Text
											style={{
												color: "#FFF", // '#5A7BBA'
												fontSize:
													this.state.fontSize * 0.9,
												fontWeight: "400",
												opacity: inView ? 1 : 0.7,
											}}>
											{milestone.title}
										</Text>
										<Text
											style={{
												color: this.state.fontColor, // '#5A7BBA'
												fontSize:
													this.state.fontSize - 2,
												fontWeight: "300",
											}}>
											{milestone.description}
										</Text> */}
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										{/* {this.state.hover === milestone.id ? (
											<View>
												<TouchableOpacity
													onPress={async () => {
														console.log(milestone);
														await this.setState({
															selecteddivision:
																division,
															selectedMilestoneComp:
																milestone,
														});
													}}>
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"center",
															marginRight: 10,
														}}>
														<Text
															style={{
																color: this
																	.state
																	.fontColor, // '#5A7BBA'
																fontSize: 12,
																fontWeight:
																	"300",
															}}>
															Edit
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										) : null} */}

										{this.state.hover === milestone.id ? (
											<View>
												<TouchableOpacity
													onPress={async () => {
														await this.setState({
															addCalendarItem: true,
															selectedMilestoneData:
																milestone,
														});
													}}>
													<View
														style={{
															flexDirection:
																"row",
															alignItems:
																"center",
															justifyContent:
																"center",
															marginRight: 10,
														}}>
														<Image
															style={{
																width: 16,
																height: 16,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
															source={require("./add_btn.png")}
														/>

														<Text
															style={{
																color: this
																	.state
																	.fontColor, // '#5A7BBA'
																fontSize: 12,
																fontWeight:
																	"500",
															}}>
															Add
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										) : null}

										{/* {calendarEvents.length > 0 ? (
											<TouchableOpacity
												style={{
													opacity: inView ? 1 : 0,
												}}
												onPress={() =>
													this.expandRow(milestone.id)
												}>
												<Image
													style={{
														width: 25,
														height: 25,
														// marginRight: 10,
														resizeMode: "contain",
													}}
													source={require("./contract.png")}
												/>
											</TouchableOpacity>
										) : (
											<TouchableOpacity
												style={{
													opacity: inView ? 1 : 0,
												}}
												onPress={() =>
													this.expandRow(milestone.id)
												}>
												<Image
													style={{
														width: 25,
														height: 25,
														// marginRight: 10,
														resizeMode: "contain",
													}}
													source={require("./expand.png")}
												/>
											</TouchableOpacity>
										)} */}
									</View>
								</View>
							</TouchableOpacity>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									marginBottom: 1,
									// padding: 5,
								}}>
								<View
									style={{
										display:
											leftStart <= 0 ? "none" : "flex",
										flex: leftStart <= 0 ? 0 : leftStart,
										alignItems: "flex-start",
										justifyContent: "center",
									}}></View>

								<View
									style={{
										display:
											leftStart + milestoneDuration <= 0
												? "none"
												: rightEnd +
														milestoneDuration <=
												  0
												? "none"
												: "flex",
										flex:
											leftStart <= 0
												? leftStart + milestoneDuration
												: rightEnd <= 0
												? rightEnd + milestoneDuration
												: milestoneDuration,
										backgroundColor:
											"rgba(255,255, 255, 0.05)",
										// padding: 6,
										// height: 40,
										// borderTopLeftRadius:
										// 	leftStart <= 0 ? 0 : 5,
										// borderTopRightRadius:
										// 	rightEnd <= 0 ? 0 : 5,
										opacity:
											calendarEvents.length > 0 ? 1 : 0,

										borderTopLeftRadius: 5,
										borderTopRightRadius: 5,
									}}>
									<TouchableOpacity
										activeOpacity={0.8}
										onPress={() =>
											this.expandRow(milestone.id)
										}>
										<View
											style={{
												width: "100%",
												height: "auto",

												position: "absolute",
												opacity: 0.4,
												borderRadius: 2,
												// padding: 6,
												paddingLeft: 5,
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: this.state.fontColor, // '#5A7BBA'
													fontSize:
														this.state.fontSize,
													fontWeight: "500",
													opacity: 0,
												}}>
												Opacity
											</Text>
										</View>
										<View
											style={{
												width: "100%",
												height: 35,
												borderRadius: 2,
												padding: 5,
												paddingLeft: 5,
												alignItems: "center",
												justifyContent: "center",
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: this.state.fontColor, // '#5A7BBA'
													fontSize:
														this.state.fontSize,
													fontWeight: "500",
													// opacity: 0.8,
												}}>
												{milestone.title}
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								<View
									style={{
										display:
											rightEnd <= 0 ? "none" : "flex",
										flex: rightEnd <= 0 ? 0 : rightEnd,
									}}></View>
							</View>
						</View>
					</View>
					{/* <View
						style={{
							width: "100%",
							height: 10,
							flexDirection: "row",
							alignItems: "center",
							borderLeftWidth: 10,
							borderLeftColor: this.state.expandRow.some(
								(d) => milestone.id === d,
							),
							borderLeftColor: division.color,
						}}
					/> */}

					<View
						style={{
							width: "100%",
							flexDirection: "row",
							// width: this.state.leftPanelMainWidth,
							// display: this.state.expandRow.some(
							// 	(d) => milestone.id === d,
							// )
							// 	? "flex"
							// 	: "none",
							// backgroundColor: "red",
							display: "flex",
							marginBottom: 2,
						}}>
						<View
							style={{
								width: this.state.milestoneWidth,
								paddingLeft: 10,
								minHeight: 50,
								// opacity:
								// 	calendarEvents.length > 0
								// 		? inView
								// 			? 1
								// 			: 0.6
								// 		: 0.6,
								justifyContent: "center",
								// alignItems: "center",
								backgroundColor:
									calendarEvents.length > 0
										? inView
											? APP_COLOURS.BG4
											: "transparent"
										: "transparent",
								// borderWidth: 1,
								// borderColor: APP_COLOURS.BG4,
							}}>
							<Text
								style={{
									color: "#FFF", // '#5A7BBA'
									fontSize: this.state.fontSize,
									fontWeight: "400",
									opacity: inView ? 1 : 0.7,
								}}>
								{milestone.title}
							</Text>
							<Text
								style={{
									color: this.state.fontColor, // '#5A7BBA'
									fontSize: this.state.fontSize * 0.8,
									fontWeight: "300",
								}}>
								{milestone.description}
							</Text>
						</View>
						<View
							style={{
								flex: 1,
								// display: this.state.expandRow.some(
								// 	(d) => milestone.id === d,
								// )
								// 	? "flex"
								// 	: "none",
								// borderLeftWidth: 3,
								// borderLeftColor: division.color,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									minHeight: 25,
								}}>
								<View
									style={{
										width:
											this.state.leftPanelWidth -
											this.state.milestoneWidth,
									}}
								/>
								<View
									style={{
										display:
											leftStart <= 0 ? "none" : "flex",
										flex: leftStart <= 0 ? 0 : leftStart,
										alignItems: "flex-start",
										justifyContent: "center",
									}}
								/>

								{this.state.hover === milestone.id ? (
									<View
										style={{
											position: "absolute",
											top: 2,
											left: 10,
											backgroundColor: APP_COLOURS.BG5,
											borderRadius: 10,
											padding: 5,
										}}>
										<TouchableOpacity
											onPress={async () => {
												await this.setState({
													addCalendarItem: true,
													selectedMilestoneData:
														milestone,
												});
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "center",
													marginRight: 10,
												}}>
												<Image
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														marginRight: 5,
													}}
													source={require("./add_btn.png")}
												/>

												<Text
													style={{
														color: this.state
															.fontColor, // '#5A7BBA'
														fontSize: 12,
														fontWeight: "500",
													}}>
													Add
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								) : null}

								<View
									style={{
										display:
											leftStart + milestoneDuration <= 0
												? "none"
												: rightEnd +
														milestoneDuration <=
												  0
												? "none"
												: "flex",
										flex:
											leftStart <= 0
												? leftStart + milestoneDuration
												: rightEnd <= 0
												? rightEnd + milestoneDuration
												: milestoneDuration,
										backgroundColor:
											"rgba(255,255, 255, 0.05)",
										// padding: 6,
										// height: 40,
										// borderTopLeftRadius:
										// 	leftStart <= 0 ? 0 : 5,
										// borderTopRightRadius:
										// 	rightEnd <= 0 ? 0 : 5,
										opacity:
											calendarEvents.length > 0 ? 1 : 0,

										borderTopLeftRadius: 5,
										borderTopRightRadius: 5,
									}}>
									<TouchableOpacity
										activeOpacity={0.8}
										onPress={() =>
											this.expandRow(milestone.id)
										}>
										<View
											style={{
												width: "100%",
												height: "auto",

												position: "absolute",
												opacity: 0.4,
												borderRadius: 2,
												// padding: 6,
												paddingLeft: 5,
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: this.state.fontColor, // '#5A7BBA'
													fontSize:
														this.state.fontSize,
													fontWeight: "500",
													opacity: 0,
												}}>
												Opacity
											</Text>
										</View>
										<View
											style={{
												width: "100%",
												height: 35,
												borderRadius: 2,
												padding: 5,
												paddingLeft: 5,
												// alignItems: "center",
												justifyContent: "center",
											}}>
											<Text
												adjustsFontSizeToFit
												numberOfLines={1}
												style={{
													color: this.state.fontColor, // '#5A7BBA'
													fontSize:
														this.state.fontSize *
														0.8,
													fontWeight: "300",
													opacity: 0.8,
												}}>
												{milestone.title}
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								<View
									style={{
										display:
											rightEnd <= 0 ? "none" : "flex",
										flex: rightEnd <= 0 ? 0 : rightEnd,
									}}
								/>
							</View>
							{calendarEvents
								.sort(
									(a, b) =>
										moment(a.start_date).unix() >
										moment(b.start_date).unix(),
								)
								.map((sub, i) =>
									this.calendarItems(sub, milestone),
								)}
						</View>
					</View>

					{/* <View
						style={{
							flex: 1,
							backgroundColor: "green",
						}}>
						{calendarEvents
							.sort(
								(a, b) =>
									moment(a.start_date).unix() >
									moment(b.start_date).unix(),
							)
							.map((sub, i) =>
								this.calendarItems(sub, milestone),
							)}
					</View> */}
				</View>
			);
		}
	};

	calendarItems = (sub, milestone) => {
		let calendarDays = this.state.calendarDays;
		if (typeof calendarDays[0] !== "undefined") {
			var sd = moment(sub.start_date).startOf("day");
			var ed = moment(sub.end_date).endOf("day");
			let cs = moment(this.state.calStartEnd.start);

			let left = sd.diff(cs, "days");
			let duration = ed.diff(sd, "days") + 1;

			let right = calendarDays.length - left - duration;

			let inView =
				left + duration <= 0
					? false
					: right + duration <= 0
					? false
					: true;

			let today = moment();
			let isOnToday = sd <= today && ed >= today;

			let isDueToday =
				moment().format("DD/MM/YYY") ===
				moment(sub.end_date).format("DD/MM/YYY");
			let isPastDue = moment().isSameOrAfter(moment(sub.end_date));
			isPastDue = isPastDue && sub.status !== "Completed";
			let showItem = true;

			let hasBegun = moment().isSameOrAfter(moment(sub.start_date));

			if (this.state.filterOnlyThisWeek) {
				if (this.state.filterOnlyThisWeek && inView) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			if (this.state.filterOnlyToday) {
				if (this.state.filterOnlyToday && isOnToday) {
					showItem = true;
				} else {
					showItem = false;
				}
			}

			let selecteddivision = this.state.divisions.filter(
				(f) => f.id === sub.division_id,
			);

			// let bgColour =
			// 	isDueToday || isPastDue
			// 		? APP_COLOURS.RED
			// 		: TaskStatus(sub.status);

			let bgColour =
				isDueToday || isPastDue
					? APP_COLOURS.RED
					: isOnToday
					? APP_COLOURS.ORANGE
					: APP_COLOURS.BLUE;

			return (
				<View
					key={`${sub.id}-sid`}
					onMouseEnter={() => this.setState({ taskHover: sub.id })}
					onMouseLeave={() => this.setState({ taskHover: "" })}
					style={{
						width: "100%",
						display: inView ? "flex" : "none",

						flexDirection: "row",

						// backgroundColor:
						// 	this.state.selectedSubTask.id === sub.id
						// 		? "rgba(0,0, 0, 0.1)"
						// 		: "transparent",
						// opacity: sub.status === "Completed" ? 0.7 : 1,

						// display: this.state.expandRow.some(
						// 	(d) => milestone.id === d,
						// )
						// 	? showItem
						// 		? "flex"
						// 		: "none"
						// 	: "none",
					}}>
					<TouchableOpacity
						onPress={() => {
							console.log(sub);
							this.setState({
								showEditModal: true,
								selectedSubTask: sub,
								selectedMilestoneData: milestone,
								currentStatus: sub.status,
								selecteddivision: selecteddivision[0],
								currentSubItemId: sub.id,
							});
						}}>
						<View
							style={{
								flex: 1,
								width:
									this.state.leftPanelWidth -
									this.state.milestoneWidth,
								// paddingLeft: 10,
								marginBottom: 1,
								justifyContent: "center",
								minHeight: 40,

								// backgroundColor:
								// 	this.state.divisionsTaskBgColour,

								borderLeftWidth: 15,
								borderLeftColor: this.state.expandRow.some(
									(d) => milestone.id === d,
								)
									? milestone.color
									: "transparent",
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									// paddingLeft: 5,
									justifyContent: "space-between",
									// marginRight: 2,

									// opacity:
									// 	this.state.taskHover === sub.id
									// 		? 1
									// 		: 0.7,
									alignItems: "center",
								}}>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										// opacity: isOnToday
										// 	? 1
										// 	: inView
										// 	? 0.7
										// 	: 0.5,
										// paddingLeft: 35,
									}}>
									<Text
										ellipsizeMode='clip'
										numberOfLines={1}
										style={{
											// flex: 1,
											color: "#FFF",
											// : this.state.fontColor,
											fontSize: this.state.fontSize,
											fontWeight: isOnToday
												? "600"
												: "400",

											// height:
											// 	sub.status === "Completed"
											// 		? this.state.fontSize
											// 		: "auto",
										}}>
										{sub.title}
									</Text>

									<Text
										style={{
											color: this.state.fontColor,
											// width: 120,
											fontSize: this.state.fontSize - 3,
											fontWeight: "400",
											opacity:
												this.state.taskHover === sub.id
													? 1
													: 0.3,
										}}>
										{moment(sd).format("DD MMM")} -
										{" End date: "}
										{moment(ed).format("DD MMM")}
									</Text>
								</View>

								<View
									style={{
										width: 70,
										marginRight: 4,
										backgroundColor: TaskStatus(sub.status),
										height: 40,
										justifyContent: "center",
										alignItems: "center",
										// borderWidth: 0.5,
										borderRadius: 2,
										paddingLeft: 5,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 10,
										}}>
										{sub.status}
									</Text>
								</View>

								{/* <View
									style={{
										overflow: "hidden",
										width: 80,
										// backgroundColor: "green",
										// display:
										// 	typeof sub?.imageData?.url !==
										// 	"undefined"
										// 		? "flex"
										// 		: "none",
									}}>
									{typeof sub?.documentUrl !== "undefined" ? (
										<Image
											source={require("./pdf.png")}
											style={{
												width: 25,
												height: 25,
												resizeMode: "contain",
												marginRight: 10,
											}}
										/>
									) : (
										<View
											style={{
												borderRadius: 5,
												overflow: "hidden",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Image
												source={sub?.imageData?.url}
												style={{
													width: 50,
													height: 50,
													resizeMode: "contain",
												}}
											/>
										</View>
									)}
								</View> */}
							</View>
						</View>
					</TouchableOpacity>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							marginBottom: 1,
						}}>
						{left <= 0 ? null : (
							<View
								style={{
									// display: left <= 0 ? "none" : "flex",
									flex: left,
									alignItems: "center",
									justifyContent: "flex-end",
									flexDirection: "row",
								}}></View>
						)}

						<TouchableOpacity
							style={{
								// backgroundColor: bgColour,
								display:
									left + duration <= 0
										? "none"
										: right + duration <= 0
										? "none"
										: "flex",
								flex:
									left <= 0
										? left + duration
										: right <= 0
										? right + duration
										: duration,
								// minWidth: 3,
								justifyContent: "center",
								// borderWidth: 4,
								// borderColor: bgColour,
								borderColor: TaskStatus(sub.status),
								// borderRadius: 8,

								// borderRightWidth: right <= 0 ? 0 : 3,
								// borderTopRightRadius: right <= 0 ? 0 : 10,
								// borderBottomRightRadius: right <= 0 ? 0 : 10,

								borderTopRightRadius: right <= 0 ? 0 : 10,
								borderBottomRightRadius: right <= 0 ? 0 : 10,
								borderTopLeftRadius: left <= 0 ? 0 : 10,
								borderBottomLeftRadius: left <= 0 ? 0 : 10,
								overflow: "hidden",
							}}
							onPress={() => {
								// if (typeof sub.documentUrl !== "undefined") {
								// 	window.open(sub?.documentUrl, "_blank");
								// } else {
								// this.setState({
								// 	selectedSubTask: sub,
								// 	selectedMilestoneData: milestone,
								// 	currentStatus: sub.status,

								// 	currentSubItemId: sub.id,
								// });
								// console.log(sub, this.state.divisions);
								// return;
								this.setState({
									showEditModal: true,
									selectedSubTask: sub,
									selectedMilestoneData: milestone,
									currentStatus: sub.status,
									selecteddivision: selecteddivision[0],
									currentSubItemId: sub.id,
								});
								// }
							}}>
							<View
								style={{
									flexDirection: "row",
									// backgroundColor: bgColour,
									backgroundColor: TaskStatus(sub.status),
									// opacity:
									// 	sub.status === "Urgent attention" ||
									// 	sub.status === "Attention Required"
									// 		? 1
									// 		: this.state.taskHover === sub.id
									// 		? 1
									// 		: 0.8,
									padding: 3,
									// height: this.state.fontSize + 12,
									// borderRadius: 5,

									overflow: "hidden",
									alignItems: "center",
								}}>
								<View
									style={{
										width: "100%",
										flexDirection: "row",
										alignItems: "center",
									}}>
									{/* <View
										style={{
											marginRight: 10,
											overflow: "hidden",
											justifyContent: "center",
											alignItems: "center",
										}}>
										{typeof sub?.documentUrl !==
										"undefined" ? (
											<View
												style={{
													width: 50,
													height: 50,
													resizeMode: "contain",
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Image
													source={require("./pdf.png")}
													style={{
														width: 30,
														height: 30,
														resizeMode: "contain",
													}}
												/>
											</View>
										) : (
											<Image
												source={sub?.imageData?.url}
												// src={sub?.image}
												style={{
													width: 50,
													height: 50,
													resizeMode: "contain",

												}}
											/>
										)}
									</View> */}
									<View
										style={{
											height: 40,
											padding: 3,
										}}>
										<Text
											// adjustsFontSizeToFit
											// numberOfLines={1}
											style={{
												// color: isOnToday
												// 	? APP_COLOURS.BG2
												// 	: "#FFF",
												color: "#FFF",
												fontSize: this.state.fontSize,
												fontWeight: "700",

												// position: "absolute",
												// left: left + 10,
												//
											}}>
											{sub.title}
										</Text>
										<Text
											style={{
												color: this.state.fontColor,
												// width: 120,
												fontSize:
													this.state.fontSize - 3,
												fontWeight: "500",
												// display:
												// 	this.state.taskHover ===
												// 	sub.id
												// 		? "flex"
												// 		: "none",
												// opacity:
												// 	this.state.taskHover ===
												// 	sub.id
												// 		? 1
												// 		: 0,
											}}>
											{moment(sd).format("DD MMMM")} -
											{moment(ed).format("DD MMMM")}
										</Text>
									</View>

									{/* <Text
										style={{
											color: isOnToday
												? APP_COLOURS.BG2
												: "#FFF",
											fontSize: this.state.fontSize,
											fontWeight: "700",
											width: "100%",
											position: "absolute",
											left: left + 10,
										}}>
										{sub.title}
									</Text> */}
								</View>
							</View>
						</TouchableOpacity>

						{/* <View
							style={{
								flex: 0.9,
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
								backgroundColor: bgColour,
								borderTopRightRadius: 10,
								borderBottomRightRadius: 10,
								opacity: isPastDue ? 0 : isDueToday ? 1 : 0.8,
							}}></View> */}

						{/* <View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
								backgroundColor:
									isDueToday || isPastDue
										? APP_COLOURS.RED
										: TaskStatus(sub.status),

								opacity: isPastDue ? 0 : isDueToday ? 1 : 0.8,
								// padding: 2,
								position: "absolute",
								display: hasBegun ? "none" : "flex",
							}}>
							{sub.status === "Completed" ? null : (
								<Text
									ellipsizeMode='clip'
									style={{
										color: APP_COLOURS.BG,
										fontSize: this.state.fontSize - 3,
										fontWeight: "700",
									}}>
									{isDueToday
										? "today"
										: moment(sub.start_date)
												.subtract(1, "day")
												.fromNow()}
								</Text>
							)}
						</View> */}

						<View
							style={{
								display: right <= 0 ? "none" : "flex",
								flex: right <= 0 ? 0 : right,
								alignItems: "center",
								justifyContent: "flex-start",
								flexDirection: "row",
							}}>
							{/* {this.assignment(sub, 25)} */}
						</View>
					</View>
				</View>
			);
		}
	};

	selectedTaskItem = () => {
		return (
			<View
				style={{
					position: "absolute",
					right: 10,
					bottom: 10,
					// backgroundColor: "rgba(0,0, 0, 0.3)",
					width: 200,
					height: 200,
					borderRadius: 20,
					padding: 20,
				}}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"minus",
							)
						}>
						<View
							style={{
								width: 60,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								style={{
									width: 40,
									height: 40,
									resizeMode: "contain",
								}}
								source={require("./min_btn2.png")}
							/>
						</View>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"plus",
							)
						}>
						<View
							style={{
								width: 60,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								style={{
									width: 40,
									height: 40,
									resizeMode: "contain",
								}}
								source={require("./add_btn2.png")}
							/>
						</View>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"left",
								"move",
							)
						}>
						<View
							style={{
								marginRight: 4,
							}}>
							<Image
								style={{
									width: 60,
									height: 60,
									resizeMode: "contain",
								}}
								source={require("./left_btn.png")}
							/>
						</View>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"right",
								"move",
							)
						}>
						<View
							style={{
								marginLeft: 2,
							}}>
							<Image
								style={{
									width: 60,
									height: 60,
									resizeMode: "contain",
								}}
								source={require("./right_btn.png")}
							/>
						</View>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "row",
					}}>
					{/* <TouchableOpacity
						onPress={() =>
							this.updateItem(
								this.state.selectedSubTask.id,
								"status",
								this.state.currentStatus,
							)
						}>
						<View
							style={{
								width: 100,
								height: "auto",
								padding: 10,
								borderRadius: 10,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor:
									this.state.currentStatus == "pending"
										? "#FF5F5F"
										: this.state.currentStatus == "complete"
										? "rgba(0,0, 0, 0.3)"
										: "#E0A749",
							}}>
							{this.state.currentStatus == "complete" ? (
								<Image
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
									source={require("./Checkmark.png")}
								/>
							) : null}
							<Text
								style={{
									color: this.state.fontColor,
									fontWeight: "500",
								}}>
								{this.state.currentStatus}
							</Text>
						</View>
					</TouchableOpacity> */}

					{/* <TouchableOpacity
						onPress={() => {
							// console.log(this.state.selectedSubTask);
							// return;
							crudDelete(
								"divisions_events",
								this.state.selectedSubTask,
								() => {
									// this.removeFromArray(
									// 	this.state.currentSubItemId,
									// 	"events",
									// );
									this.setState({
										selectedSubTask: "",
									});
									this.props.reloadData();
								},
							);
						}}
						style={{
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							padding: 3,
							marginLeft: 5,
						}}>
						<Image
							source={bin}
							style={{
								width: 38,
								height: 38,
								marginRight: 5,
								resizeMode: "contain",
							}}
						/>
					</TouchableOpacity> */}
				</View>
			</View>
		);
	};

	selectedMilestoneComp = (ddd) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "flex-start",
						alignItems: "center",
						// backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								width: 850 - 40,
								maxWidth: 850,
								height: "auto",
								backgroundColor: "white",
								// alignItems: 'center',
								borderRadius: 12,
								paddingTop: 20,
								paddingBottom: 10,
								padding: 10,
								marginTop: 40,
							}}>
							{/* <EditMilestone
								d={ddd}
								divisionData={this.state.selecteddivision}
								updateMoveTask={this.updateItem.bind(this)}
								reloadData={() => this.props.reloadData()}
								close={() =>
									this.setState({
										selectedMilestoneComp: "",
									})
								}
							/> */}
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	selectedTaskComp = (d) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "flex-start",
						alignItems: "center",
						backgroundColor: "rgba(0, 0, 0, 0.6)",
					}}>
					<ScrollView>
						<View
							style={
								{
									// width: 850 - 40,
									// maxWidth: 850,
									// height: "auto",
									// backgroundColor: "white",
									// // alignItems: 'center',
									// borderRadius: 12,
									// paddingTop: 20,
									// paddingBottom: 10,
									// padding: 10,
									// marginTop: 40,
								}
							}>
							<EditTask
								d={d}
								milestoneData={this.state.selectedMilestoneData}
								divisionData={this.state.selecteddivision}
								selecteddivision={this.state.selecteddivision}
								updateMoveTask={this.updateItem.bind(this)}
								reloadData={() => this.getTrackerData_()}
								close={() =>
									this.setState({
										selectedSubTask: "",
										showEditModal: false,
										selecteddivision: "",
										selectedMilestoneData: "",
									})
								}
							/>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	calendarBars = () => {
		return this.state.calendar.map((d, i) => (
			<View
				style={{
					flex: 1,
					// marginleft: "1%",

					borderLeftWidth: 3,
					borderLeftColor: "rgba(225,225, 225, 0.1)",
				}}>
				<View
					style={{
						height: 40,
						// backgroundColor: "#026BFA",
						backgroundcolor: this.state.bgColor1,
						padding: 5,
						// marginRight: 0.5,
					}}>
					<Text
						style={{
							// color: this.state.fontColor, // '#5A7BBA'
							color: "#FFF",
							fontSize: 13,
							fontWeight: "900",
							marginBottom: 16,
						}}>
						Week {d.week}
					</Text>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						borderRightWidth: 0.5,
						borderRightColor: this.state.borderLeftColour,
					}}>
					{d.days.map((d_, i_) => {
						let today = moment().format("DD MM");
						let day = moment(d_).format("DD MM");

						let dayName = moment(d_).format("ddd");

						let isWeekend = false;
						if (dayName == "Sat" || dayName == "Sun") {
							isWeekend = true;
						}

						let isToday = today == day;

						let startOfMonth = false;
						if (moment(d_).format("D") == 1) {
							startOfMonth = true;
						}

						return (
							<View
								key={`d-${i_}`}
								style={{
									flex: 1,
									borderRightWidth: isToday ? 2 : 0.5,
									borderLeftWidth: isToday
										? 1
										: startOfMonth
										? 2
										: this.state.seasonHover !== ""
										? moment(this.state.seasonHover).isSame(
												moment(d_),
										  )
											? 1
											: 1
										: 0,

									borderRightColor: isToday
										? "rgba(255,0, 0, 0.15)"
										: isWeekend
										? this.state.dateBorders
										: this.state.dateBorders,

									// borderLeftColor: startOfMonth
									// 	? "#797979"
									// 	: "red",

									borderLeftColor:
										this.state.seasonHover !== ""
											? moment(
													this.state.seasonHover,
											  ).isSame(moment(d_))
												? "red"
												: "transparent"
											: "#797979",

									backgroundColor: isToday
										? "rgba(255,0, 0, 0.15)"
										: isWeekend
										? "rgba(12,0, 0, 0.05)"
										: "transparent",
									// padding: 2,
								}}>
								<Text
									adjustsFontSizeToFit
									numberOfLines={1}
									style={{
										color: this.state.fontColor,
										fontSize: this.state.fontSize - 3,
										fontWeight: "400",
									}}>
									{dayName}
								</Text>
								<Text
									adjustsFontSizeToFit
									numberOfLines={1}
									style={{
										color: this.state.fontColor,
										fontSize: this.state.fontSize,
										fontWeight: "700",
									}}>
									{moment(d_).format("DD")}
								</Text>
								<View
									style={{
										height: 10,
									}}>
									<Text
										style={{
											display: startOfMonth
												? "flex"
												: "none",
											color: APP_COLOURS.ORANGE,
											fontSize: this.state.fontSize + 3,
											fontWeight: "300",
										}}>
										{moment(d_).format("MMMM YYYY")}
									</Text>
								</View>
							</View>
						);
					})}
				</View>
			</View>
		));
	};

	techSupportModal = () => {
		let sw = this.state.screenWidth;
		let sh = this.state.screenHeight;
		sw = sw > 600 ? 600 : sw - 30;
		sh = sh > 800 ? 800 : sh - 60;
		let align = "right";
		return (
			<>
				{!this.state.viewTechSupport ? (
					<View
						onMouseEnter={() => this.setState({ tshover: true })}
						onMouseLeave={() => this.setState({ tshover: false })}
						style={{
							position: "absolute",
							zIndex: 99,
							bottom: 3,
							[align]: 14,
							// alignItems: "flex-end",
							justifyContent: "center",
							paddingRight: 15,
							shadowColor: "#000",
							shadowOffset: { width: 0, height: 8 },
							shadowOpacity: 0.44,
							shadowRadius: 9.32,
							elevation: 3,
							borderRadius: 40,
							borderTopRightRadius: 15,
							// backgroundColor: "transparent",
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									viewTechSupport: true,
								})
							}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									paddingLeft: 10,
								}}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData2,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={65}
									width={65}
								/>
								<View>
									<Text
										style={{
											// color: APP_COLOURS.TEXTCOLOR,
											color: "#FFF",
											fontSize: 26,
											fontWeight: "200",
											fontFamily: "Gotham",
										}}>
										Jarvis
									</Text>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 9,
											fontWeight: "300",
											fontFamily: "Gotham",
										}}>
										powered by Ai
									</Text>
								</View>
							</View>
						</TouchableOpacity>
					</View>
				) : (
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							zIndex: 999,
							position: "absolute",
							left: 0,
							top: 0,
						}}>
						<TouchableOpacity
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}
							onPress={() =>
								this.setState({
									viewTechSupport: false,
								})
							}>
							<View
								style={{
									// flex: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgba(52, 52, 52, 0.5)",
									width: this.state.screenWidth,
									height: this.state.screenHeight,
								}}></View>
						</TouchableOpacity>

						<View
							style={{
								width: sw,
								height: sh,
								position: "absolute",
								bottom: 60,
								[align]: 18,
								alignItems: "center",
								justifyContent: "center",
								position: "absolute",
								// backgroundColor: "#864AE0",
								backgroundColor: APP_COLOURS.BACKGROUND,
								borderRadius: 18,
								shadowColor: "#000",
								shadowOffset: { width: 0, height: 8 },
								shadowOpacity: 0.44,
								shadowRadius: 9.32,
								elevation: 10,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: -15,
									left: -15,
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: 20,
									backgroundColor: "#FFF",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewTechSupport: false,
											tshover: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>

							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									paddingLeft: 10,
								}}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData2,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={65}
									width={65}
								/>
								<View>
									<Text
										style={{
											// color: APP_COLOURS.TEXTCOLOR,
											color: "#FFF",
											fontSize: 26,
											fontWeight: "200",
											fontFamily: "Gotham",
										}}>
										Jarvis
									</Text>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 9,
											fontWeight: "300",
											fontFamily: "Gotham",
										}}>
										powered by Ai
									</Text>
								</View>
							</View>

							<TechSupportComponent
								screenHeight={sh}
								screenWidth={sw}
								user_data={this.state.user_data}
								logOut={this.logOut.bind(this)}
							/>
						</View>
					</View>
				)}
			</>
		);
	};

	resize() {
		this.setState({
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,
		});
	}

	logOut = async () => {
		await localStorage.clear();
		await localStorage.clear();
		this.props.navigate("/");
		// return <Navigate to='/' />;
	};
}
