import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProposalReport from ".";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ReportPage() {
	const { id } = useParams();
	const query = useQuery();
	const token = query.get("token");

	// useEffect(() => {
	// 	// Perform side effects here, like setting local storage
	// 	// localStorage.setItem("rt_", t);
	// 	// localStorage.setItem("e", e);
	// 	// window.location.href = "/ResetPassword";
	// }, [e, t]);

	// useEffect(() => {
	// 	// const postData = async () => {
	// 	// 	try {
	// 	// 		const response = await fetch("https://your-api-endpoint.com", {
	// 	// 			method: "POST",
	// 	// 			headers: {
	// 	// 				"Content-Type": "application/json",
	// 	// 			},
	// 	// 			body: JSON.stringify(data),
	// 	// 		});
	// 	// 		if (!response.ok) {
	// 	// 			throw new Error("Network response was not ok");
	// 	// 		}
	// 	// 		const result = await response.json();
	// 	// 		//  setResponse(result);
	// 	// 	} catch (error) {
	// 	// 		//  setError(error);
	// 	// 	}
	// 	// };

	// 	postData();
	// }, [data]);

	return <ProposalReport id={id} token={token} />;
}

export default ReportPage;
