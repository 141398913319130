import React, { Component } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	Image,
	ScrollView,
} from "react-native";
import { APP_COLOURS, Avatars, StatusColour } from "../../../APP_VARS";
import moment from "moment";
import User from "./User";
import UsersSelectList from "./UsersSelectList";
import { crudUpdate, updateAssignements } from "../../../CRUDHELPER";

export default class DivisionDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: "",
			assignment: [],
			assignment_ids: [],
			...this.props.divData,
		};
	}

	componentDidMount() {
		console.log(this.props.divData);
	}

	updateAssignedToDivision = () => {
		let obj = {
			...this.props.divData,
			assignment: this.state.assignment,
			assignment_ids: this.state.assignment_ids,
		};
		if (obj.milestones?.length === 0) {
			delete obj.milestones;
		}
		if (obj.assignment?.length === 0) {
			delete obj.assignment;
		}
		if (obj.assignment_ids?.length === 0) {
			delete obj.assignment_ids;
		}
		console.log(obj);
		updateAssignements(obj, (d) => {
			this.props.reload();
		});
	};

	render() {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						width: "100%",
						justifyContent: "flex-start",
						// alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								flex: 1,
								width: "100%",
								flexDirection: "row",
							}}>
							<View
								style={{
									width: "100%",
									maxWidth: 700,
									height: "auto",
									backgroundColor:
										APP_COLOURS.DESELECTEDCOLOR,
									// alignItems: 'center',
									borderRadius: 12,
									paddingTop: 20,
									paddingBottom: 10,
									padding: 15,
									marginTop: 40,
								}}>
								<TouchableOpacity
									onPress={() => this.props.close()}>
									<Image
										source={require("./close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 20,

										fontWeight: "600",
										fontFamily: "Gotham",
									}}>
									{this.props.divData.title}
								</Text>
								<Text style={{ fontSize: 10 }}>Channels</Text>
								{this.props.divData.milestones.map((d, i) => (
									<View
										style={{
											marginVertical: 3,
											backgroundColor: "#FFF",
											padding: 5,
											borderRadius: 3,
										}}>
										<Text style={{}}>{d.title}</Text>
									</View>
								))}

								<View
									style={{
										marginTop: 20,
									}}>
									<Text
										style={{
											padding: 5,
										}}>
										assignment
									</Text>

									{/* {this.state.assignment.map((d) => (
										<User d={d} preview={false} />
									))} */}

									<UsersSelectList
										assignment_ids={
											this.state.assignment_ids
										}
										assignment={this.state.assignment}
										isStandalone={false}
										addAssignment={(ass, ids) => {
											// updateAssignements();
											console.log(ass, ids);
											this.setState({
												assignment: ass,
												assignment_ids: ids,
											});
										}}
									/>
								</View>
								<TouchableOpacity
									onPress={() => {
										this.updateAssignedToDivision();
									}}>
									<View
										style={{
											padding: 8,
											backgroundColor: APP_COLOURS.BLUE,
											borderRadius: 10,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 14,
											}}>
											Save
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}
