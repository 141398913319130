import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "../APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";
import Background from "./Background";

export default class Page14 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					minHeight: h,
					// height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					paddingTop: "5%",
					// flex: 1,
				}}>
				<View
					style={{
						position: "absolute",
						bottom: 0,
						left: 0,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View>

				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						paddingBottom: "5%",
					}}>
					<View
						style={{
							width: w,

							// height: h,
							height: "auto",
							maxWidth: 1200,
							padding: 20,

							flex: 1,
							zIndex: 1,
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h * 0.2,
								width: w,
								// marginBottom: "40%",
							}}>
							<View
								style={{
									flex: 1,
									// padding: 20,
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={700}>
											REVLON
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 35,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={500}>
											Community Management
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								// height: h / 2,
								width: w,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
									}}></View>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 25,
										flexDirection: "row",
									}}>
									<Font family='Montserrat' weight={300}>
										Our proposed community management
										service will be split between Revlon and
										Elizabeth Arden, where 70% will be
										focused on Revlon, including cosmetics,
										hair care, fragrance, Mitchum, and Creme
										of nature, and 30% will be focused on
										Elizabeth Arden. The exact volumes of
										brand accounts and channels will be
										confirmed during our alignment session
										Our comprehensive community management
										service includes the following
										activities:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Scope of work:
									</Font>
								</Text>

								{[
									{
										title: "- Timely Customer Support",
										desc: "Responding to direct and on-page queries at a dedicated timeframe (to be discussed) for 16 hours of the day, with an average response time of 30 minutes.",
									},
									{
										title: "- Active Engagement & Feedback Management",
										desc: "Engaging in conversation and replying to feedback to promote customer engagement and satisfaction.",
									},
									{
										title: "- Maintaining Social Media Quality",
										desc: "Monitoring and deleting spam comments to maintain the quality and authenticity of your social media pages.",
									},
									{
										title: "- Comprehensive Social Listening",
										desc: "Conducting social listening to identify conversations featuring your brands and engaging in conversations where relevant within the Sprinklr Professional Plan.",
									},
									{
										title: "- Monthly Reporting & Tactical Opportunities",
										desc: "Identifying daily tactical opportunities as a part of our monthly reporting, delivered on the 15th of the following month.",
									},
									{
										title: "- Crisis Management & Reputation Monitoring",
										desc: "Implementing a crisis management plan and monitoring brand reputation across social media platforms to address potential issues promptly and effectively.",
									},
									{
										title: "- Performance Analysis & Optimization",
										desc: "Regularly analyzing social media performance data to identify trends and opportunities for improvement, optimizing content and engagement strategies for better results.",
									},
								].map((d, i) => (
									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											marginBottom: 5,
										}}>
										<Font family='Montserrat' weight={500}>
											{d.title}
										</Font>
										<Text
											style={{
												fontSize: 12,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												{d.desc}
											</Font>
										</Text>
									</Text>
								))}

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Hours and Response Times:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={300}>
										Proposed costs include full-time
										community managers dedicated to Revlon
										and Elizabeth Arden, available for 16
										hours a day, this is limited to
										community management on the platforms
										(responding to queries and deleting
										relevant comments). The community
										manager will be available to the Revlon
										team for queries and updates during
										working hours between (08:30-17:00). We
										guarantee an average response time of 30
										minutes during office hours. After-hours
										community management will be charged at
										after-hours rates on a case-by-case
										basis.
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 17,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 5,
										flexDirection: "row",
										marginTop: 5,
									}}>
									<Font family='Montserrat' weight={500}>
										Resource Inclusion:
									</Font>
								</Text>

								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										Community Manager
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 15,
										color: APP_COLOURS.TEXTCOLOR,
										marginBottom: 2,
										flexDirection: "row",
										marginTop: 2,
									}}>
									<Font family='Montserrat' weight={300}>
										Sprinklr Professional Plan
									</Font>
								</Text>

								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											REVLON:{" "}
										</Font>
										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Community Management:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 46,305
										</Font>
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									{/* <Image
											source={require("./assets/revlon.png")}
											style={{
												width: 180,
												height: 100,
												resizeMode: "contain",
											}}
										/> */}
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={700}>
											Elizabeth Arden:{" "}
										</Font>
										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,

												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Community Management:
											</Font>
										</Text>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={700}>
											R 19,845
										</Font>
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: APP_COLOURS.TEXTCOLOR,
									}}
								/>
								<View
									style={{
										flexDirection: "row",
										// marginBottom: 25,
										marginTop: 20,
										alignItems: "center",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 19,
											color: APP_COLOURS.TEXTCOLOR,

											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={500}>
											Total:
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 25,
											flexDirection: "row",
											marginTop: 25,
										}}>
										<Font family='Montserrat' weight={500}>
											R 66,150
										</Font>
									</Text>
								</View>
							</View>
						</View>

						<Text
							style={{
								fontSize: 13,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 2,
								flexDirection: "row",
								marginTop: 70,
							}}>
							<Font family='Montserrat' weight={300}>
								* All quoted amounts are monthly recurring and
								exclusive of VAT unless stated otherwise.
							</Font>
						</Text>
					</View>
				</View>

				{/* <View
					style={{
						position: "absolute",
						top: 0,
						right: 0,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: 500,
							height: 500,
							resizeMode: "contain",
						}}
					/>
				</View> */}
				<View
					style={{
						// position: "absolute",
						// bottom: 60,
						// right: 0,
						width: "100%",
						alignItems: "flex-end",
						justifyContent: "flex-end",
					}}>
					<Image
						source={require("./assets/agreeMent.png")}
						style={{
							width: 200 * (368.94 / 255.42),
							height: 200,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						width: "100%",
						backgroundColor: "#1C2B46",
						height: 65,
						// position: "absolute",
						// bottom: 0,
						// left: 0,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							maxWidth: 1200,
							justifyContent: "space-between",
						}}>
						<Image
							source={require("./assets/services.png")}
							style={{
								width: 300,
								height: 35,
								resizeMode: "contain",
							}}
						/>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 220,
								height: 35,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			</div>
		);
	}
}
