import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "../APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/122694-brand-identity.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page10 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							// width: w,
							// height: h,
							height: "auto",
							padding: 20,
							// paddingTop: "10%",
							zIndex: 1,

							width: window.innerWidth * 0.8,
							maxWidth: mw,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,

										marginBottom: "10%",
									}}>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.4}
											width={h * 0.4}
										/>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													The Essence of Revlon &
													Elizabeth Arden
												</Font>
											</Text>
										</View>
									</View>
								</View>

								<View
									style={
										{
											// width: w,
										}
									}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												},
												fontS,
											]}>
											Revlon and Elizabeth Arden are two
											iconic beauty brands, each
											possessing a distinct identity and
											heritage. By understanding and
											embracing the essence of each brand,
											we can craft a PR and community
											management strategy that resonates
											with their respective target
											audiences and showcases their unique
											strengths.
										</Text>

										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
											}}>
											<View
												style={{
													flex: 1,
													padding: 20,
													backgroundColor: "#FFF",
													marginRight: 15,
													borderRadius: 20,
													// width: 350,
													marginBottom: 15,
													marginTop: 10,
												}}>
												<Text
													style={{
														fontSize: 25,
														color: APP_COLOURS.TEXTCOLOR,
														marginBottom: 15,
														flexDirection: "row",
														marginVertical: 20,
													}}>
													<Font
														family='Montserrat'
														weight={500}>
														Revlon:
													</Font>
												</Text>

												{[
													{
														title: "Empowerment:",
														desc: " Revlon has always been about empowering women, encouraging self-expression, and embracing individuality through a wide range of innovative and high-quality beauty products.",
													},
													{
														title: "Accessible Beauty:",
														desc: "Revlon's mission is to provide accessible, on-trend beauty solutions, making it easy for women of all ages and backgrounds to feel confident and beautiful.",
													},
													{
														title: "Bold and Vibrant:",
														desc: "With a legacy of bold colors and innovative formulas, Revlon is synonymous with vibrancy and daring self-expression.",
													},
													{
														title: "Timeless Allure:",
														desc: "As an iconic beauty brand with a rich history, Revlon's timeless appeal continues to inspire and captivate new generations of beauty enthusiasts.",
													},
												].map((d, i) => (
													<Text
														style={[
															{
																marginBottom: 15,
																flexDirection:
																	"row",
															},
															fontS,
														]}>
														<Text
															style={[
																{
																	fontWeight:
																		"600",
																},
															]}>
															{d.title}
														</Text>{" "}
														{d.desc}
													</Text>
												))}
											</View>

											<View
												style={{
													flex: 1,
													padding: 20,
													backgroundColor: "#FFF",
													marginRight: 15,
													borderRadius: 20,
													// width: 350,
													marginBottom: 15,
													marginTop: 10,
												}}>
												<Text
													style={{
														fontSize: 25,
														color: APP_COLOURS.TEXTCOLOR,
														marginBottom: 15,
														flexDirection: "row",
														marginVertical: 20,
													}}>
													<Font
														family='Montserrat'
														weight={500}>
														Elizabeth Arden:
													</Font>
												</Text>

												{[
													{
														title: "Elegance and Sophistication:",
														desc: "Elizabeth Arden embodies grace, sophistication, and a timeless appeal, catering to discerning women who value quality and refinement in their beauty products.",
													},
													{
														title: "Pioneering Skincare:",
														desc: "As a trailblazer in skincare, Elizabeth Arden is committed to innovation and scientific research, offering cutting-edge products that deliver visible results.",
													},
													{
														title: "Holistic Beauty:",
														desc: "Elizabeth Arden's philosophy goes beyond cosmetics, embracing a holistic approach to beauty that includes skincare, makeup, and fragrance, as well as a focus on inner wellbeing.",
													},
													{
														title: "Iconic Heritage:",
														desc: "With a storied past that includes empowering women during World War II and creating the iconic Red Door Spa, Elizabeth Arden has a rich heritage that inspires trust and admiration.",
													},
												].map((d, i) => (
													<Text
														style={[
															{
																marginBottom: 15,
																flexDirection:
																	"row",
															},
															fontS,
														]}>
														<Text
															style={[
																{
																	fontWeight:
																		"600",
																},
															]}>
															{d.title}
														</Text>{" "}
														{d.desc}
													</Text>
												))}
											</View>
										</View>

										<Text
											style={[
												{
													fontSize: 22,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginVertical: 20,
												},
												fontS,
											]}>
											By leveraging the unique essence of
											Revlon and Elizabeth Arden, our PR
											and community management strategy
											will reinforce the distinct identity
											of each brand, create emotional
											connections with their respective
											audiences, and drive brand loyalty
											and growth.
										</Text>
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
