// import React, { Component } from "react";
// import { View, Text } from "react-native";
// import FileBase64 from "react-file-base64";

// import "./Input.css";

// class ImageUploader extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			file: [],
// 			borderHighlight: false,
// 		};
// 	}

// 	getFiles(files) {
// 		console.log("files", files);
// 		this.setState({ file: files });

// 		// WE USING A FOR LOOP TO SAVE THE FILES INCASE THEY ARE MULTIPLE
// 		for (var i = 0; i < files.length; i++) {
// 			if (files[i].type === "image/png") {
// 				files[i].file_name = files[i].name;
// 				files[i].doc = files[i].type;

// 				// let replace_ = "data:image/png;base64,";
// 				// let myStr = files[i].base64;
// 				// let cleanString = myStr.replace(replace_, "");
// 				console.log(files[i]);
// 				this.props.base64String(files[i]);
// 			} else {
// 				alert("Only PNG images accepted");
// 			}

// 			// const objectTosend = {
// 			// 	name: files[i].name,
// 			// 	fileBase64: files[i].base64,
// 			// 	size: files[i].size,
// 			// 	selectedFolder: folderid,
// 			// };
// 		}
// 	}

// 	SaveFile = (objectToSend) => {
// 		this.setState({ loading: true });
// 		let obj = JSON.stringify(objectToSend);
// 		console.log(obj);
// 	};

// 	render() {
// 		return (
// 			<View
// 				style={{
// 					zIndex: 99,
// 					// borderColor: this.state.borderHighlight ? "blue" : "white",
// 					// borderWidth: 1,
// 					// borderRadius: 10,
// 					// borderStyle: "dotted",
// 				}}>
// 				<View
// 					onDragEnter={() =>
// 						this.setState({
// 							borderHighlight: true,
// 						})
// 					}
// 					onDragLeave={() =>
// 						this.setState({
// 							borderHighlight: false,
// 						})
// 					}
// 					style={{
// 						opacity: 0,
// 					}}>
// 					<FileBase64
// 						multiple={true}
// 						onDone={this.getFiles.bind(this)}
// 						onDragEnter={() =>
// 							this.setState({
// 								borderHighlight: true,
// 							})
// 						}
// 						onDragLeave={() =>
// 							this.setState({
// 								borderHighlight: false,
// 							})
// 						}
// 					/>
// 				</View>
// 			</View>
// 		);
// 	}
// }

// export default ImageUploader;

import React, { useState } from "react";

const ProfileImageUploader = ({ onFileChange }) => {
	const [imageBase64, setImageBase64] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleImageChange = (event) => {
		setErrorMessage("");
		const file = event.target.files[0];

		if (!file) return;

		//  if (file.type !== 'image/png') {
		//    setErrorMessage('Invalid file type. Please upload a PNG image.');
		//    return;
		//  }

		if (!file.type.startsWith("image/")) {
			setErrorMessage("Please upload an image file.");
			return;
		}

		const reader = new FileReader();

		reader.onloadend = () => {
			const img = new Image();
			img.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");

				// Start with the original image dimensions
				let width = img.width;
				let height = img.height;

				// If the image size is greater than 500KB, reduce the dimensions
				if (file.size > 500 * 1024) {
					// Calculate the new dimensions to maintain the aspect ratio
					const scaleFactor = Math.sqrt((500 * 1024) / file.size);
					width = width * scaleFactor;
					height = height * scaleFactor;
				}

				// Set the canvas dimensions to the new size
				canvas.width = width;
				canvas.height = height;

				// Draw the image at the new dimensions
				ctx.drawImage(img, 0, 0, width, height);

				// Convert the canvas to a data URL
				canvas.toBlob((blob) => {
					const newFile = new File([blob], file.name, {
						type: file.type, // Use the original file type
						lastModified: Date.now(),
					});

					const reader = new FileReader();
					reader.onloadend = () => {
						// Call the parent callback function with all file information
						onFileChange({
							name: newFile.name,
							size: newFile.size,
							type: newFile.type,
							base64: reader.result,
							doc: newFile.type,
							file_name: newFile.name,
						});

						setImageBase64(reader.result);
					};
					reader.readAsDataURL(newFile);
				}, file.type); // Use the original file type
			};
			img.src = reader.result;
		};
		reader.readAsDataURL(file);
	};

	return (
		<div
			style={{
				minWidth: 300,
				marginTop: 15,
				borderWidth: 1,
				borderColor: "#1F2229",
				backgroundColor: "#FFF",
				borderRadius: 12,
				flexDirection: "row",
				padding: 15,
				justifyContent: "center",
				alignItems: "center",
				zIndex: 999,
			}}>
			<input type='file' onChange={handleImageChange} />
			{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
			{imageBase64 && (
				<img
					src={imageBase64}
					alt='Profile'
					style={{
						maxWidth: "100%",
						maxHeight: "100%",
						borderRadius: 12,
					}}
				/>
			)}
		</div>
	);
};

export default ProfileImageUploader;
