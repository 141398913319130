import React, { useEffect } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
	Dimensions,
} from "react-native";
import { useParams, useLocation } from "react-router-dom";

import GanttComponent from "./Gantt/index";
import Font from "react-font";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";
// import logo_tetrice from "../../assets/logo_tetrice.png";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ReportPage() {
	const { id, brandid, token } = useParams();
	// const query = useQuery();

	return (
		<View
			style={{
				flex: 1,
				// backgroundColor: "#000",
				// backgroundColor: APP_COLOURS.BG,
				backgroundColor: "#171A20",
				// backgroundColor: "#131313",
				height: window.innerHeight,
			}}>
			<View
				style={{
					// width: window.innerWidth,
					width: "100%",
					flexDirection: "row",
					flexWrap: "wrap",
					// backgroundColor: "#171A20",
					// padding: "8%",
					overflow: "hidden",
					height: 130,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						// flex: 1,
						position: "absolute",
						left: 10,
						top: 10,
					}}>
					<Image
						// source={bg1}
						source={require("../assets/logoTetrice.png")}
						style={{
							width: 170,
							height: 80,

							resizeMode: "contain",
						}}
					/>
				</View>
				{/* <View
					style={{
						// flex: 1,
						position: "absolute",
						left: 10,
						top: 90,
					}}>
					<Image
						// source={bg1}
						source={require("../assets/tetrice-revlon-main.png")}
						style={{
							width: 150,
							height: 80,

							resizeMode: "contain",
						}}
					/>
				</View> */}
				<View
					style={{
						flex: 1,
						opacity: 0.9,
						alignItems: "center",
					}}>
					<Text
						style={{
							fontSize: 45,
							color: "#FFF",
						}}>
						<Font family='Poppins' weight={800}>
							DIGITAL TRACKER
						</Font>
					</Text>

					<Text
						style={{
							fontSize: 19,
							color: "#FFF",
						}}>
						<Font family='Poppins' weight={400}>
							CALENDAR 2024
						</Font>
					</Text>
				</View>
			</View>
			<GanttComponent
				brand_id={id}
				brandid={brandid}
				token={token}
				screenHeight={screenHeight - 150}
			/>
		</View>
	);
}

export default ReportPage;
