import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "../APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/141255-influencer-green.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page9 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		let mw = 1200;
		let h = this.props.screenHeight;
		let w = this.props.screenWidth > mw ? mw : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		let fontS = {
			fontSize: 20,
			color: APP_COLOURS.TEXTCOLOR,
			fontFamily: "Montserrat",
			textAlign: "justify",
			fontWeight: "300",
		};

		return (
			<Fade>
				<View
					style={{
						// flex: 1,
						width: w,
						// height: h,
						height: "auto",
						// backgroundColor: "#1F2229",
						// backgroundColor: "#FFF",
						// overflow: "hidden",
						justifyContent: "center",
						alignItems: "center",
						// paddingBottom: "10%",
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: w,
								// height: h,
								height: "auto",
								padding: 20,
								paddingTop: "10%",
								zIndex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h * 0.2,
									width: window.innerWidth * 0.8,
									maxWidth: mw,
								}}>
								<View
									style={{
										flex: 1,
										padding: 20,
									}}>
									<Lottie
										options={defaultOptions}
										height={h * 0.35}
										width={h * 0.4}
									/>
								</View>
								<View
									style={{
										flex: 1,
										padding: 20,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 50,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											<Font family='Poppins' weight={600}>
												The Power of Influence
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 22,

												marginBottom: 5,
												flexDirection: "row",
												opacity: 0.4,
												color: "#000",
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Elevating our brand with the
												help of industry tastemakers.
											</Font>
										</Text>
									</View>
								</View>
							</View>

							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h / 2,
									width: window.innerWidth * 0.8,
									maxWidth: mw,
								}}>
								<View
									style={{
										flex: 1,
									}}>
									<Text
										style={[
											{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
											},
											fontS,
										]}>
										Influencer partnerships play a crucial
										role in our PR and community management
										strategy for Revlon & Elizabeth Arden.
										By collaborating with industry
										tastemakers who embody our brand's
										values and aesthetic, we can tap into
										their dedicated followers and amplify
										our reach, effectively attracting new
										customers and reinforcing brand loyalty.
									</Text>
									{[
										{
											title: "Potential Influencers:",
											description: "",
										},
										{
											title: "Beauty Influencer A",
											description:
												"With a focus on makeup tutorials and product reviews, Influencer A has built a loyal following of beauty enthusiasts. Their elegant and sophisticated style aligns with the Revlon & Elizabeth Arden brands, making them an ideal partner for product showcases and collaborations.",
										},
										{
											title: "Skincare Expert B",
											description:
												"As a skincare specialist with a passion for clean beauty and sustainability, Influencer B's content resonates with consumers seeking expert advice on skincare routines and product recommendations. A partnership with Elizabeth Arden's skincare line could involve educational content and in-depth product breakdowns.",
										},
										{
											title: "Celebrity C",
											description:
												"Known for their flawless red-carpet looks and dedication to philanthropy, Celebrity C embodies the glamour and social responsibility associated with Revlon & Elizabeth Arden. Collaborations could include exclusive product launches, charity events, or limited-edition collections.",
										},
										{
											title: "Lifestyle Influencer D",
											description:
												"With content spanning fashion, beauty, and wellness, Influencer D appeals to a broad audience seeking a luxurious and aspirational lifestyle. Potential collaborations might involve showcasing Revlon & Elizabeth Arden products in daily routines, travel essentials, or seasonal trends.",
										},
										{
											title: "Examples of Collaborations:",
											description: "",
										},
										{
											title: "Co-created content",
											description:
												"Influencers can create engaging content showcasing Revlon & Elizabeth Arden products, such as makeup tutorials, skincare routines, or product comparisons, sharing it with their audience across various platforms.",
										},
										{
											title: "Giveaways and contests",
											description:
												"Collaborate with influencers to host giveaways or contests, encouraging their followers to engage with the brand and participate in community activities.",
										},
										{
											title: "Influencer events",
											description:
												"Invite influencers to exclusive brand events, product launches, or store openings, where they can share their experiences with their audience through live streaming, Instagram Stories, or blog posts.",
										},
										{
											title: "Brand ambassador programs",
											description:
												"Develop long-term partnerships with select influencers, making them official brand ambassadors. This ongoing relationship can include sponsored content, event appearances, and exclusive product access.",
										},
										{
											title: "Conclusion",
											description:
												"By leveraging the power of influencers, Revlon & Elizabeth Arden can create authentic connections with their target audience, boost brand awareness, and drive customer engagement across multiple touchpoints.",
										},
									].map((d) => (
										<>
											<Text
												style={[
													fontS,
													{
														fontWeight: "600",
														marginBottom: 15,
														marginVertical: 20,
													},
												]}>
												{d.title}
											</Text>

											<Text
												style={[
													{
														marginBottom: 15,
													},
													fontS,
												]}>
												{d.description}
											</Text>
										</>
									))}
								</View>
							</View>
						</View>

						{/* <PageFooter /> */}
					</View>
				</View>
			</Fade>
		);
	}
}
