import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	SafeAreaView,
	TextInput,
	Platform,
	UIManager,
	LayoutAnimation,
	Keyboard,
	TouchableWithoutFeedback,
	Animated,
	Easing,
	Image,
} from "react-native";
import AutoHeightImage from "../AutoHeightImageComp";
import moment from "moment";
import { APP_COLOURS, Avatars } from "../../APP_VARS";

async function getUserData() {
	try {
		const value = await localStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}
const sendSingleJarvisMessage = async (message, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/9a6b020f-ff7d-4f6b-8b0a-b4691cdb9410";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer 1234`,
		},
		redirect: "follow",
		body: JSON.stringify(message),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, [
							{
								text: "Error",
								date: moment().toISOString(),
								from: "Jarvis",
							},
						]);
					});
			} else {
				return next(response.status, [
					{
						text: "Error",
						date: moment().toISOString(),
						from: "Jarvis",
					},
				]);
			}
		})
		.catch((error) => console.log("error", error));
};

export default class JarvisChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: [],

			messageData: "",
			messageDataType: "text",
			beginScroll: false,
			secondsCountdown: 10,

			bottomofScrollview: 0,
			listeningForMessage: false,
		};
	}

	async componentDidMount() {
		// await this.getMessages();
		await clearInterval(this.interval);
		this.interval = setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
		console.log("mounted");

		setTimeout(() => {
			let messages = this.state.messages.push({
				text: `Hi ${this.props.user_data.first_name}, How can I help you?"`,
				from: "Jarvis",
				date: "2024-07-04T13:35:11.553Z",
			});
			// this.setState({
			// 	messasges: [],
			// });
		}, 800);
	}

	triggerTimer = async () => {
		//  return;
		console.log("triggerTimer");
		await clearInterval(this.interval);
		await this.setState({
			secondsCountdown: 10,
		});

		this.interval = await setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.secondsCountdown === 1) {
			await console.log("fetch messages");
			await this.setState({
				secondsCountdown: 0,
			});
			await clearInterval(this.interval);
		}
		console.log(this.state.secondsCountdown);
	}

	async componentWillUnmount() {
		await console.log("unmounted");
		await clearInterval(this.interval);

		this.setState = (state, callback) => {
			return;
		};
	}

	s_SingleJuliaMessage = async () => {
		let now = moment().toISOString();

		let message = {
			text: this.messageData,
			from: "Human",
			date: now,
		};

		// TTS.speak('Good day Sir!', {
		//   iosVoiceId: 'com.apple.ttsbundle.Samantha-compact',
		//   rate: 0.52,
		// });

		// return;

		// let message = {
		//   text: 'How long until chrsitmas',
		//   from: 'Human',
		//   sent: now,
		// };
		await clearInterval(this.interval);
		await this.setState({
			sendingMessage: true,
			messageData: this.messageData,
		});
		let messages = this.state.messages;
		messages = [...this.state.messages, message];

		this.setState({
			messages,
		});

		this.scrollView.scrollToEnd({ animated: true });
		// this.getMessages();
		await this.scrollView.scrollToEnd({ animated: true });
		await this.inputRef.clear();
		await this.setState({
			messageData: "",
			sendingMessage: false,
		});
		this.scrollView.scrollToEnd({ animated: true });

		let messageString = "";
		const mm = await messages.map(
			(m) =>
				(messageString = `${messageString} ${m.date} ${m.from}: ${m.text};`),
		);
		this.setState({
			listeningForMessage: true,
		});
		console.log(messageString);
		// return;
		sendSingleJarvisMessage(messageString, async (s, d) => {
			if (s === 200 && d.length > 0) {
				messages = [...messages, d[0]];
				console.log(s, d);
				// TTS.speak(d[0].text, {
				//   iosVoiceId: 'com.apple.ttsbundle.Daniel-compact',
				//   name: 'Samantha',
				//   language: 'en-GB',
				//   rate: 0.55,
				// });
				this.setState({
					messages,
					listeningForMessage: false,
				});
			}
			// this.scrollView.scrollToEnd({animated: true});
		});
	};

	render() {
		let messages = this.state.messages.sort(
			(a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
		);
		return (
			<View
				style={{
					flex: 1,

					overflow: "hidden",
					backgroundColor: APP_COLOURS.BACKGROUND,
				}}>
				<View
					style={{
						flexDirection: "row",
						backgroundColor: APP_COLOURS.BACKGROUND,
						shadowColor: APP_COLOURS.BACKGROUND,
						shadowOffset: { width: 0, height: 8 },
						shadowOpacity: 0.14,
						shadowRadius: 9.32,
						elevation: 12,
						// top: this.state.keyboardActive ? 48 : 100,
						position: "absolute",
						width: "100%",
						top: 0,
					}}>
					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							alignItems: "center",
							// paddingRight: 5,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								margin: 5,
								borderRadius: 30,
								borderWidth: 2,
								padding: 0.5,
								// borderColor: "online",
								overflow: "hidden",
								marginRight: 9,
								minHeight: 120,
							}}>
							{/* <AutoHeightImage
								width={40}
								source={Avatars[5].pp}
								style={{
									resizeMode: "cover",
									borderRadius: 25,
									opacity: 0,
								}}
							/> */}

							{this.state.listeningForMessage ? (
								<View
									style={{
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
										position: "absolute",
										top: 0,
										left: 0,
									}}>
									<ActivityIndicator
										color={APP_COLOURS.YELLOW}
									/>
								</View>
							) : null}
						</View>

						{/* <View
							style={
								{
									// flex: 1,
								}
							}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									//   marginBottom: 3,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "700",
										fontSize: 15,
										color: "#FFF",
									}}>
									Jarvis
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "200",
										fontSize: 10,
										color: "#FFF",
									}}>
									- personal assistant
								</Text>
							</View>
						</View> */}
					</View>
				</View>

				<View
					style={{
						position: "absolute",
						top: 200,
						marginHorizontal: "auto",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}>
					{/* <Image
						source={require("../../../assets/images/tetrice_iyote.png")}
						style={{
							width: 200,
							height: 200,
							resizeMode: "contain",
						}}
					/> */}

					{this.state.listeningForMessage ? (
						<View
							style={{
								padding: 10,
								justifyContent: "center",
								alignItems: "center",
								position: "absolute",
								top: 0,
								left: 0,
							}}>
							<ActivityIndicator color={APP_COLOURS.YELLOW} />
						</View>
					) : null}
				</View>

				<View
					style={{
						flex: 1,
						// height: window.innerHeight,
						// minHeight
						// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT9,
						paddingTop: 60,
						backgroundColor: APP_COLOURS.BACKGROUND,
					}}>
					<ScrollView
						ref={(ref) => (this.scrollView = ref)}
						// onScrollBeginDrag={() =>
						//   this.setState({
						//     beginScroll: true,
						//   })
						// }
						// onScrollEnd={() =>
						//   this.setState({
						//     beginScroll: false,
						//   })
						// }
						// onScrollBeginDrag={() => Keyboard.dismiss()}
						onContentSizeChange={() =>
							this.scrollView.scrollToEnd({
								animated: true,
							})
						}
						scrollEventThrottle={16}
						onScroll={(e) => {
							//   // let this.state.coords
							let yPos = e.nativeEvent.contentOffset.y;

							// if (
							// 	yPos >=
							// 		this.state.bottomofScrollview -
							// 			screenHeight &&
							// 	this.state.beginScroll
							// ) {
							// 	this.setState({
							// 		beginScroll: false,
							// 	});
							// }

							//   Animated.parallel([
							//     Animated.spring(this.scrollPosition, {
							//       toValue: yPos,
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//     Animated.spring(this.isScrolling, {
							//       toValue: 1,
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//     Animated.spring(this.yOffset, {
							//       toValue: Math.round(progresss),
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//   ]).start();
						}}>
						<TouchableWithoutFeedback
							onPress={() => {
								// Keyboard.dismiss();
								this.setState({
									keyboardActive: false,
								});
							}}>
							<View
								onLayout={(e) => {
									console.log();
									// this.setState({
									// 	bottomofScrollview:
									// 		e.nativeEvent.layout.height,
									// });
								}}
								style={{
									// flex: 1,
									// width: "100%",
									padding: 10,
									paddingRight: 10,
									paddingLeft: 20,
									// minHeight: window.innerHeight,
								}}>
								{messages.map((d, i) => {
									return (
										<View
											key={`js-${i}`}
											style={{
												width: "100%",
												zIndex: 10,
												alignItems:
													d.from === "Human"
														? "flex-end"
														: "flex-start",
											}}>
											<View
												style={{
													maxWidth: "75%",
													flexDirection:
														d.length > 20 ||
														d.from !== "Jarvis"
															? "column"
															: "row",
													backgroundColor:
														d.from === "Human"
															? APP_COLOURS.BLUE2
															: "#FFF",
													borderRadius: 16,
													borderTopLeftRadius:
														d.from === "Jarvis"
															? 16
															: 3,
													borderBottomLeftRadius:
														d.from === "Jarvis"
															? 16
															: typeof messages[
																	i + 1
															  ] !== "undefined"
															? messages[i + 1]
																	.from ===
															  messages[i].from
																? 3
																: 16
															: 16,
													borderTopRightRadius:
														d.from === "Jarvis"
															? typeof messages[
																	i - 1
															  ] !== "undefined"
																? messages[
																		i - 1
																  ].from ===
																  messages[i]
																		.from
																	? 3
																	: 16
																: 3
															: 16,
													borderBottomRightRadius:
														d.from === "Human"
															? 2
															: 16,
													padding: 10,
													marginTop:
														i > 0
															? messages[i - 1]
																	.from ===
															  messages[i].from
																? 3
																: 15
															: 3,
													shadowColor: "#000",
													shadowOffset: {
														width: 0,
														height: 8,
													},
													shadowOpacity:
														d.from === "Human"
															? 0
															: 0.24,
													shadowRadius: 9.32,
													elevation: 10,
													// opacity: 0.9,
												}}>
												<Text
													style={{
														color:
															d.from === "Human"
																? "#FFF"
																: "#000",
														fontSize: 17,
													}}>
													{typeof d.text !==
													"undefined"
														? d.text
														: d.data}
												</Text>
												<Text
													style={{
														fontWeight: "300",
														fontStyle: "italic",
														marginLeft: 10,
														color:
															d.from === "Human"
																? "#FFF"
																: "#000",
														fontSize: 10,
														alignSelf: "flex-end",
														marginTop: 2,
														opacity: 0.7,
													}}>
													{moment().diff(
														d.createdAt,
														"days",
													) >= 1
														? moment(
																d.createdAt,
														  ).format(
																"HH:mm MMM DD, YYYY",
														  )
														: moment(
																d.createdAt,
														  ).format("HH:mm")}
												</Text>
												{/* <Image
                          source={require('../assets/images/Checkmark.png')}
                          style={{
                            width: 12,
                            height: 12,
                            resizeMode: 'contain',
                            position: 'absolute',
                            right: 0,
                            bottom: -12,
                            display:
                              d.from === "Human" ? 'flex' : 'none',
                          }}
                        /> */}
											</View>
										</View>
									);
								})}
							</View>
						</TouchableWithoutFeedback>
					</ScrollView>
				</View>
				<View
					style={{
						// flex: 1,
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "flex-end",
						paddingLeft: 5,
						marginTop: 5,
						paddingRight: 10,
						paddingBottom: 5,
						backgroundColor: APP_COLOURS.BACKGROUND,
					}}>
					<TextInput
						ref={(ref) => (this.inputRef = ref)}
						style={{
							flex: 1,
							paddingLeft: 15,
							maxHeight: 300,
							padding: 12,
							paddingTop: 12,
							fontSize: 17,
							borderRadius: 13,
							marginRight: 10,
							// marginLeft: this.state.keyboardActive
							// 	? 3
							// 	: 15,
							color: "#FFF",
							backgroundColor: APP_COLOURS.BG4,
							fontWeight: "400",
						}}
						multiline
						numberOfLines={1}
						placeholderTextColor={"#828282"}
						clearButtonMode='while-editing'
						autoCompleteType='off'
						autoCorrect={true}
						autoCapitalize='sentences'
						placeholder={" Type a message"}
						onFocus={async () => {
							await this.setState({
								keyboardActive: true,
								beginScroll: false,
							});

							setTimeout(() => {
								this.scrollView.scrollToEnd({
									animated: true,
								});
								this.scrollView.scrollToEnd({
									animated: true,
								});
							}, 600);
						}}
						onBlur={() =>
							this.setState({
								keyboardActive: false,
							})
						}
						// value={this.state.messageData}
						onChangeText={(text) => {
							// this.setState({
							//   messageData: text,
							//   messageDataType: 'text',
							// });
							// clearInterval(this.interval);
							// clearTimeout(this.inputInterval);
							// clearInterval(this.inputInterval);
							// this.inputInterval = setTimeout(() => {
							//   this.triggerTimer();
							// }, 1000);

							this.messageData = text;
						}}
						// onSubmitEditing={() => this.addMeeting()}
					/>

					<TouchableOpacity
						disabled={this.state.sendingMessage}
						style={{
							opacity: this.state.filterSearch !== "" ? 1 : 0.5,
						}}
						onPress={async () => {
							// await this.sendMessage();
							this.s_SingleJuliaMessage();
						}}>
						<View
							style={{
								width: 35,
								height: 35,
								justifyContent: "center",
								alignItems: "center",
							}}>
							{this.state.sendingMessage ? (
								<ActivityIndicator
									color={APP_COLOURS.BLUE2}
									size='small'
								/>
							) : (
								<AutoHeightImage
									width={35}
									source={require("./send.png")}
								/>
							)}
						</View>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}
